<div class="u-margin-bottom-half u-flex-row u-flex-align-items-center">
    <h6>{{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.TITLE' | translate }}</h6>
</div>
<div class="__boiler-maintenance-container" *ngIf="!!DbUtils.getStringId(boilerMaintenanceContract); else noBoilerMaintenanceContract">
    <div>
        <div [ngSwitch]="!!boilerMaintenanceContract.confirmed" class="u-margin-bottom">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !boilerMaintenanceContract.confirmed }">
                <div *ngSwitchCase="true">
                    <div class="u-flex-column">
                        <div class="u-flex-row u-flex-align-items-center">
                            <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                            <span class="u-margin-right-half">{{
                                'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CONFIRMED.OK.LABEL' | translate
                            }}</span>
                        </div>
                        <div class="u-color-muted u-font-size-small __confirmed-on" *ngIf="boilerMaintenanceContract.confirmed">
                            {{ boilerMaintenanceContract.lastConfirmedOn | date: 'dd/MM/yyyy' }}
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CONFIRMED.NOK.LABEL' | translate }}</span>
                    </div>
                    <div class="u-margin-top-half u-margin-left-double">
                        <button smvd-ui-button [context]="uiContext.Alt" (click)="setConfirmed(true)">
                            {{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CONFIRMED.NOK.CONFIRM' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div [ngSwitch]="!!boilerMaintenanceContract.checkedOff">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !boilerMaintenanceContract.checkedOff }">
                <div *ngSwitchCase="true">
                    <div class="u-flex-column">
                        <div class="u-flex-row u-flex-align-items-center">
                            <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                            <span class="u-margin-right-half">{{
                                'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.OK.LABEL' | translate | translate
                            }}</span>
                            <button
                                class="u-margin-left-half"
                                smvd-ui-button
                                [appearance]="buttonAppearance.Link"
                                (click)="setCheckedOff(false)"
                            >
                                {{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.OK.UNCHECK' | translate }}
                            </button>
                        </div>
                        <div class="u-color-muted u-font-size-small __confirmed-on" *ngIf="boilerMaintenanceContract.checkedOff">
                            {{ boilerMaintenanceContract.lastCheckedOffOn | date: 'dd/MM/yyyy' }}
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.NOK.LABEL' | translate }}</span>
                    </div>
                    <div class="u-margin-top-half u-margin-left-double">
                        <button
                            smvd-ui-button
                            [context]="uiContext.Alt"
                            [disabled]="!boilerMaintenanceContract.confirmed"
                            (click)="confirmCheckOff()"
                        >
                            {{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.NOK.CONFIRM' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-move-detail-extra-info [collapsed]="false" *ngIf="!!boilerMaintenanceContract">
        <div ngProjectAs="header">Details</div>
        <app-boiler-maintenance-details
            ngProjectAs="content"
            [boilerMaintenanceContract]="boilerMaintenanceContract"
        ></app-boiler-maintenance-details>
    </app-move-detail-extra-info>
</div>

<ng-template #noBoilerMaintenanceContract>
    <p id="no-boiler-maintenance-contract" class="u-color-muted u-margin-bottom-triple">
        {{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.NO_CONTRACT' | translate }}
    </p>
</ng-template>
