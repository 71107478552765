import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ButtonAppearance, SvgIllustration, UiContext, UiSandbox } from '@smooved/ui';
import { StorageSandbox } from '../../storage/sandboxes/storage.sandbox';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';
import { environment } from '@environments/environment';

@Component({
    selector: 'smvd-app-companion-signup-bottom-sheet',
    templateUrl: 'companion-signup.bottom-sheet.html',
    styleUrls: ['companion-signup.bottom-sheet.scss'],
})
export class CompanionSignupBottomSheet implements OnInit {
    public readonly svgIllustration = SvgIllustration;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;
    protected readonly environment = environment;

    constructor(
        public readonly uiSandbox: UiSandbox,
        private readonly bottomSheetRef: MatBottomSheetRef<CompanionSignupBottomSheet>,
        private readonly storageSandbox: StorageSandbox
    ) {}

    public ngOnInit(): void {
        if (!this.storageSandbox.getToken(LocalStorageKeys.Companion)) {
            this.storageSandbox.setToken(LocalStorageKeys.Companion, 'show');
        }
    }

    public close(): void {
        this.bottomSheetRef.dismiss();
    }

    public hideCompanionSignup(): void {
        this.storageSandbox.setToken(LocalStorageKeys.Companion, 'hide');
        this.close();
    }
}
