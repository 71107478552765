import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonAppearance, ButtonModule, CardsModule, ServiceCardItem, UiSandbox } from '@smooved/ui';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LineupModule } from '@app/services-components/lineup/lineup.module';
import { ServicesState } from '@app/services/state/services.state';
import { Store } from '@ngrx/store';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { selectTransactionInfo } from '@app/services/state/services.selectors';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { ServiceCardConfig } from '@app/services/types/service-type';
import { routingDataUpsellServices } from '@app/services/constants/services.constants';

@Component({
    selector: 'app-thanks-container',
    template: `
        <ng-container *ngIf="{ transaction: transaction$ | async } as vm">
            <app-base-step-container>
                <h3 class="u-margin-bottom-md">{{ 'SERVICES.THANKS.TITLE' | translate }}</h3>
                <p class="u-margin-bottom-lg">
                    {{ 'SERVICES.THANKS.DESCRIPTION' | translate }} <strong>{{ vm.transaction?.user?.email }}.</strong>
                </p>
                <h6 class="u-margin-bottom-sm-md">{{ 'SERVICES.THANKS.UPSELL.TITLE' | translate }}</h6>
                <smvd-ui-service-card
                    *ngFor="let upsellService of upsellServices"
                    [title]="upsellService.titleLabel | translate"
                    [description]="upsellService.descriptionLabel | translate"
                    [service]="upsellService.serviceCardItem"
                    [id]="upsellService.serviceCardId"
                    class="u-margin-bottom-md"
                >
                    <ng-container image-content>
                        <img [ngSrc]="upsellService.imageSrc" [width]="upsellService.imageWidth" [height]="upsellService.imageHeight" />
                    </ng-container>
                    <ng-container actions-content>
                        <a
                            (click)="posthogServicesService.clickUpsellService(serviceType, upsellService.upsellServiceType)"
                            smvd-ui-button
                            [context]="uiContext.PrimaryDark"
                            [routerLink]="upsellService.routeLink"
                            class="u-w-fit-content"
                            >{{ upsellService.buttonText | translate }}</a
                        >
                    </ng-container>
                </smvd-ui-service-card>
                <div class="u-flex-row u-flex-justify-content-end">
                    <button
                        smvd-ui-button
                        (click)="posthogServicesService.clickDone(serviceType)"
                        [context]="uiContext.Text"
                        routerLink="/"
                    >
                        {{ 'COMMON.READY' | translate }}
                    </button>
                </div>
            </app-base-step-container>
        </ng-container>
    `,
    styleUrls: ['./thanks.container.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, ButtonModule, LineupModule, RouterModule, BaseStepContainer, CardsModule, NgOptimizedImage],
})
export class ThanksContainer extends BaseStepContainer {
    public transaction$ = this.store.select(selectTransactionInfo);

    public readonly upsellServices: ServiceCardConfig[] = this.route.snapshot.data[routingDataUpsellServices];

    protected readonly service = ServiceCardItem;

    constructor(
        private readonly store: Store<ServicesState>,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        public readonly uiSandbox: UiSandbox,
        public readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    protected readonly ButtonAppearance = ButtonAppearance;
}
