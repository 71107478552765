import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, tap } from 'rxjs';
import { PosthogService } from '../posthog.service';
import { DbUtils, PostHogEventsEnum, Role } from '@smooved/core';
import { MoveActionTypes } from '@app/move/state/move.actions';
import { switchMap } from 'rxjs/operators';
import { AuthenticationActionTypes, LogoutSuccess, SetAuthorizationAction } from '@app/authentication/state/authentication.actions';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AuthUtils } from '@app/authentication/auth.utils';
import { ActivityActionTypes } from '@app/activity-log/activity.actions';
import posthog from 'posthog-js';
import { ServiceActionType } from '@app/services/state/services.actions';
import { setUser } from '@sentry/angular';

@Injectable()
export class PosthogEffects {
    // todo - refactor so this is the general method which will call identify, right now this will only be used when the logged in user is a mover
    public onAuthorization$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetAuthorizationAction>(AuthenticationActionTypes.SetAuthorization),
                tap((action) => {
                    if (action.payload?.role === Role.Mover) {
                        const user = action.payload;
                        this.posthogService.identify(DbUtils.getStringId(user), user, {
                            moverType: user.moverRole,
                        });
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    public realEstateAgentLoginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionTypes.RealEstateAgentLoginSuccess, AuthenticationActionTypes.LoginSuccess),
                switchMap((_) =>
                    forkJoin([this.authenticationSandbox.authorizationOnce$, this.realEstateGroupSandbox.realEstateGroupOnce$])
                ),
                tap(([authUser, realEstateGroup]) => {
                    const userId = AuthUtils.isAdmin(authUser) ? DbUtils.getStringId(authUser) : authUser?.realEstateAgentId;
                    this.posthogService.identify(userId, authUser);
                    this.posthogService.groupByCompany(AuthUtils.isAdmin(authUser), realEstateGroup);
                    this.posthogService.groupByOffice(realEstateGroup?.officeId, realEstateGroup);
                })
            ),
        {
            dispatch: false,
        }
    );

    public heartBeat$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSuccess>(ActivityActionTypes.HeartBeat),
                switchMap((_) =>
                    forkJoin([this.authenticationSandbox.authorizationOnce$, this.realEstateGroupSandbox.realEstateGroupOnce$])
                ),
                tap(([authUser, realEstateGroup]) => {
                    const distinctId = posthog.get_distinct_id();
                    const groups = posthog.getGroups();

                    if (authUser && distinctId !== authUser?.realEstateAgentId) {
                        this.posthogService.identify(authUser.realEstateAgentId, authUser);
                    }
                    if (!groups?.company && DbUtils.getStringId(realEstateGroup)) {
                        this.posthogService.groupByCompany(AuthUtils.isAdmin(authUser), realEstateGroup);
                    }
                    if (!groups?.office && DbUtils.getStringId(realEstateGroup?.officeId)) {
                        this.posthogService.groupByOffice(realEstateGroup?.officeId, realEstateGroup);
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    public logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSuccess>(AuthenticationActionTypes.LogoutSuccess),
                tap(() => this.posthogService.resetIdentity())
            ),
        {
            dispatch: false,
        }
    );

    public desktopTransactionEnergyMeterReadingsAttachUploaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsAttachmentUploaded),
                tap(({ moveId }: { moveId: string }) => {
                    this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersEditAttachmentUploaded, moveId);
                })
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditManual$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsManual),
                tap(({ moveId }: { moveId: string }) => {
                    this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersEditManual, moveId);
                })
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditConfirmed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsConfirmed),
                tap(({ moveId }: { moveId: string }) => {
                    this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersConfirmed, moveId);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly posthogService: PosthogService,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}
}
