import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormGroupDirective,
    FormsModule,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Address, AddressUtils, PlaceResult } from '@smooved/core';
import { UiSize } from '../../ui.enums';
import { UiSandbox } from '../../ui.sandbox';
import { BaseInput } from '../base-input';
import { FormFieldAppearanceEnum } from '../enums/mat-form-field-appearance.enum';
import { LabelContainerModule } from '@ui/form/label-container';
import { CommonModule } from '@angular/common';
import { ErrorStateModule } from '@ui/form/error-state';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@ui/directives';

@Component({
    selector: 'ui-address-street-input',
    templateUrl: 'address-street-input.component.html',
    styleUrls: ['address-street-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressStreetInputComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [CommonModule, LabelContainerModule, FormsModule, ErrorStateModule, MatInputModule, DirectivesModule],
})
export class AddressStreetInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public showLabel = false;
    @Input() public showPlaceholder = true;
    @Input() public size = UiSize.Medium;
    @Input() public appearance: MatFormFieldAppearance = FormFieldAppearanceEnum.Outline;
    @Input() public types = ['address'];

    @Output() public addressSelected: EventEmitter<Address> = new EventEmitter<Address>();
    @Output() public placeResultSelected: EventEmitter<PlaceResult> = new EventEmitter<PlaceResult>();

    @ViewChild('input', { static: true, read: ElementRef })
    public input: ElementRef;

    public innerModel: string;

    public isBrowser: boolean;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        @Optional() private readonly formGroupDirective: FormGroupDirective,
        private readonly cdr: ChangeDetectorRef,
        private readonly uiSandbox: UiSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.isBrowser = this.uiSandbox.isBrowser();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public getAbstractControl(): AbstractControl {
        const control = this.formGroupDirective?.control;
        if (!control) {
            return null;
        }
        if (this.formControl) return this.formControl;
        // if formArray, get control by index
        if (control['controls']?.length) {
            return control['controls'][this.formControlName];
        }
        return control?.get(this.formControlName);
    }

    public onAutoCompleteSelected(result: PlaceResult): void {
        const address = AddressUtils.googlePlaceResultToAddress(result);
        this.addressSelected.emit(address);
        this.placeResultSelected.emit(result);
    }

    public writeValue(value: string): void {
        this.innerModel = value;
        this.cdr.detectChanges();
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel);
    }
}
