import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { SvgModule } from '../svg/svg.module';
import { CardTableWithFilter } from './components/card-table-with-filter/card-table-with-filter.component';
import { CardComponent } from './components/card/card.component';
import { ContextCardComponent } from './components/context-card/context-card.component';
import { CallToActionCardComponent } from './components/cta-card/cta-card.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { NumberCardComponent } from './components/number-card/number-card.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { CardContainerContainer } from './containers/card-container/card-container.container';

const components = [
    CardComponent,
    NumberCardComponent,
    CallToActionCardComponent,
    DashboardCardComponent,
    CardTableWithFilter,
    CardContainerContainer,
    ContextCardComponent,
    ServiceCardComponent,
];

@NgModule({
    imports: [CommonModule, IconModule, SvgModule],
    declarations: components,
    exports: components,
})
export class CardsModule {}
