import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[uiSticky]',
    standalone: true,
})
export class StickyDirective implements OnInit {
    /**
     * Define whether the element should stick to the 'top' or 'bottom'.
     * Default is 'bottom'.
     */
    @Input() stickyPosition: 'top' | 'bottom' = 'bottom';

    constructor(
        private readonly el: ElementRef,
        private readonly renderer: Renderer2
    ) {}

    @HostListener('window:resize')
    public onResize() {
        this.checkIfScrollable();
    }

    @HostListener('window:scroll')
    public onScroll() {
        this.checkIfScrollable();
    }

    public ngOnInit(): void {
        // Apply sticky positioning once the directive is initialized
        this.makeSticky();
        // Then check if the page is scrollable to toggle the shadow class
        setTimeout(() => {
            this.checkIfScrollable();
        }, 0);
    }

    private makeSticky() {
        // Ensure position is 'sticky'
        this.renderer.setStyle(this.el.nativeElement, 'position', 'sticky');

        // Depending on whether it's top or bottom, set the appropriate side
        if (this.stickyPosition === 'top') {
            this.renderer.removeStyle(this.el.nativeElement, 'bottom');
            this.renderer.setStyle(this.el.nativeElement, 'top', '0');
        } else {
            this.renderer.removeStyle(this.el.nativeElement, 'top');
            this.renderer.setStyle(this.el.nativeElement, 'bottom', '0');
        }
    }

    private checkIfScrollable() {
        const docEl = document.documentElement;
        // If content extends beyond window's inner height, show shadow
        if (docEl.scrollHeight > window.innerHeight) {
            this.renderer.addClass(this.el.nativeElement, 'ui-sticky');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'ui-sticky');
        }
    }
}
