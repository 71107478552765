import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '../base-input';
import { CommonModule } from '@angular/common';
import { LabelContainerModule } from '@ui/form/label-container';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateModule } from '@ui/form/error-state';

@Component({
    selector: 'app-email-input, smvd-ui-email-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field appearance="outline">
                <input
                    [id]="id"
                    type="email"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (ngModelChange)="onModelChange()"
                    (blur)="onBlur($event)"
                    [disabled]="innerDisabled"
                    [errorStateMatcher]="errorStateMatcher"
                    [placeholder]="placeholder"
                    [readonly]="readonly"
                    autocomplete="smooved"
                />
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
            </mat-form-field>
            <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
        </app-label-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailInputComponent),
            multi: true,
        },
    ],
    styleUrls: ['./email-input.component.scss'],
    standalone: true,
    imports: [CommonModule, LabelContainerModule, MatInputModule, FormsModule, ErrorStateModule],
})
export class EmailInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id = 'email';
    @Input() public label: string = this.translateService.instant('FORM.EMAIL.LABEL') as string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public readonly = false;
    @Input() public hint: string;

    public innerModel: string;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private translateService: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel);
    }
}
