<div class="u-flex-column u-margin-top">
    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-dark">
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate }}
            </p>
            <p class="u-color-gray-ultradark" id="label-nrn" *ngIf="move.water?.nationalRegistrationNumber; else unknownLabel">
                {{ move.water?.nationalRegistrationNumber }}
            </p>
        </div>
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.CLIENT_NUMBER' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-client-number" *ngIf="move.water?.clientNumber; else unknownLabel">
                {{ move.water?.clientNumber }}
            </p>
        </div>
    </div>

    <div class="u-margin-top u-mw50p">
        <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate }}</p>
        <p class="u-color-gray-ultradark" id="label-water-supplier" *ngIf="move.water?.waterSupplier?.name; else unknownLabel">
            {{ move.water?.waterSupplier?.name }}
        </p>
    </div>

    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-meter-number" *ngIf="move.water?.meterNumber; else unknownLabel">
                {{ move.water?.meterNumber }}
            </p>
        </div>
        <div id="meter-reading" class="u-flex-grow-1 u-mw50p u-margin-top-up-to-and-including-phone-landscape">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-meter-reading" *ngIf="move?.water?.meterReading; else unknownLabel">
                {{ move?.water?.meterReading }}
            </p>
        </div>
    </div>

    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1 u-mw50p">
            <div class="u-min-w-0">
                <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.BROKER_ATTACHMENTS' | translate }}</p>
                <ng-container
                    *ngIf="
                        (move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.Attachment, undefined])
                            ?.length;
                        else noBrokerAttach
                    "
                >
                    <div
                        class="u-flex-row u-padding-y-axis-half u-padding-right u-border-radius u-bordered-gray-lightest u-margin-bottom-quarter u-flex-align-items-center __attachment-row"
                        *ngFor="
                            let waterTransferAsset of move.water?.waterTransferAssets
                                | waterTransferAssetFilter: [WaterTransferAssetType.Attachment, undefined]
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="waterTransferAssetTemplate"
                            [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                        ></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="u-flex-grow-1 u-mw50p">
            <div class="u-min-w-0">
                <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TRANSFER_DOCUMENT' | translate }}</p>
                <ng-container
                    *ngIf="
                        (move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.TransferDocument])?.length;
                        else noTransferDoc
                    "
                >
                    <div
                        class="u-flex-row u-padding-y-axis-half u-padding-right u-border-radius u-bordered-gray-lightest u-margin-bottom-quarter u-flex-align-items-center __attachment-row"
                        *ngFor="
                            let waterTransferAsset of move.water?.waterTransferAssets
                                | waterTransferAssetFilter: [WaterTransferAssetType.TransferDocument]
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="waterTransferAssetTemplate"
                            [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                        ></ng-container>
                    </div>
                </ng-container>

                <p class="u-color-gray-dark u-margin-top">
                    {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.EXTRA_EMAIL_ATTACHMENTS' | translate }}
                </p>
                <ng-container
                    *ngIf="
                        (move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.EmailAttachment])?.length;
                        else noExtraAttach
                    "
                >
                    <div
                        class="u-flex-row u-padding-y-axis-half u-padding-right u-border-radius u-bordered-gray-lightest u-margin-bottom-quarter u-flex-align-items-center __attachment-row"
                        *ngFor="
                            let waterTransferAsset of move.water?.waterTransferAssets
                                | waterTransferAssetFilter: [WaterTransferAssetType.EmailAttachment]
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="waterTransferAssetTemplate"
                            [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                        ></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #unknownLabel>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.UNKNOWN' | translate }}</p>
</ng-template>

<ng-template #noTransferDoc>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NO_TRANSFER_DOCUMENT' | translate }}</p>
</ng-template>

<ng-template #noExtraAttach>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NO_EXTRA_ATTACHMENTS' | translate }}</p>
</ng-template>

<ng-template #noBrokerAttach>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NO_BROKER_ATTACHMENTS' | translate }}</p>
</ng-template>

<ng-template #waterTransferAssetTemplate let-waterTransferAsset="waterTransferAsset">
    <smvd-ui-svg-illustration
        class="u-margin-right-half u-flex-no-shrink __mime-icon"
        [svg]="waterTransferAsset?.mime === Mimetypes.Pdf ? SvgIllustration.PdfFile : SvgIllustration.ImageFile"
    ></smvd-ui-svg-illustration>
    <div class="u-color-gray-ultradark __file-name">{{ waterTransferAsset?.name }}</div>
</ng-template>
