import { SelectInput } from '@ui/form';
import { Svg } from '@ui/icon';

export enum PropertyAge {
    OlderThanTenYears = 'olderThanTenYears',
    YoungerThanTenYears = 'youngerThanTenYears',
}

export const options: SelectInput<PropertyAge>[] = [
    {
        name: 'property-age',
        value: PropertyAge.OlderThanTenYears,
        id: PropertyAge.OlderThanTenYears,
        labelResource: 'SERVICES.ORDER_INFO.PROPERTY_AGE.OLDER_THAN_TEN_YEARS',
        svg: Svg.Pre2000,
    },
    {
        name: 'property-age',
        value: PropertyAge.YoungerThanTenYears,
        id: PropertyAge.YoungerThanTenYears,
        labelResource: 'SERVICES.ORDER_INFO.PROPERTY_AGE.YOUNGER_THAN_TEN_YEARS',
        svg: Svg.Post2000,
    },
];
