<app-card-container>
    <div class="u-container u-margin-0-auto">
        <smvd-ui-card>
            <app-loading-container [loading]="loading">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <h3 class="u-margin-bottom">{{ 'MOVE.DETAILS.TITLE' | translate }}</h3>
                    <app-alert
                        *ngIf="processed"
                        [context]="uiContext.Success"
                        icon="check_circle"
                        [iconContext]="uiContext.Success"
                        class="u-w100p u-margin-bottom-xs u-margin-top-xs"
                    >
                        <h6 class="u-margin-bottom-xs">{{ 'MOVE.DETAILS.PROCESSED.TITLE' | translate }}</h6>
                        <p>
                            {{ 'MOVE.DETAILS.PROCESSED.BODY' | translate }}
                            <a class="u-color-success u-font-weight-normal" [href]="contactUsLink$ | async" target="_blank">
                                {{ 'MOVE.DETAILS.PROCESSED.CONTACT_US' | translate }}
                            </a>
                        </p>
                    </app-alert>
                    <app-date-input [label]="'Transfer date'" [formControlName]="'movingDate'"></app-date-input>

                    <div class="u-flex-row u-flex-justify-content-end">
                        <button smvd-ui-button type="submit" [disabled]="form.pristine" [context]="uiContext.PrimaryDark">
                            {{ 'SAVE' | translate }}
                        </button>
                    </div>
                </form>
            </app-loading-container>
        </smvd-ui-card>
    </div>
</app-card-container>
