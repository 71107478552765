<smvd-ui-bottom-sheet class="__companion-signup-bottom-sheet u-display-block u-margin-y-axis-half" [showCloseBtn]="false">
    <div class="u-flex-row u-flex-justify-content-flex-end">
        <a
            smvd-ui-svg-illustration
            class="u-color-gray-ultradark u-cursor-pointer u-icon-24"
            [svg]="svgIllustration.CloseButton"
            (click)="close()"
        ></a>
    </div>
    <h3 class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.COMPANION.TITLE' | translate }}</h3>
    <div class="__companion-signup-content u-color-gray-dark u-margin-bottom">
        <p [innerHtml]="'REAL_ESTATE_AGENT.COMPANION.HEADER' | translate"></p>
        <ul class="u-list-style-disc u-margin-bottom-half">
            <li>{{ 'REAL_ESTATE_AGENT.COMPANION.LIST.ITEM_1' | translate }}</li>
            <li>{{ 'REAL_ESTATE_AGENT.COMPANION.LIST.ITEM_2' | translate }}</li>
            <li>{{ 'REAL_ESTATE_AGENT.COMPANION.LIST.ITEM_3' | translate }}</li>
        </ul>
        <p class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.COMPANION.PARAGRAPH_1' | translate }}</p>
    </div>
    <div class="u-flex-column u-flex-align-items-center">
        <a
            smvd-ui-button
            class="u-font-weight-bold u-w100p u-text-align-center u-margin-bottom-sm-md u-margin-left-none"
            [context]="context.Alt"
            (click)="hideCompanionSignup()"
            data-testid="companion-signup-bottom-sheet-dont-show-again-button"
            >{{ 'REAL_ESTATE_AGENT.COMPANION.CTA.DONT_SHOW_AGAIN' | translate }}</a
        >
        <a
            *ngIf="uiSandbox.isMobile; else learnMore"
            smvd-ui-button
            class="u-font-weight-bold u-w100p u-text-align-center"
            [href]="'/companion'"
            [context]="context.PrimaryDark"
            >{{ 'REAL_ESTATE_AGENT.COMPANION.CTA.TRY_NOW' | translate }}</a
        >
    </div>
</smvd-ui-bottom-sheet>

<ng-template #learnMore>
    <a
        smvd-ui-button
        class="u-font-weight-bold u-w100p u-text-align-center"
        target="_blank"
        [href]="environment.companionBlogPostUrl"
        [context]="context.PrimaryDark"
        >{{ 'REAL_ESTATE_AGENT.COMPANION.CTA.LEARN_MORE' | translate }}</a
    >
</ng-template>
