import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseInput } from '../base-input';
import { FormFieldAppearanceEnum } from '../enums/mat-form-field-appearance.enum';
import { LabelContainerModule } from '@ui/form/label-container';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateModule } from '@ui/form/error-state';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'smvd-ui-phone-input',
    templateUrl: './phone-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneInputComponent),
            multi: true,
        },
    ],
    styleUrls: ['./phone-input.component.scss'],
    standalone: true,
    imports: [CommonModule, LabelContainerModule, MatInputModule, FormsModule, ErrorStateModule],
})
export class PhoneInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public appearance: MatFormFieldAppearance = FormFieldAppearanceEnum.Outline;

    public innerModel: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        const value = this.innerModel.trim();
        this.propagateChange(value ? value : null);
    }
}
