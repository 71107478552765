import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveTrackEnergyComponent } from '@app/move/components/move-track-energy/move-track-energy.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MobileMoveEnergyMetersComponent } from '@app/real-estate-agent/modals/mobile-move-energy-meters/mobile-move-energy-meters.component';
import { AppI18nKeyType, appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { I18nKeyService, RxjsComponent, skipWhileEmpty } from '@smooved/core';
import { MenuItemSize, MobileModalComponent, ModalClosingData, ModalSandbox } from '@smooved/ui';
import { takeUntil, tap } from 'rxjs/operators';
import { MobileMoveLeaverGeneralDetailsComponent } from '../mobile-move-leaver-general-details/mobile-move-leaver-general-details.component';
import { MobileMoveOverviewMenuItem } from '../mobile-move-overview/mobile-move-overview-menu-item.interface';
import { MoveDetailWaterDataModal } from '../move-detail-water-data/move-detail-water-data-modal.component';
import { i18nKeys } from './mobile-move-leaver-overview.constants';

@Component({
    selector: 'app-mobile-move-leaver-overview',
    templateUrl: './mobile-move-leaver-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMoveLeaverOverviewComponent extends RxjsComponent implements OnInit {
    @ViewChild(MobileModalComponent) public readonly modal: MobileModalComponent;

    public menuItemGeneralDetails: MobileMoveOverviewMenuItem;
    public availableServices: MobileMoveOverviewMenuItem[];

    public readonly move$ = this.moveSandbox.move$;
    public readonly fetchMoveEvent = new EventEmitter<string>();
    public readonly menuSize = MenuItemSize;
    public readonly i18nKeys = appI18nKeyTypes;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox,
        private readonly i18nKeyService: I18nKeyService,
        @Inject(MAT_DIALOG_DATA) public readonly data: ModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        this.move$.pipe(skipWhileEmpty(), tap(this.setMenuItems)).subscribe();
        this.fetchMoveEvent.pipe(takeUntil(this.destroy$)).subscribe(this.fetch);
    }

    public fetch = (moveId: string): void => {
        this.moveSandbox.fetch(moveId);
    };

    public openEnergy(move: Move): void {
        const data = {
            move: move?.linkedMove,
            patchedSubject: this.data.patchedSubject,
            readOnly: !!(move?.linkedMove as Move)?.moveStates?.energyDocumentsMovingAddressByAdmin,
        };

        this.modalSandbox.openModal(MobileMoveEnergyMetersComponent, { data }, (_) => this.onModalClose(), null, null, null);
    }

    public openWater(move: Move): void {
        const data = {
            move: move.linkedMove,
            patchedSubject: this.data.patchedSubject,
            readOnly: !!(move?.linkedMove as Move)?.moveStates?.waterDocumentsMovingAddressByAdmin,
        };
        const afterClose = (updatedMove: Move) => {
            const { leaver } = MoveUtils.getMovers(updatedMove || move);
            this.moveSandbox.setMoveState(leaver)
            this.onModalClose();
        };

        this.moveSandbox.setMoveState(move.linkedMove as Move);
        this.modalSandbox.openModal(MoveDetailWaterDataModal, { data }, afterClose, null, null, null);
    }

    private openEnergyModal = (header: string) => {
        return (): void => {
            this.modalSandbox.openClosableModal({
                component: MoveTrackEnergyComponent,
                config: { header },
            });
        };
    };

    private openWaterModal = (move: Move, header: string) => {
        return (): void => {
            const { transferee } = MoveUtils.getMovers(move);
            const data = {
                move: transferee,
                patchedSubject: this.data.patchedSubject,
                readOnly: !!transferee?.moveStates?.waterDocumentsMovingAddressByAdmin,
            };

            const onClose = (updatedMove: Move) => {
                const { leaver } = MoveUtils.getMovers(updatedMove || move);
                this.moveSandbox.setMoveState(leaver)
            }
            this.moveSandbox.setMoveState(transferee);
            this.modalSandbox.openModal(MoveDetailWaterDataModal, { data }, onClose , null, null, null);
        };
    };

    private onModalClose(): void {
        const patched = this.data.patchedSubject.value;
        if (patched) {
            this.moveSandbox.fetch(this.data.id);
        }
    }

    private setMenuItems = (move: Move): void => {
        const overviewDataLabel = this.i18nKeyService.instant(i18nKeys.overviewData, AppI18nKeyType.RealEstateAgentDashboard);
        const transferEnergyLabel = this.i18nKeyService.instant(i18nKeys.energy, AppI18nKeyType.RealEstateAgentDashboard);
        const transferWaterLabel = this.i18nKeyService.instant(i18nKeys.water, AppI18nKeyType.RealEstateAgentDashboard);
        const expectedMovingDateLabel = this.i18nKeyService.instant(i18nKeys.expectedMovingDate, AppI18nKeyType.RealEstateAgentDashboard, {
            date: (move.linkedMove as Move)?.formattedMovingDate,
        });

        this.menuItemGeneralDetails = {
            label: overviewDataLabel,
            subLabel: (move.linkedMove as Move)?.formattedMovingDate ? expectedMovingDateLabel : '-',
            navigate: this.openGeneralDetails,
            isVisible: true,
            level: 0,
        };

        this.availableServices = [
            {
                label: this.translateService.instant(transferEnergyLabel) as string,
                subLabel: move.formattedOrderedByAdmin || '-',
                navigate: this.openEnergyModal(transferEnergyLabel),
                isVisible: true,
                level: 1,
            },
            {
                label: this.translateService.instant(transferWaterLabel) as string,
                subLabel: move.formattedOrderedByAdmin || '-',
                navigate: this.openWaterModal(move, transferWaterLabel),
                isVisible: true,
                level: 1,
            },
        ];
    };

    private openGeneralDetails = (): void => {
        const data = {
            id: this.data.id,
            changeMoveSubject: this.data.changeMoveSubject,
        };

        this.modalSandbox.openModal(
            MobileMoveLeaverGeneralDetailsComponent,
            { data },
            this.onMobileLeaverGeneralDetailsClose,
            null,
            null,
            null
        );
    };

    private onMobileLeaverGeneralDetailsClose = (result: ModalClosingData): void => {
        if (result.openMove) this.modal.close({ openMove: result.openMove });
    };
}
