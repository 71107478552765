import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    EventArg,
    EventName,
    FEATURE_TOGGLE_CONFIG,
    FeatureScope,
    FeatureScopeSandbox,
    FeatureToggleConfig,
    NavigationData,
    NavigationSandbox,
    RxjsComponent,
} from '@smooved/core';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import {
    BottomSheetSandbox,
    MenuItemIconSize,
    MenuItemSize,
    ScreenSize,
    SvgIllustration,
    UiSandbox,
    UiVerticalAlignment,
} from '@smooved/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MainContainerTemplateService } from './main-container-template.service';
import { StorageSandbox } from '../storage/sandboxes/storage.sandbox';
import { LocalStorageKeys } from '../storage/sandboxes/storage.constants';
import { CompanionSignupBottomSheet } from '../real-estate-agent/bottom-sheet/companion-signup.bottom-sheet';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';

@Component({
    selector: 'app-main-container-template',
    templateUrl: './main-container-template.component.html',
    styleUrls: ['./main-container-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainContainerTemplateComponent extends RxjsComponent implements OnInit {
    public hasSecondaryNavigation = new BehaviorSubject<boolean>(false);
    public hasTertiaryNavigation = new BehaviorSubject<boolean>(false);
    public scrolled: boolean;
    public collapsed: boolean;
    public blockHover: boolean;

    public showSecondaryNavigation$: Observable<boolean>;
    public showTertiaryNavigation$: Observable<boolean>;

    public secondaryNavigationCollapsed$ = this.templateService.secondaryNavigationCollapsed$;
    public secondaryNavigationCollapsedOpen$ = this.templateService.secondaryNavigationCollapsedOpen$;
    public secondaryNavigationCollapsing$ = this.templateService.secondaryNavigationCollapsing$;
    public showSideBar$ = combineLatest([
        this.navigationSandbox.routeNavigationEndData$.pipe(map((data): boolean => data[NavigationData.ShowSideBar])),
        this.uiSandbox.tabletPortraitUp$,
    ]).pipe(map(([showSideBar, tabletPortraitUp]) => showSideBar && tabletPortraitUp));

    public readonly menuItemSize = MenuItemSize;
    public readonly illustrationEnum = SvgIllustration;
    public readonly verticalAlignment = UiVerticalAlignment;
    public readonly menuItemIconSize = MenuItemIconSize;
    public readonly featureScope = FeatureScope;

    @HostListener(EventName.WindowScroll, [EventArg.$Event]) onWindowScroll(): void {
        //In chrome and some browser scroll is given to body tag
        this.scrolled = !!(document.documentElement.scrollTop || document.body.scrollTop);
    }

    constructor(
        public readonly uiSandbox: UiSandbox,
        private readonly templateService: MainContainerTemplateService,
        private readonly navigationSandbox: NavigationSandbox,
        public readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        public readonly authenticationSandbox: AuthenticationSandbox,
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly bottomSheetSandbox: BottomSheetSandbox,
        private readonly storageSandbox: StorageSandbox,
        @Inject(FEATURE_TOGGLE_CONFIG) private readonly config: FeatureToggleConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.uiSandbox.screenSize$.pipe(takeUntil(this.destroy$)).subscribe((screenSize) => {
            this.blockHover = screenSize !== ScreenSize.Desktop;
        });

        this.showSecondaryNavigation$ = combineLatest([this.hasSecondaryNavigation, this.uiSandbox.tabletPortraitUp$]).pipe(
            map(([secondaryNavigation, portraitUp]) => secondaryNavigation && portraitUp)
        );
        this.showTertiaryNavigation$ = combineLatest([this.hasTertiaryNavigation, this.uiSandbox.tabletPortraitUp$]).pipe(
            map(([tertiaryNavigation, portraitUp]) => tertiaryNavigation && portraitUp)
        );

        this.uiSandbox.upToAndIncludingTabletPortrait$.pipe(takeUntil(this.destroy$)).subscribe((upToAndIncludingTabletPortrait) => {
            upToAndIncludingTabletPortrait
                ? this.templateService.collapseSecondaryNavigation()
                : this.templateService.expandSecondaryNavigation();
        });

        this.authenticationSandbox.isRealEstateAgentOnce$
            .pipe(
                filter(
                    (isRealEstateAgent) =>
                        (!!isRealEstateAgent && this.storageSandbox.getToken(LocalStorageKeys.Companion) === 'show') ||
                        !this.storageSandbox.getToken(LocalStorageKeys.Companion)
                )
            )
            .subscribe(() => {
                this.bottomSheetSandbox.open({
                    component: CompanionSignupBottomSheet,
                    panelClass: 'mat-bottom-sheet-corner',
                    backdropClass: 'mat-bottom-sheet-backdrop-corner',
                });
            });
    }

    public secondaryNavigationOnActivate(): void {
        // TODO: Temporary hiding on mobile view. Style should be improved
        this.hasSecondaryNavigation.next(true);
    }

    public secondaryNavigationOnDeactivate(): void {
        this.hasSecondaryNavigation.next(false);
    }

    public tertiaryNavigationOnActivate(): void {
        // TODO: Temporary hiding on mobile view. Style should be improved
        this.hasTertiaryNavigation.next(true);
    }

    public tertiaryNavigationOnDeactivate(): void {
        this.hasTertiaryNavigation.next(false);
    }

    public toggleCollapsed(): void {
        this.templateService.toggleSecondaryNavigationCollapseState();
    }

    public openCollapsedHover(): void {
        if (this.blockHover) return;
        this.templateService.setSecondaryNavigationCollapsedOpenState(true);
    }

    private closeCollapsedHover(): void {
        if (this.blockHover) return;
        this.templateService.setSecondaryNavigationCollapsedOpenState(false);
    }

    public onMouseOutSecondary(): void {
        if (this.blockHover) return;
        if (!this.hasTertiaryNavigation.value) this.closeCollapsedHover();
    }

    public onMouseOutTertiary(): void {
        if (this.blockHover) return;
        this.closeCollapsedHover();
    }
}
