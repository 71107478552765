import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { updateTransaction } from '@app/services/state/services.actions';
import { selectTransactionInfo } from '@app/services/state/services.selectors';
import { ServicesState } from '@app/services/state/services.state';
import { PosthogTransferDateSource } from '@app/tracking/enums/posthog.enum';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { PosthogService } from '@app/tracking/posthog.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Address, PostHogEventsEnum } from '@smooved/core';
import { UiSandbox } from '@smooved/ui';
import { AddressInputComponent } from '@ui/form/address-input/address-input.component';
import { DateInputComponent } from '@ui/form/date-input/date-input.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-transaction-details-container',
    template: `
        <app-base-step-container (next)="onNext()">
            <h3 class="u-margin-bottom-md">{{ 'SERVICES.FULFILLMENT.TRANSACTION_DETAILS.TITLE' | translate }}</h3>
            <div class="u-service-card u-margin-bottom-md">
                <form [formGroup]="form">
                    <app-date-input
                        [id]="'moving-date'"
                        [label]="'COMMON.TRANSFER_DATE' | translate"
                        [placeholder]="'DATE.PLACEHOLDER' | translate"
                        formControlName="movingDate"
                    ></app-date-input>
                    <smvd-ui-address-input
                        maxWidth="612px"
                        [label]="'COMMON.ADDRESS' | translate"
                        formControlName="movingAddress"
                    ></smvd-ui-address-input>
                </form>
            </div>
        </app-base-step-container>
    `,
    standalone: true,
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, BaseStepContainer, AddressInputComponent, DateInputComponent],
})
export class TransactionDetailsContainer extends BaseStepContainer implements OnInit {
    public form = this.formBuilder.group({
        movingDate: this.formBuilder.control<Date | null>(null, [Validators.required]),
        movingAddress: this.formBuilder.control<Address | null>(null, [Validators.required]),
    });

    constructor(
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        private readonly store: Store<ServicesState>,
        private readonly formBuilder: FormBuilder,
        private readonly posthogServicesService: PosthogServicesService,
        private readonly posthogService: PosthogService,
        public readonly uiSandbox: UiSandbox,
    ) {
        super(route, router, uiSandbox);
    }

    public ngOnInit(): void {
        this.store
            .select(selectTransactionInfo)
            .pipe(take(1))
            .subscribe((state) => {
                this.form.patchValue({
                    movingDate: state.movingDate,
                    movingAddress: state.user?.movingAddress,
                });
            });
    }

    public onNext(): void {
        this.form.controls['movingDate'].markAsTouched();
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        this.posthogServicesService.clickNextTransactionDetails(this.serviceType);
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.UpdateTransferDate, {
            dateFilledOut: this.form.get('movingDate').dirty,
            from: PosthogTransferDateSource.BoilerMaintenanceTransactionDetails,
        });
        this.store.dispatch(
            updateTransaction({
                movingDate: this.form.value.movingDate,
                user: {
                    movingAddress: this.form.value.movingAddress,
                },
            })
        );
        super.onNext();
    }
}
