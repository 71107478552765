import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddressModule } from '@app/address/address.module';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { GiftModule } from '@app/gift/gift.module';
import { ModalModule } from '@app/modal/modal.module';
import { MoveWizardModule } from '@app/move-wizard/move-wizard.module';
import { TableColumnDetailArrowComponent } from '@app/move/columns/table-column-detail-arrow/table-column-detail-arrow.component';
import { TableColumnEanCountComponent } from '@app/move/columns/table-column-ean-count/table-column-ean-count.component';
import { TableColumnEnergyFlowTypeComponent } from '@app/move/columns/table-column-energy-flow-type/table-column-energy-flow-type.component';
import { TableColumnFeeInsurancesComponent } from '@app/move/columns/table-column-fee-insurances/table-column-fee-insurances.component';
import { TableColumnFeeComponent } from '@app/move/columns/table-column-fee/table-column-fee.component';
import { TableColumnFormattedNameComponent } from '@app/move/columns/table-column-formatted-name/table-column-formatted-name.component';
import { TableColumnImpersonateComponent } from '@app/move/columns/table-column-impersonate/table-column-impersonate.component';
import { TableColumnInsurancesFlowTypeComponent } from '@app/move/columns/table-column-insurances-flow-type/table-column-insurances-flow-type.component';
import { ContactAddressDetailComponent } from '@app/move/components/contact-address-detail/contact-address-detail.component';
import { ContactLogsOverviewComponent } from '@app/move/components/contact-logs-overview/contact-logs-overview.component';
import { CurrentAddressDetailComponent } from '@app/move/components/current-address-detail/current-address-detail.component';
import { CreateContactLogModal } from '@app/move/modals/create-contact-log/create-contact-log.modal';
import { DetailModal } from '@app/move/modals/detail/detail.modal';
import { RentalInspectionAssetModal } from '@app/move/modals/rental-inspection-asset/rental-inspection-asset.component';
import { OrderGiftPipe } from '@app/move/pipes/order-gift.pipe';
import { moveReducerMock } from '@app/move/state/move.reducer.mock';
import { TranslationModule } from '@app/translation/translation.module';
import { CheckItemModule } from '@app/ui/check-item/check-item.module';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule as CorePipeModule } from '@smooved/core';
import { AlertModule, SvgModule, TrackAndTraceModule } from '@smooved/ui';
import { CardModule } from '../card/card.module';
import { ContractModule } from '../contract/contract.module';
import { EnergyModule } from '../energy/energy.module';
import { SharedModule } from '../shared/shared.module';
import { LegalModule } from '../wizard/legal/legal.module';
import { TableColumnMovingAddressComponent } from './columns/table-column-moving-address/table-column-moving-address.component';
import { TableColumnMovingDateComponent } from './columns/table-column-moving-date/table-column-moving-date.component';
import { TableColumnNameAddressComponent } from './columns/table-column-name-address/table-column-name-address.component';
import { TableColumnServicesSelectedComponent } from './columns/table-column-services-selected/table-column-services-selected.component';
import { BaseServiceActionComponent } from './components/base-service-action/base-service-action.component';
import { ConfirmEnergySuccessActionsComponent } from './components/confirm-energy-success-actions/confirm-energy-success-actions.component';
import { EnergyDocumentsMovingAddressWithoutContractComponent } from './components/energy-documents-moving-address-without-contract/energy-documents-moving-address-without-contract.component';
import { ExpectedMovingDateDetailComponent } from './components/expected-moving-date-detail/expected-moving-date-detail.component';
import { FeeComponent } from './components/fee/fee.component';
import { ImpersonateComponent } from './components/impersonate/impersonate.component';
import { LeadPassingFormComponent } from './components/lead-passing-form/lead-passing-form.component';
import { LeavingAddressDetailComponent } from './components/leaving-address-detail/leaving-address-detail.component';
import { MoveNpsDetailComponent } from './components/move-nps-detail/move-nps-detail.component';
import { MoveRemarkDetailComponent } from './components/move-remark/move-remark-detail.component';
import { MovingAddressDetailComponent } from './components/moving-address-detail/moving-address-detail.component';
import { MovingDateDetailComponent } from './components/moving-date-detail/moving-date-detail.component';
import { OfferSelectedComponent } from './components/offer-selected/offer-selected.component';
import { PersonExtraDetailsComponent } from './components/person-extra-details/person-extra-details.component';
import { RealEstateAgentDetailComponent } from './components/real-estate-agent-detail/real-estate-agent-detail.component';
import { TelecomInfoDetailComponent } from './components/telecom-info-detail/telecom-info-detail.component';
import { TransfereeDetailComponent } from './components/transferee-detail/transferee-detail.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserLanguageComponent } from './components/user-language/user-language.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { ConfirmEnergyEotsSuccessContainer } from './containers/confirm-energy-eots-success/confirm-energy-eots-success.container';
import { ConfirmEnergySuccessContainer } from './containers/confirm-energy-success/confirm-energy-success.container';
import { ConfirmEnergyContainer } from './containers/confirm-energy/confirm-energy.container';
import { ConfirmContainer } from './containers/confirm/confirm.container';
import { MoveDetailsContainer } from './containers/details/move-details.container';
import { MoveResponseInterceptor } from './interceptors/move-response.interceptor';
import { ActiveMoveModal } from './modals/active-move/active-move.modal';
import { ExtraInfoModal } from './modals/extra-info/extra-info.modal';
import { UpdateLeaverSuppliersModal } from './modals/update-leaver-suppliers/update-leaver-suppliers.component';
import { MoveRoutingModule } from './move-routing.module';
import { MoveEffects } from './state/move.effects';
import { combinedReducer } from './state/move.reducer';

@NgModule({
    declarations: [
        ConfirmContainer,
        ConfirmEnergyContainer,
        ConfirmEnergySuccessContainer,
        ConfirmEnergyEotsSuccessContainer,
        TransfereeDetailComponent,
        MovingAddressDetailComponent,
        ContactAddressDetailComponent,
        CurrentAddressDetailComponent,
        MovingDateDetailComponent,
        DetailModal,
        RealEstateAgentDetailComponent,
        FeeComponent,
        PersonExtraDetailsComponent,
        TelecomInfoDetailComponent,
        MoveRemarkDetailComponent,
        MoveNpsDetailComponent,
        BaseServiceActionComponent,
        ImpersonateComponent,
        UserDetailComponent,
        TableColumnDetailArrowComponent,
        ContactLogsOverviewComponent,
        ExtraInfoModal,
        LeadPassingFormComponent,
        ActiveMoveModal,
        OfferSelectedComponent,
        WizardComponent,
        TableColumnMovingAddressComponent,
        TableColumnFormattedNameComponent,
        TableColumnFeeComponent,
        TableColumnFeeInsurancesComponent,
        TableColumnMovingDateComponent,
        TableColumnServicesSelectedComponent,
        TableColumnEnergyFlowTypeComponent,
        TableColumnInsurancesFlowTypeComponent,
        TableColumnEanCountComponent,
        ExpectedMovingDateDetailComponent,
        LeavingAddressDetailComponent,
        ConfirmEnergySuccessActionsComponent,
        EnergyDocumentsMovingAddressWithoutContractComponent,
        UserLanguageComponent,
        OrderGiftPipe,
        TableColumnNameAddressComponent,
        UpdateLeaverSuppliersModal,
        RentalInspectionAssetModal,
        TableColumnImpersonateComponent,
        CreateContactLogModal,
        MoveDetailsContainer,
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MoveResponseInterceptor,
            multi: true,
        },
    ],
    imports: [
        MoveRoutingModule,
        StoreModule.forFeature('move', environment.initialData ? moveReducerMock : combinedReducer),
        EffectsModule.forFeature([MoveEffects]),
        MatButtonModule,
        TranslateModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        CardModule,
        EnergyModule,
        LegalModule,
        SharedModule,
        ContractModule,
        AddressModule,
        ModalModule,
        SvgModule,
        TranslationModule,
        MoveWizardModule,
        GiftModule,
        CheckItemModule,
        TrackAndTraceModule,
        CorePipeModule,
        DocumentCenterModule,
        AlertModule,
        EnergyModule,
    ],
    exports: [
        TransfereeDetailComponent,
        MovingAddressDetailComponent,
        ContactAddressDetailComponent,
        CurrentAddressDetailComponent,
        MovingDateDetailComponent,
        DetailModal,
        RealEstateAgentDetailComponent,
        FeeComponent,
        PersonExtraDetailsComponent,
        MoveRemarkDetailComponent,
        PersonExtraDetailsComponent,
        TelecomInfoDetailComponent,
        MoveNpsDetailComponent,
        ContactLogsOverviewComponent,
        ImpersonateComponent,
        UserDetailComponent,
        TableColumnDetailArrowComponent,
        ExtraInfoModal,
        LeadPassingFormComponent,
        OfferSelectedComponent,
        TableColumnMovingAddressComponent,
        TableColumnFormattedNameComponent,
        TableColumnFeeComponent,
        TableColumnFeeInsurancesComponent,
        TableColumnMovingDateComponent,
        TableColumnServicesSelectedComponent,
        TableColumnEnergyFlowTypeComponent,
        TableColumnInsurancesFlowTypeComponent,
        TableColumnEanCountComponent,
        ExpectedMovingDateDetailComponent,
        LeavingAddressDetailComponent,
        EnergyDocumentsMovingAddressWithoutContractComponent,
        UserLanguageComponent,
        OrderGiftPipe,
        TableColumnNameAddressComponent,
        TableColumnImpersonateComponent,
    ],
})
export class MoveModule {}
