import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { MoveService } from '@app/move/services/move.service';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { LineupModule } from '@app/services-components/lineup/lineup.module';
import { routeFulfillmentMaintenanceSchedule } from '@app/services/boiler-maintenance/boiler-maintenance-routing.module';
import { BoilerMaintenanceService } from '@app/services/boiler-maintenance/services/boiler-maintenance.service';
import { updateTransaction } from '@app/services/state/services.actions';
import { selectFeature, selectTransactionInfo } from '@app/services/state/services.selectors';
import { ServicesState } from '@app/services/state/services.state';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DbUtils, FormatAddressPipe } from '@smooved/core';
import { ButtonModule, FormatDatePipe, NamePipe, UiSandbox } from '@smooved/ui';
import { combineLatest } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { StickyDirective } from '@ui/sticky/sticky.directive';

@Component({
    selector: 'app-overview-container',
    template: `
        <ng-container *ngIf="{ isMobile: uiSandbox.isMobile } as vm">
            <div [ngClass]="{ 'u-padding-top-lg': !vm.isMobile }">
                <h3 class="u-margin-bottom-md u-container-lg u-margin-x-axis-auto u-padding-x-axis">
                    {{ 'SERVICES.OVERVIEW.TITLE' | translate }}
                </h3>
                <div
                    class="u-container-lg u-padding-x-axis u-margin-x-axis-auto u-grid u-grid-gap-md"
                    [ngClass]="{ '__container-desktop': !vm.isMobile, '__container-mobile': vm.isMobile }"
                >
                    <aside class="u-flex-column u-background-white">
                        <app-lineup-offer
                            class="u-stroke-secondary u-box-shadow-default u-flex-grow-1 u-w100p"
                            [offer]="service?.selectedOffer"
                            [selectable]="false"
                        >
                        </app-lineup-offer>
                    </aside>
                    <main class="u-flex-column u-flex-justify-content-space-between u-service-card">
                        <div class="u-flex-grow-1">
                            <h5 class="u-margin-bottom">{{ 'SERVICES.OVERVIEW.TRANSACTION_DETAILS' | translate }}</h5>
                            <div class="u-flex-row u-flex-wrap u-margin-bottom">
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'COMMON.ADDRESS' | translate
                                    }}</span>
                                    <span class="u-white-space-no-wrap">{{
                                        transaction?.user?.movingAddress | formatAddress: ' ' : false
                                    }}</span>
                                </div>
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'COMMON.TRANSFER_DATE' | translate
                                    }}</span>
                                    <span>{{ transaction?.movingDate | formatDate }}</span>
                                </div>
                            </div>
                            <h5 class="u-margin-bottom">{{ 'SERVICES.OVERVIEW.CONTACT_DETAILS' | translate }}</h5>
                            <div class="u-flex-row u-flex-wrap u-margin-bottom">
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'COMMON.NAME' | translate
                                    }}</span>
                                    <span>{{ transaction?.user | name }}</span>
                                </div>
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'COMMON.EMAIL' | translate
                                    }}</span>
                                    <span>{{ transaction?.user?.email }}</span>
                                </div>
                            </div>
                            <div class="u-flex-row u-flex-wrap u-margin-bottom">
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'CONTACT_ADDRESS' | translate
                                    }}</span>
                                    <span class="u-white-space-no-wrap">{{
                                        transaction?.user?.contactAddress | formatAddress: ' ' : false
                                    }}</span>
                                </div>
                                <div class="u-min-width-50p u-margin-bottom">
                                    <span class="u-display-block u-font-size-sm u-color-content-secondary-default">{{
                                        'COMMON.PHONE' | translate
                                    }}</span>
                                    <span>{{ transaction?.user?.phoneNumber }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                (click)="posthogServicesService.clickChangeFulfillment(serviceType)"
                                smvd-ui-button
                                [context]="uiContext.Tertiary"
                                [routerLink]="routeFulfillmentMaintenanceSchedule"
                                >{{ 'SERVICES.OVERVIEW.FULFILLMENT_EDIT' | translate }}</a
                            >
                        </div>
                    </main>
                </div>

                <div
                    *ngIf="vm.isMobile; else footerDesktop"
                    class="u-flex-column u-padding-top-md u-padding-right u-padding-bottom u-padding-left"
                >
                    <div
                        [innerHTML]="'SERVICES.OVERVIEW.TERMS_AND_CONDITIONS' | translate"
                        class="u-color-content-secondary-default u-font-size-xs u-margin-bottom"
                    ></div>
                    <button smvd-ui-button [isLoading]="loading" [disabled]="loading" (click)="submit()" [context]="uiContext.Secondary">
                        {{ 'COMMON.SUBMIT' | translate }}
                    </button>
                </div>

                <ng-template #footerDesktop>
                    <div uiSticky>
                        <div
                            class="u-flex-row u-flex-justify-content-end u-flex-align-items-center u-background-content-application u-padding u-container-lg u-margin-x-axis-auto"
                        >
                            <div
                                [innerHTML]="'SERVICES.OVERVIEW.TERMS_AND_CONDITIONS' | translate"
                                class="u-color-content-secondary-default u-font-size-xs u-margin-right-md u-text-align-right u-container-extra-small"
                            ></div>
                            <button
                                smvd-ui-button
                                [isLoading]="loading"
                                [disabled]="loading"
                                (click)="submit()"
                                [context]="uiContext.Secondary"
                            >
                                {{ 'COMMON.SUBMIT' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    `,
    styleUrls: ['./overview.container.scss'],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        TranslateModule,
        ButtonModule,
        LineupModule,
        FormatAddressPipe,
        FormatDatePipe,
        RouterModule,
        NamePipe,
        StickyDirective,
    ],
})
export class OverviewContainer extends BaseStepContainer implements OnInit {
    public readonly routeFulfillmentMaintenanceSchedule = routeFulfillmentMaintenanceSchedule;
    public service: ServicesState;
    public transaction: Partial<Move>;

    public loading = false;

    constructor(
        private readonly store: Store<ServicesState>,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        public readonly uiSandbox: UiSandbox,
        private readonly moveService: MoveService,
        private readonly boilerMaintenanceService: BoilerMaintenanceService,
        public readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    public ngOnInit(): void {
        combineLatest([this.store.select(selectFeature), this.store.select(selectTransactionInfo)])
            .pipe(take(1))
            .subscribe(([service, transaction]) => {
                this.service = service;
                this.transaction = transaction;
            });
    }

    public submit(): void {
        if (!this.transaction || !this.service) return;

        this.loading = true;

        this.transactionId$
            .pipe(
                take(1),
                switchMap((transactionId) =>
                    transactionId
                        ? this.moveService.patch(transactionId, this.transactionFactory())
                        : this.moveService.register(this.transactionFactory())
                ),
                switchMap((response) => {
                    const id = DbUtils.getStringId(response);
                    this.store.dispatch(updateTransaction({ _id: id }));
                    return this.boilerMaintenanceService.createContract({
                        transactionId: id,
                        maintenanceSchedule: this.service.fulfillment.maintenanceSchedule,
                        boilerMaintenanceOfferId: DbUtils.getStringId(this.service?.selectedOffer),
                        termsAndConditions: true,
                    });
                }),
                finalize(() => (this.loading = false))
            )
            .subscribe((response) => {
                this.posthogServicesService.confirmOffer(this.serviceType);
                super.onNext();
            });
    }

    private transactionId$ = this.store.select(selectTransactionInfo).pipe(map((transaction) => DbUtils.getStringId(transaction)));

    private transactionFactory(): Partial<Move> {
        return {
            user: {
                firstName: this.transaction.user?.firstName,
                lastName: this.transaction.user?.lastName,
                email: this.transaction.user?.email,
                phoneNumber: this.transaction.user?.phoneNumber,
                movingAddress: this.transaction.user?.movingAddress,
                contactAddress: this.transaction.user?.contactAddress,
            },
            movingDate: this.transaction.movingDate,
            legal: {
                smoovedTermsAndConditions: true,
            },
            meta: {
                propertyAge: this.service.orderInfo.propertyAge,
            },
        };
    }
}
