import { Injectable } from '@angular/core';
import { State } from '@app/store/state';
import { select, Store } from '@ngrx/store';
import { selectOrderedBoilerMaintenanceContract } from '@app/services/state/services.selectors';
import { BoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { clearServicesState, setOrderedBoilerMaintenanceContract } from '../state/services.actions';

@Injectable({
    providedIn: 'root',
})
export class ServicesSandbox {
    public orderedBoilerMaintenanceContract$ = this.store$.pipe(select(selectOrderedBoilerMaintenanceContract));

    constructor(private readonly store$: Store<State>) {}

    public setOrderedBoilerMaintenanceContract(boilerMaintenanceContract: BoilerMaintenanceContract): void {
        this.store$.dispatch(setOrderedBoilerMaintenanceContract(boilerMaintenanceContract));
    }

    public clearServicesState(): void {
        this.store$.dispatch(clearServicesState());
    }
}
