import { createAction } from '@ngrx/store';
import { Fulfillment, OrderInfo } from '@app/services/state/services.state';
import { ServiceOffer } from '@app/services/types/service-offer';
import { Move } from '@app/move/interfaces/move';
import { BoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';

export enum ServiceActionType {
    ClearServicesState = '[Services] Clear services state',

    UpdateOrderInfo = '[Services] Update order info',
    SelectOffer = '[Services] Select offer',
    UpdateFulfillment = '[Services] Update fulfillment',
    UpdateTransaction = '[Services] Update transaction',

    SetOrderedBoilerMaintenanceContract = '[Services] SetOrderedBoilerMaintenanceContract',
}

export const clearServicesState = createAction(ServiceActionType.ClearServicesState);

export const updateOrderInfo = createAction(ServiceActionType.UpdateOrderInfo, (orderInfo: OrderInfo) => ({
    orderInfo,
}));

export const selectOffer = createAction(ServiceActionType.SelectOffer, (offer: ServiceOffer) => ({ offer }));

export const updateFulfillment = createAction(ServiceActionType.UpdateFulfillment, (fulfillment: Fulfillment) => ({
    fulfillment,
}));

// this will just update the move info right now
export const updateTransaction = createAction(ServiceActionType.UpdateTransaction, (transaction: Partial<Move>) => ({
    transaction,
}));

export const setOrderedBoilerMaintenanceContract = createAction(
    ServiceActionType.SetOrderedBoilerMaintenanceContract,
    (boilerMaintenanceContract: BoilerMaintenanceContract) => ({
        boilerMaintenanceContract,
    })
);
