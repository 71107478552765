import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { EnergyMetersState, WaterMeterState } from '@smooved/core';

@Component({
    selector: 'smvd-ui-meter-state-pill-base',
    templateUrl: 'meter-state-pill-base.component.html',
    styleUrls: ['meter-state-pill-base.component.scss'],
})
export class MeterStatePillBaseComponent implements OnInit, OnChanges {
    @Input() public meterState: EnergyMetersState | WaterMeterState = EnergyMetersState.Incomplete;
    @Input() public todo: boolean = false;

    public stateKey: string;
    public stateClass = `__meter-state--${EnergyMetersState.Incomplete}`;
    public iconUrl: string;

    public ngOnInit(): void {
        this.setAppearanceAndState();
    }

    public ngOnChanges({ meterState }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(meterState)) {
            this.setAppearanceAndState();
        }
    }

    protected setAppearanceAndState(): void {
        this.setIconUrl();
    }

    protected setIconUrl(): void {}
}
