import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { RequestBuilder } from '@app/http/builders/request.builder';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { CreateContactLogAddresseesRequest } from '@app/move/interfaces/create-contact-log-addressees-request';
import { CreateContactLogRequest } from '@app/move/interfaces/create-contact-log-request';
import { Move } from '@app/move/interfaces/move';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { State } from '@app/store/state';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { CreatedByFlow } from '@app/wizard/move/interfaces/created-by-flow.interface';
import { MoveForLeaver } from '@app/wizard/move/interfaces/move-for-leaver';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import { Address, Asset, dateFormatAngularDefault, DbUtils, ObjectUtils, PaginationRequest, Role } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { isPlainObject, merge, set } from 'lodash';
import { combineLatest, MonoTypeOperatorFunction, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { concatMap, filter, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import * as constants from '../constants/move.constants';
import { MilestoneLogType } from '../enums/milestone-log-type.enum';
import { ServiceStatus } from '../enums/service-status.enum';
import { ConfirmRequest } from '../interfaces/confirm-request';
import { CreateMoveOptions } from '../interfaces/create-move-options';
import { DeleteMoveOptionsRequest } from '../interfaces/delete-move-options.request';
import { Log } from '../interfaces/log';
import { MoveSandboxPatchMoveRequest } from '../interfaces/patch-move.request';
import { SendConfirmationRequest } from '../interfaces/send-confirmation-request';
import { MoveService } from '../services/move.service';
import {
    Confirm,
    ConfirmEnergy,
    ConfirmOffers,
    CreateMove,
    CreateMoveForLeaver,
    DeleteMove,
    energyMeterReadingsAttachmentUploaded,
    energyMeterReadingsConfirmed,
    energyMeterReadingsManual,
    FetchMove,
    GetMoveByAccessTokenAndId,
    PatchMove,
    PatchMoveState,
    PatchProperty,
    PatchRealEstateAgent,
    SetLatestMoveState,
    SetMoveState,
} from '../state/move.actions';
import {
    getAdditionalNotesState,
    getAmountCanOrderState,
    getCanOrderState,
    getContactAddress,
    getConvertPowerSolarPanelsState,
    getCreatedByFlowState,
    getCurrentAddress,
    getDigitalStreamTelevisionState,
    getEanCodeElectricityCurrentState,
    getEanCodeGasCurrentState,
    getEanCodesCompleteState,
    getEmailState,
    getEnergyCompleteState,
    getEnergyOfferOrTransferSelectedState,
    getEnergyOfferSelectedState,
    getEnergyOfferSelectionState,
    getEnergyOfferState,
    getEnergyOrderedByAdminState,
    getEnergyOrderedState,
    getEnergyPaymentType,
    getEnergySelectedState,
    getEnergySuggestionSelectionState,
    getEnergyTransferSelectedState,
    getEnergyTransferState,
    getEnergyTypeState,
    getFamilyMemberCountState,
    getFirstNameState,
    getFixedLineState,
    getFormattedMovingAddress,
    getGenderState,
    getHasAMoveState,
    getHasContactDetailsState,
    getHasExclusiveNightMeterState,
    getHasFixedLineState,
    getHasInternetState,
    getHasLinkedMoveState,
    getHasMobilePhoneState,
    getHasSolarPanelsState,
    getHeatElectricityState,
    getHomeDescriptionState,
    getIdState,
    getInitialMoveState,
    getInsurancesCompleteState,
    getInsurancesOfferSelectedState,
    getInsurancesOfferSelectionState,
    getInsurancesOrderedState,
    getInsurancesProposalUrlState,
    getInsurancesStatesState,
    getInsurancesStatusIsConfirmedState,
    getInsurancesStatusIsOrderedState,
    getInsurancesStatusIsPaidState,
    getInsurancesStatusIsSelectedState,
    getInterestedState,
    getIsLeaverState,
    getIsSomeCompleteState,
    getLandAgentState,
    getLastNameState,
    getLegalHopalaOptInPartnerState,
    getMetaState,
    getMeterReadingsCompleteState,
    getMeterTypeState,
    getMinimalInformationState,
    getMobileBundleCompleteState,
    getMonthlyEnergyAdvanceAmount,
    getMoveState,
    getMovingAddress,
    getMovingDateState,
    getNoIdState,
    getPayableShoppingCartLengthState,
    getPayableShoppingCartState,
    getPayableShoppingCartWithoutInsurancesLengthState,
    getPhoneNumberState,
    getProfessionalState,
    getProviderChoiceState,
    getRealEstateGroupServicesInsurancesDisabledState,
    getSepaRequiredState,
    getShoppingCartState,
    getShowCompleteDataState,
    getShowEnergyCompleteDataState,
    getShowInsurancesCompleteDataState,
    getShowMajorListState,
    getShowShoppingCartState,
    getShowTelecomCompleteDataState,
    getTelecomBundleState,
    getTelecomCompleteState,
    getTelecomInstallationCompleteState,
    getTelecomInstallationDateByAdminState,
    getTelecomInstallationState,
    getTelecomOfferOrTransferSelectedState,
    getTelecomOfferSelectedState,
    getTelecomOfferSelectionState,
    getTelecomOfferState,
    getTelecomOrderedByAdminState,
    getTelecomOrderedState,
    getTelecomPaymentType,
    getTelecomSelectedState,
    getTelecomTransferSelectedState,
    getTelecomTransferState,
    getTransfereeIsTenantState,
    getTransfereeTypeState,
    getUserState,
    getVacancyState,
    getWaterInfoCurrentAddressState,
    getWaterInfoMovingAddressState,
    getWaterState,
} from '../state/move.reducer';
import { MoveUtils } from '../state/move.utils';

@Injectable({
    providedIn: 'root',
})
export class MoveSandbox {
    public readonly updatedMoveTrigger = new Subject<void>();
    public move$ = this.store$.pipe(select(getMoveState));
    public moveOnce$ = this.move$.pipe(take(1));
    public hasAMove$ = this.store$.pipe(select(getHasAMoveState));
    public id$ = this.store$.pipe(select(getIdState));
    public idOnce$ = this.id$.pipe(take(1));
    public movingDate$ = this.store$.pipe(select(getMovingDateState));
    public movingDateOnce$ = this.movingDate$.pipe(take(1));
    public createdByFlow$ = this.store$.pipe(select(getCreatedByFlowState));

    public user$ = this.store$.pipe(select(getUserState));
    public userOnce$ = this.user$.pipe(take(1));
    public firstName$ = this.store$.pipe(select(getFirstNameState));
    public lastName$ = this.store$.pipe(select(getLastNameState));
    public gender$ = this.store$.pipe(select(getGenderState));
    public phoneNumber$ = this.store$.pipe(select(getPhoneNumberState));
    public email$ = this.store$.pipe(select(getEmailState));
    public emailOnce$ = this.email$.pipe(take(1));
    public currentAddress$ = this.store$.pipe(select(getCurrentAddress));
    public currentAddressOnce$ = this.currentAddress$.pipe(take(1));
    public movingAddress$ = this.store$.pipe(select(getMovingAddress));
    public movingAddressOnce$ = this.movingAddress$.pipe(take(1));
    public formattedMovingAddress$: Observable<string> = this.store$.pipe(select(getFormattedMovingAddress));
    public contactAddress$ = this.store$.pipe(select(getContactAddress));
    public isLeaver$: Observable<boolean> = this.store$.pipe(select(getIsLeaverState));
    public hasLinkedMove$: Observable<boolean> = this.store$.pipe(select(getHasLinkedMoveState));
    public landAgent$ = this.store$.pipe(select(getLandAgentState));
    public transfereeType$ = this.store$.pipe(select(getTransfereeTypeState));
    public transfereeTypeOnce$ = this.transfereeType$.pipe(take(1));
    public transfereeIsTenant$ = this.store$.pipe(select(getTransfereeIsTenantState));
    public meta$ = this.store$.pipe(select(getMetaState));
    public metaOnce$ = this.meta$.pipe(take(1));
    public familyMemberCount$ = this.store$.pipe(select(getFamilyMemberCountState));
    public familyMemberCountOnce$ = this.familyMemberCount$.pipe(take(1));
    public homeDescription$ = this.store$.pipe(select(getHomeDescriptionState));

    public telecomOffer$ = this.store$.pipe(select(getTelecomOfferState));
    public telecomOfferOnce$ = this.telecomOffer$.pipe(take(1));
    public telecomTransfer$ = this.store$.pipe(select(getTelecomTransferState));
    public telecomTransferOnce$ = this.telecomTransfer$.pipe(take(1));
    public telecomPaymentType$ = this.store$.pipe(select(getTelecomPaymentType));
    public telecomSelected$: Observable<boolean> = this.store$.pipe(select(getTelecomSelectedState));
    public telecomSelectedOnce$: Observable<boolean> = this.telecomSelected$.pipe(take(1));
    public telecomOrdered$: Observable<boolean> = this.store$.pipe(select(getTelecomOrderedState));
    public telecomOrderedByAdmin$: Observable<boolean> = this.store$.pipe(select(getTelecomOrderedByAdminState));
    public telecomOfferSelected$: Observable<boolean> = this.store$.pipe(select(getTelecomOfferSelectedState));
    public telecomOfferSelectedOnce$: Observable<boolean> = this.telecomOfferSelected$.pipe(take(1));
    public telecomOfferOrTransferSelected$: Observable<boolean> = this.store$.pipe(select(getTelecomOfferOrTransferSelectedState));
    public telecomOfferOrTransferSelectedOnce$: Observable<boolean> = this.telecomOfferOrTransferSelected$.pipe(take(1));

    public showTelecomCtaInConfirm$ = combineLatest(this.telecomOrdered$, this.hasAMove$, this.createdByFlow$).pipe(
        map(([telecomOrdered, hasAMove, createdByFlow]) => !telecomOrdered && hasAMove && !createdByFlow?.energyOptimization)
    );

    public telecomBundle$ = this.store$.pipe(select(getTelecomBundleState));
    public telecomBundleOnce$ = this.telecomBundle$.pipe(take(1));

    public digitalStreamTelevision$ = this.store$.pipe(select(getDigitalStreamTelevisionState));
    public digitalStreamTelevisionOnce$ = this.digitalStreamTelevision$.pipe(take(1));

    public hasInternet$ = this.store$.pipe(select(getHasInternetState));
    public hasMobilePhone$ = this.store$.pipe(select(getHasMobilePhoneState));
    public hasMobilePhoneOnce$ = this.hasMobilePhone$.pipe(take(1));
    public hasFixedLine$ = this.store$.pipe(select(getHasFixedLineState));
    public hasFixedLineOnce$ = this.hasFixedLine$.pipe(take(1));

    public providerChoice$ = this.store$.pipe(select(getProviderChoiceState));
    public providerChoiceOnce$ = this.providerChoice$.pipe(take(1));

    public mobileBundleComplete$ = this.store$.pipe(select(getMobileBundleCompleteState));

    public telecomInstallation$ = this.store$.pipe(select(getTelecomInstallationState));
    public telecomInstallationOnce$ = this.telecomInstallation$.pipe(take(1));
    public telecomInstallationComplete$ = this.store$.pipe(select(getTelecomInstallationCompleteState));
    public telecomInstallationCompleteOnce$ = this.telecomInstallationComplete$.pipe(take(1));
    public telecomInstallationDateByAdmin$ = this.store$.pipe(select(getTelecomInstallationDateByAdminState));

    public telecomOfferSelection$ = this.store$.pipe(select(getTelecomOfferSelectionState));
    public telecomOfferSelectionOnce$ = this.telecomOfferSelection$.pipe(take(1));

    public telecomTransferSelected$ = this.store$.pipe(select(getTelecomTransferSelectedState));

    public telecomComplete$ = this.store$.pipe(select(getTelecomCompleteState));
    public telecomCompleteOnce$ = this.telecomComplete$.pipe(take(1));

    public fixedLine$ = this.store$.pipe(select(getFixedLineState));

    public energyOffer$ = this.store$.pipe(select(getEnergyOfferState));
    public energyOfferOnce$ = this.energyOffer$.pipe(take(1));
    public energyType$ = this.store$.pipe(select(getEnergyTypeState));
    public energyTypeOnce$ = this.energyType$.pipe(take(1));
    public energyTypeHasElectricity$ = this.energyType$.pipe(map((energyType) => EnergyUtils.hasElectricity(energyType)));
    public energyTypeHasGas$ = this.energyType$.pipe(map((energyType) => EnergyUtils.hasGas(energyType)));
    public energyTransfer$ = this.store$.pipe(select(getEnergyTransferState));
    public energyTransferOnce$ = this.energyTransfer$.pipe(take(1));
    public energyPaymentType$ = this.store$.pipe(select(getEnergyPaymentType));
    public energySelected$: Observable<boolean> = this.store$.pipe(select(getEnergySelectedState));
    public energySelectedOnce$: Observable<boolean> = this.energySelected$.pipe(take(1));
    public energyOrdered$: Observable<boolean> = this.store$.pipe(select(getEnergyOrderedState));
    public energyOrderedByAdmin$: Observable<boolean> = this.store$.pipe(select(getEnergyOrderedByAdminState));

    public energyTransferSelected$ = this.store$.pipe(select(getEnergyTransferSelectedState));
    public energyTransferSelectedOnce$ = this.energyTransferSelected$.pipe(take(1));

    public energyOfferSelected$: Observable<boolean> = this.store$.pipe(select(getEnergyOfferSelectedState));
    public energyOfferSelectedOnce$: Observable<boolean> = this.energyOfferSelected$.pipe(take(1));

    public energyOfferOrTransferSelected$: Observable<boolean> = this.store$.pipe(select(getEnergyOfferOrTransferSelectedState));
    public energyOfferOrTransferSelectedOnce$: Observable<boolean> = this.energyOfferSelected$.pipe(take(1));

    public energyOfferSelection$ = this.store$.pipe(select(getEnergyOfferSelectionState));
    public energySuggestionSelection$ = this.store$.pipe(select(getEnergySuggestionSelectionState)); // eots flow

    public meterType$ = this.store$.pipe(select(getMeterTypeState));
    public meterTypeOnce$ = this.meterType$.pipe(take(1));

    public hasExclusiveNightMeter$ = this.store$.pipe(select(getHasExclusiveNightMeterState));
    public hasExclusiveNightMeterOnce$ = this.hasExclusiveNightMeter$.pipe(take(1));

    public heatElectricity$ = this.store$.pipe(select(getHeatElectricityState));
    public heatElectricityOnce$ = this.heatElectricity$.pipe(take(1));

    public hasSolarPanels$ = this.store$.pipe(select(getHasSolarPanelsState));
    public hasSolarPanelsOnce$ = this.hasSolarPanels$.pipe(take(1));

    public convertPowerSolarPanels$ = this.store$.pipe(select(getConvertPowerSolarPanelsState));

    public eanCodeGasCurrent$ = this.store$.pipe(select(getEanCodeGasCurrentState));
    public eanCodeGasCurrentOnce$ = this.eanCodeGasCurrent$.pipe(take(1));
    public eanCodeElectricityCurrent$ = this.store$.pipe(select(getEanCodeElectricityCurrentState));
    public eanCodeElectricityCurrentOnce$ = this.eanCodeElectricityCurrent$.pipe(take(1));

    public insurancesOfferSelected$: Observable<boolean> = this.store$.pipe(select(getInsurancesOfferSelectedState));
    public insurancesOfferSelectedOnce$: Observable<boolean> = this.insurancesOfferSelected$.pipe(take(1));
    public insurancesOrdered$: Observable<boolean> = this.store$.pipe(select(getInsurancesOrderedState));
    public insurancesOrderedOnce$: Observable<boolean> = this.insurancesOrdered$.pipe(take(1));

    public insurancesProposalUrl$ = this.store$.pipe(select(getInsurancesProposalUrlState));

    public insurancesStates$ = this.store$.pipe(select(getInsurancesStatesState));

    public insurancesStatusIsSelected$ = this.store$.pipe(select(getInsurancesStatusIsSelectedState));
    public insurancesStatusIsConfirmed$ = this.store$.pipe(select(getInsurancesStatusIsConfirmedState));
    public insurancesStatusIsOrdered$ = this.store$.pipe(select(getInsurancesStatusIsOrderedState));
    public insurancesStatusIsPaid$ = this.store$.pipe(select(getInsurancesStatusIsPaidState));

    public insurancesOfferSelection$ = this.store$.pipe(select(getInsurancesOfferSelectionState));

    public insurancesComplete$ = this.store$.pipe(select(getInsurancesCompleteState));

    public water$ = this.store$.pipe(select(getWaterState));
    public waterInfoCurrentAddress$ = this.store$.pipe(select(getWaterInfoCurrentAddressState));
    public waterInfoMovingAddress$ = this.store$.pipe(select(getWaterInfoMovingAddressState));

    public legalHopalaOptInPartnerState$ = this.store$.pipe(select(getLegalHopalaOptInPartnerState));
    public sepaRequired$: Observable<boolean> = this.store$.pipe(select(getSepaRequiredState));

    public minimalInformation$: Observable<boolean> = this.store$.pipe(select(getMinimalInformationState));

    public additionalNotes$ = this.store$.pipe(select(getAdditionalNotesState));
    public additionalNotesOnce$ = this.additionalNotes$.pipe(take(1));

    public interested$ = this.store$.pipe(select(getInterestedState));
    public interestedOnce$ = this.interested$.pipe(take(1));

    public realEstateGroupServicesInsurancesDisabled$ = this.store$.pipe(select(getRealEstateGroupServicesInsurancesDisabledState));
    public realEstateGroupServicesInsurancesDisabledOnce$ = this.realEstateGroupServicesInsurancesDisabled$.pipe(take(1));

    public monthlyEnergyAdvanceAmount$: Observable<number> = this.store$.pipe(select(getMonthlyEnergyAdvanceAmount));

    public showShoppingCart$ = this.store$.pipe(select(getShowShoppingCartState));

    public shoppingCart$ = this.store$.pipe(select(getShoppingCartState));
    public payableShoppingCart$ = this.store$.pipe(select(getPayableShoppingCartState));
    public payableShoppingCartLength$ = this.store$.pipe(select(getPayableShoppingCartLengthState));
    public payableShoppingCartWithoutInsurancesLength$ = this.store$.pipe(select(getPayableShoppingCartWithoutInsurancesLengthState));

    public payableShoppingCartHasEnergy$ = this.payableShoppingCart$.pipe(
        map((items) => {
            return !!items.find((item) => item.type === UiOfferType.Energy);
        })
    );

    public payableShoppingCartHasTelecom$ = this.payableShoppingCart$.pipe(
        map((items) => {
            return !!items.find((item) => item.type === UiOfferType.Telecom);
        })
    );

    public payableShoppingCartHasInsurances$ = this.payableShoppingCart$.pipe(
        map((items) => {
            return !!items.find((item) => item.type === UiOfferType.Insurances);
        })
    );

    /**
     *  Rxjs function: Will update the Move state.
     *  If state and newMoveState match by id, newMoveState is used,
     *  Else move is fetched.
     */
    public updateMoveState = switchMap((newMoveState: Move) => {
        return this.moveOnce$.pipe(
            tap((state) => {
                if (DbUtils.getStringId(state) === DbUtils.getStringId(newMoveState)) this.setMoveState(newMoveState);
                else if (DbUtils.getStringId(state)) this.fetch(DbUtils.getStringId(state));
            }),
            map(() => newMoveState)
        );
    });

    public canOrder$(services: { energy?: boolean; telecom?: boolean; insurances?: boolean; onlyOffers?: boolean }): Observable<boolean> {
        return this.store$.pipe(
            select(getCanOrderState, {
                energy: services?.energy,
                telecom: services?.telecom,
                insurances: services?.insurances,
                onlyOffers: services?.onlyOffers,
            })
        );
    }

    public amountCanOrder$: Observable<number> = this.store$.pipe(select(getAmountCanOrderState));

    public showMajorList$ = this.store$.pipe(select(getShowMajorListState));

    public showEnergyCompleteData$ = this.store$.pipe(select(getShowEnergyCompleteDataState));
    public showEnergyCompleteDataOnce$ = this.showEnergyCompleteData$.pipe(take(1));
    public showTelecomCompleteData$ = this.store$.pipe(select(getShowTelecomCompleteDataState));
    public showInsurancesCompleteData$ = this.store$.pipe(select(getShowInsurancesCompleteDataState));

    public showCompleteData$ = this.store$.pipe(select(getShowCompleteDataState));

    public isSomeComplete$ = this.store$.pipe(select(getIsSomeCompleteState));

    public hasContactDetails$ = this.store$.pipe(select(getHasContactDetailsState));

    public noId$ = this.store$.pipe(select(getNoIdState));

    public eanCodesComplete$: Observable<boolean> = this.store$.pipe(select(getEanCodesCompleteState));
    public meterReadingsComplete$: Observable<boolean> = this.store$.pipe(select(getMeterReadingsCompleteState));
    public energyComplete$: Observable<boolean> = this.store$.pipe(select(getEnergyCompleteState));
    public energyCompleteOnce$: Observable<boolean> = this.energyComplete$.pipe(take(1));

    public vacancy$: Observable<boolean> = this.store$.pipe(select(getVacancyState));
    public vacancyOnce$: Observable<boolean> = this.vacancy$.pipe(take(1));

    public professional$ = this.store$.pipe(select(getProfessionalState));

    public meterInfoTransfereeContactUsLink$ = this.setMailToLink();

    constructor(
        private readonly moveService: MoveService,
        private readonly datePipe: DatePipe,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly sanitizer: DomSanitizer,
        private readonly uiSandbox: AppUiSandbox,
        private readonly store$: Store<State>,
        private readonly translationSandbox: TranslationSandbox,
        private readonly translateService: TranslateService
    ) {}

    public patchProperty(
        propertyPath: string,
        value: any,
        persist: boolean = true,
        callback?: (move: Move) => any,
        checkMoveWithLatestState?: boolean,
        moveToPatch?: Move,
        persistToState: boolean = true,
        showLoadingOverlay: boolean = false,
        bypassIsDraft: boolean = false
    ): void {
        this.store$.dispatch(
            new PatchProperty({
                propertyPath,
                value,
                persist,
                callback,
                checkMoveWithLatestState,
                moveToPatch,
                persistToState,
                showLoadingOverlay,
                bypassIsDraft,
            })
        );
    }

    public setMoveStateIfDraft(move: Move): void {
        // When user has draft move, set move state to this move so the user can resume it.
        if (move?.isDraft) {
            this.setLatestMoveState(move);
            this.setMoveState(move);
        }
    }

    public initSessionForMover(accessToken?: string, moveId?: string): Observable<any> {
        return this.authenticationSandbox.authorization().pipe(
            tap((authorizationResponse) => this.authenticationSandbox.setAuthorization(authorizationResponse)),
            concatMap((authorizationResponse) => {
                if (authorizationResponse?.role !== Role.Mover || !authorizationResponse?.email) {
                    throw new Error();
                }

                let query;

                if (accessToken && moveId) {
                    query = {
                        accessToken,
                        id: moveId,
                    };
                } else {
                    query = {
                        id: authorizationResponse.id,
                    };
                }

                return this.realEstateGroupSandbox.getTheme(query).pipe(
                    filter((x) => !!x),
                    tap((theme) => this.realEstateGroupSandbox.setTheme(theme)),
                    map((_) => ({ accessToken, moveId, authorizationResponse }))
                );
            }),
            concatMap((_) => this.getNewestDraft()),
            filter((move) => {
                if (!!move && move.isDraft) {
                    return true;
                }
                throw new Error();
            }),
            tap((move) => {
                // todo - set ngrx store if submit false
                this.setLatestMoveState(move);
                this.setMoveState(move);
            })
        );
    }

    /**
     * SetLatestMoveState
     * This method is used to set the private property 'latestMoveValue' in the move.effects.ts file
     * This property is used to get the difference when a patch is made
     * @param move
     */
    public setLatestMoveState(move: Move): void {
        this.store$.dispatch(
            new SetLatestMoveState({
                move: merge({ ...getInitialMoveState() }, { ...move }),
            })
        );
    }

    public buildPayload(move: Move): Move {
        return ObjectUtils.buildPayload(move);
    }

    /**
     * Sets the move state with a move object
     * No validation is triggered whether this can be set or not
     * @param move
     */
    public setMoveState(move: Move): void {
        this.store$.dispatch(
            new SetMoveState({
                move: merge({ ...getInitialMoveState() }, { ...move }),
            })
        );
    }

    public patchMoveState(rootPath: string, propertyPath: string, value: any): void {
        this.store$.dispatch(new PatchMoveState({ rootPath, propertyPath, value }));
    }

    public patchCurrentAddress(value: Address): void {
        this.patchMoveState('user', 'currentAddress', value);
    }

    public patchRealEstateAgent(realEstateAgent: string): void {
        this.store$.dispatch(new PatchRealEstateAgent(realEstateAgent));
    }

    public confirmOffers(id: string, accessToken?: string, callback?: () => any): void {
        this.store$.dispatch(new ConfirmOffers({ id, accessToken, callback }));
    }

    public confirmEnergy(accessToken: string, id: string, callback?: () => any): void {
        this.store$.dispatch(new ConfirmEnergy({ accessToken, id, callback }));
    }

    public get(id: string): Observable<Move> {
        this.uiSandbox.moveLoading(true);
        return this.moveService.get(id).pipe(finalize(() => this.uiSandbox.moveLoading(false)));
    }

    /**
     * This method will fetch move by moveId and set in state
     * @param id
     */
    public fetch(moveId: string, callback?: (move: Move) => void): void {
        this.store$.dispatch(new FetchMove({ moveId, callback }));
    }

    /**
     * This method will fetch move with moveId and set in state if not yet in state.
     * @param id
     */
    public setStateFor(moveId: string): Observable<Move> {
        const fetched$ = new Subject<Move>();
        const complete = (move): void => {
            setTimeout(() => {
                fetched$.next(move);
                fetched$.complete();
            });
        };
        this.moveOnce$.subscribe((move) => {
            if (!move || DbUtils.getStringId(move) !== moveId) this.fetch(moveId, complete);
            else complete(move);
        });
        return fetched$.asObservable();
    }

    public getAll(paginationRequest: PaginationRequest): Observable<Move[]> {
        return this.moveService.getAll(paginationRequest);
    }

    public getCount(): Observable<{ count: number }> {
        return this.moveService.getCount();
    }

    public patch({
        moveId,
        payload: patch,
        withNotification = false,
        dialogIdToClose,
        callback,
        forceNull = false,
        onFailure,
    }: MoveSandboxPatchMoveRequest): void {
        this.store$.dispatch(
            new PatchMove({
                id: moveId,
                patch,
                withNotification,
                dialogIdToClose,
                callback,
                forceNull,
                onFailure,
            })
        );
    }

    /**
     * @deprecated: use patch
     */
    public patchMove(
        moveId: string,
        patchMoveRequest: Move,
        withNotification: boolean = false,
        dialogIdToClose?: string,
        callback?: (move: Move) => any
    ): void {
        this.store$.dispatch(
            new PatchMove({
                id: moveId,
                patch: patchMoveRequest,
                withNotification,
                dialogIdToClose,
                callback,
            })
        );
    }

    public energyMeterReadingsAttachmentUploaded(moveId: string): void {
        this.store$.dispatch(energyMeterReadingsAttachmentUploaded({ moveId }));
    }

    public energyMeterReadingsConfirmed(moveId: string): void {
        this.store$.dispatch(energyMeterReadingsConfirmed({ moveId }));
    }

    public energyMeterReadingsManual(moveId: string): void {
        this.store$.dispatch(energyMeterReadingsManual({ moveId }));
    }

    public expireMove(moveId: string): Observable<Move> {
        return this.moveService.expire(moveId);
    }

    public activateMove(moveId: string): Observable<Move> {
        return this.moveService.activate(moveId);
    }

    public delete(moveId: string, options: DeleteMoveOptionsRequest, callback?: () => any): void {
        this.store$.dispatch(new DeleteMove({ id: moveId, options, callback }));
    }

    public getNewestDraft(): Observable<Move> {
        return this.moveService.getNewestDraft();
    }

    public getByAccessTokenAndId(accessToken: string, id: string, loadingIndication: boolean = false, callback?: () => any): void {
        this.store$.dispatch(new GetMoveByAccessTokenAndId({ accessToken, id, loadingIndication, callback }));
    }

    public getActiveMoveByEmail(email: string): Observable<Move> {
        return this.moveService.getByEmail(email);
    }

    private defaultCreateMoveOptions: CreateMoveOptions = {
        showNotification: true,
        callback: null,
    };

    public createContactLogAddressees(
        moveId: string,
        createContactLogAddresseesRequest: CreateContactLogAddresseesRequest
    ): Observable<Move> {
        const { value, subject, content, cc, bcc, to, attachments, assets, language, escalationId } =
            createContactLogAddresseesRequest || {};
        const data: object = { to, cc, bcc, subject, value, content, attachments, language, escalationId };
        ObjectUtils.removeEmpty(data);
        const requestBuilder = new RequestBuilder().addDataToFormData(data);
        if (assets?.length) {
            requestBuilder.addFiles(assets);
        }
        return this.moveService.createContactLogForAddressees(moveId, requestBuilder.getFormData());
    }

    public createContactLog(
        moveId: string,
        { value, remark, content, subject, toEmail, toPhoneNumber, assets, escalationId }: CreateContactLogRequest
    ): Observable<Move> {
        const requestBuilder = new RequestBuilder().addDataToFormData({
            value,
            remark,
            content,
            subject,
            toEmail,
            toPhoneNumber,
            escalationId,
        });

        if (assets?.length) {
            requestBuilder.addFiles(assets);
        }
        return this.moveService.createContactLog(moveId, requestBuilder.getFormData());
    }

    public updateContactLog(moveId: string, contactLogId: string, remark?: string): Observable<Move> {
        return this.moveService.updateContactLog(moveId, contactLogId, remark);
    }

    public deleteContactLog(moveId: string, contactLogId: string): Observable<Move> {
        return this.moveService.deleteContactLog(moveId, contactLogId);
    }

    /**
     * Get latest move state and POST
     * @param options
     */
    public register(options: CreateMoveOptions): void {
        this.moveOnce$.subscribe((move) => {
            this.createMove(move, options);
        });
    }

    public createMove(move: Move, options: CreateMoveOptions = this.defaultCreateMoveOptions): void {
        this.store$.dispatch(
            new CreateMove({
                move,
                options,
            })
        );
    }

    public createMoveForTransferee(move: Move): Observable<string> {
        delete move._id;
        ObjectUtils.buildPayload(move);
        return this.moveService.registerForTransferee(move).pipe(...this.handleCreateOrUpdateMoveForTransfereeSuccess(move));
    }

    public updateMoveForTransferee(move: Move): Observable<string> {
        ObjectUtils.buildPayload(move);
        return this.moveService.updateForTransferee(move).pipe(...this.handleCreateOrUpdateMoveForTransfereeSuccess(move));
    }

    private handleCreateMoveForTransfereeSuccess = (moveId: string, move: Move): void => {
        const payload: Move = {};
        if (moveId) {
            payload._id = moveId;
        }
        if (move.realEstateAgent) {
            payload.realEstateAgent = DbUtils.getStringId(move.realEstateAgent);
        }

        payload.emailLeaver = move.emailLeaver;
        payload.phoneNumberLeaver = move.phoneNumberLeaver;
        payload.firstNameLeaver = move.firstNameLeaver;
        payload.lastNameLeaver = move.lastNameLeaver;
        payload.leaverType = move.leaverType;

        payload.eanCodeElectricity = move.eanCodeElectricity;
        payload.eanCodeGas = move.eanCodeGas;
        payload.eanCodeElectricityCurrent = move.eanCodeElectricityCurrent;
        payload.eanCodeGasCurrent = move.eanCodeGasCurrent;

        payload.gasMeterReading = move.gasMeterReading;
        payload.electricitySingleMeterReading = move.electricitySingleMeterReading;
        payload.electricityDoubleDayMeterReading = move.electricityDoubleDayMeterReading;
        payload.electricityDoubleNightMeterReading = move.electricityDoubleNightMeterReading;
        payload.electricityExclusiveNight = move.electricityExclusiveNight;
        payload.electricityDoubleExclusiveNightMeterReading = move.electricityDoubleExclusiveNightMeterReading;

        this.setMoveState({ ...move, ...payload });
    };

    public createMoveByFlow(move: Move, createdByFlow: CreatedByFlow, options: CreateMoveOptions = this.defaultCreateMoveOptions): void {
        this.store$.dispatch(
            new CreateMove({
                move: { ...move, ...{ createdByFlow } },
                options,
            })
        );
    }

    public createMoveForLeaverByFlow(move: MoveForLeaver, createdByFlow: CreatedByFlow, callback?: () => any): void {
        this.store$.dispatch(
            new CreateMoveForLeaver({
                move: { ...move, ...{ createdByFlow } },
                callback,
            })
        );
    }

    public sendConfirmationEmail(moveId: string, accessToken?: string): Observable<Move> {
        return this.moveService.sendConfirmationEmail(moveId, accessToken);
    }

    // single go
    public registerAndConfirmOrders(registerRequest: Move, submit?: boolean): Observable<{ id: string }> {
        delete registerRequest._id;
        const payload = this.buildPayload(registerRequest);
        return this.moveService.registerAndConfirmOrders(payload, submit);
    }

    public confirm(confirmRequest: ConfirmRequest, callback?: () => any): void {
        this.store$.dispatch(
            new Confirm({
                id: confirmRequest?.moveId,
                accessToken: confirmRequest?.accessToken,
                callback,
            })
        );
    }

    public sendConfirmation(sendConfirmationRequest: SendConfirmationRequest): Observable<any> {
        return this.moveService.sendConfirmation(sendConfirmationRequest).pipe(
            tap((_) => {
                this.notificationSandbox.success(NotificationLabel.MoveSendConfirmationSuccess);
            })
        );
    }

    public submit(id: string): Observable<void> {
        return this.moveService.submit(id);
    }

    public getState(move: Move): string {
        if (!move) {
            return '';
        }
        if (move.isConfirmed && !move.isDraft) {
            return 'MOVE.STATE.SUBMITTED';
        } else if (move.isConfirmed && move.isDraft) {
            return 'MOVE.STATE.CONFIRMED';
        } else {
            return 'MOVE.STATE.NOT_CONFIRMED';
        }
    }

    public getOrderedState(move: Move): string {
        if (!move || (!move.energyOrdered && !move.telecomOrdered)) {
            return '';
        }

        if (move.energyOrdered && move.telecomOrdered) return ' [EO | TO]';

        if (move.energyOrdered) return ' [EO]';

        if (move.telecomOrdered) return ' [TO]';

        return '';
    }

    public static createNewStateForPatch(state: any, action: PatchProperty): any {
        const propertyPathArray: string[] = action.payload.propertyPath.split('.');
        const propertyName: string = propertyPathArray[0];

        if (propertyPathArray.length > 1) {
            propertyPathArray.shift();
            const newPath = propertyPathArray.join('.');
            return {
                ...state,
                ...{
                    [propertyName]: {
                        ...state[propertyName],
                        ...set(Object.assign({}, state[propertyName]), newPath, action.payload.value),
                    },
                },
            };
        } else {
            if (!propertyName) {
                return ObjectUtils.objectAssign({ ...state }, { ...action.payload.value });
            } else {
                const newValue = isPlainObject(action.payload.value)
                    ? ObjectUtils.objectAssign({ ...state[propertyName] }, { ...action.payload.value })
                    : action.payload.value;
                return {
                    ...state,
                    ...{
                        [propertyName]: newValue,
                    },
                };
            }
        }
    }

    /**
     * Reset move state
     */
    public resetMoveState(): void {
        this.moveOnce$.subscribe((moveState) => {
            const payload = this.moveStateWithRealEstateAgentFactory(moveState);
            const id = DbUtils.getStringId(moveState);
            if (id) {
                payload._id = id;
            }
            this.setMoveState(payload);
        });
    }

    /**
     * Clear move state
     */
    public clearMoveState(callback?: () => void, fullClear?: boolean): void {
        if (fullClear) {
            this.setMoveState({});
            return;
        }
        this.moveOnce$.subscribe((moveState) => {
            this.setMoveState(this.moveStateWithRealEstateAgentFactory(moveState));
            if (callback) {
                callback();
            }
        });
    }

    public enrichMove(move: Move): void {
        if (move?.movingDate) {
            move.formattedMovingDate = this.datePipe.transform(move.movingDate, dateFormatAngularDefault);
        } else {
            set(move, 'formattedMovingDate', null);
        }

        if (move.movingDate) {
            move.movingDate = new Date(move.movingDate);
        }

        if (move?.user?.dateOfBirth) {
            move.user.dateOfBirth = new Date(move?.user?.dateOfBirth);
        }
    }

    /**
     * this is used in real estate agent move detail modal
     * @param move
     */
    public telecomOrderedSuccess(move: Move): boolean {
        return (
            (MoveUtils.telecomTransferSuccess(move?.telecomTransfer, move?.user) || !!move?.telecomOffer?.telecomOfferSelection) &&
            !!move?.telecomOrdered
        );
    }

    /**
     * this is used in real estate agent move detail modal
     * @param move
     */
    public telecomOrderedByAdminSuccess(move: Move): boolean {
        return this.telecomOrderedSuccess(move) && !!move?.telecomOrderedByAdmin;
    }

    /**
     *
     * @param moveId
     */
    public getMailAdvanceAmount(moveId: string): Observable<string> {
        return this.moveService.getMailAdvanceAmount(moveId);
    }

    /**
     * Send mail advance amount to supplier
     * @param moveId
     * @param content
     */
    public sendMailAdvanceAmount(moveId: string, content: string): Observable<Move> {
        return this.moveService.sendMailAdvanceAmount(moveId, content);
    }

    /**
     *
     * @param moveId
     */
    public getMailMeterReadings(moveId: string): Observable<string> {
        return this.moveService.getMailMeterReadings(moveId);
    }

    public getMailLeaverReadingAssets(moveId: string): Observable<{ subject: string; content: string }> {
        return this.moveService.getMailLeaverReadingAssets(moveId);
    }

    /**
     * Post meter readings
     * @param moveId
     * @param content
     */
    public postMeterReadings(moveId: string, content?: string): Observable<Move> {
        return this.moveService.postMeterReadings(moveId, content).pipe(this.updateMoveState);
    }

    /**
     *
     * @param moveId
     * @param files
     */
    public uploadEnergyMeterReadingAsset(moveId: string, files: any): Observable<Move> {
        return this.moveService.uploadEnergyMeterReadingAsset(moveId, files).pipe(this.updateMoveState);
    }

    /**
     *
     * @param moveId
     * @param key
     */
    public deleteEnergyMeterReadingAsset(moveId: string, key: string): Observable<Move> {
        return this.moveService.deleteEnergyMeterReadingAsset(moveId, key).pipe(this.updateMoveState);
    }

    /**
     *
     * @param moveId
     * @param files
     */
    public uploadEnergyDocumentAsset(moveId: string, files: any): Observable<Move> {
        return this.moveService.uploadEnergyDocumentAsset(moveId, files).pipe(this.updateMoveState);
    }

    /**
     *
     * @param moveId
     * @param key
     */
    public deleteEnergyDocumentAsset(moveId: string, key: string): Observable<Move> {
        return this.moveService.deleteEnergyDocumentAsset(moveId, key).pipe(this.updateMoveState);
    }

    public deleteEnergyAsset(moveId: string, key: string): Observable<Move> {
        return this.moveService.deleteEnergyAsset(moveId, key).pipe(this.updateMoveState);
    }

    public uploadInvoicesAssets(moveId: string, energyType: EnergyType, content: FormData): Observable<Move> {
        return energyType === EnergyType.Gas
            ? this.moveService.uploadGasInvoices(moveId, content)
            : this.moveService.uploadElectricityInvoices(moveId, content);
    }

    public deleteInvoicesAsset(moveId: string, assetId: string, energyType: EnergyType): Observable<Move> {
        return energyType === EnergyType.Gas
            ? this.moveService.deleteGasInvoice(moveId, assetId)
            : this.moveService.deleteElectricityInvoice(moveId, assetId);
    }

    public getLogTranslationLabel(log: Log<ContactLogType | MilestoneLogType>): string {
        if (!log?.value) return null;
        switch (log.value) {
            case ContactLogType.Call:
                return constants.CALL.ON;
            case ContactLogType.Voicemail:
                return constants.VOICEMAIL.ON;
            case ContactLogType.EmailEnergyUrgency:
                return constants.EMAIL_ENERGY_URGENCY.ON;
            case ContactLogType.EmailPitch:
                return constants.EMAIL_PITCH.ON;
            case ContactLogType.EmailMover:
                return constants.EMAIL_MOVER.ON;
            case ContactLogType.EmailRealEstateAgent:
                return constants.EMAIL_REALESTATEAGENT.ON;
            case ContactLogType.EmailConfirm:
                return constants.EMAIL_CONFIRM;
            case ContactLogType.EmailCustomMover:
                return constants.EMAIL_CUSTOM_MOVER.SHORT;
            case ContactLogType.EmailCustomRealEstateAgent:
                return constants.EMAIL_CUSTOM_REALESTATEAGENT.SHORT;
            case ContactLogType.EmailCustomLinkedMove:
                return constants.EMAIL_CUSTOM_LINKEDMOVE.SHORT;
            case ContactLogType.EmailCustomSupplier:
                return constants.EMAIL_CUSTOM_SUPPLIER.SHORT;
            case ContactLogType.EmailCustomOther:
                return constants.EMAIL_CUSTOM_OTHER.SHORT;
            case ContactLogType.SmsCustomMover:
                return constants.SMS_CUSTOM_MOVER.SHORT;
            case ContactLogType.EmailMeterTransferForLeaver:
                return constants.EMAIL_METER_TRANSFER_FOR_LEAVER;
            case ContactLogType.EmailSurveyRequest:
                return constants.EMAIL_SURVEY_REQUEST.SHORT;
            case ContactLogType.SmsLeaver:
                return constants.SMS_LEAVER.SHORT;
            case ContactLogType.SmsEotsRequestWithoutMover:
            case ContactLogType.SmsEotsRequestWithMover:
                return constants.SMS_EOTS_COMPLETE;
            case MilestoneLogType.Created:
                return constants.CREATED;
            case MilestoneLogType.EnergyOrdered:
                return constants.ENERGY_ORDERED;
            case ContactLogType.EmailLeaverInvite:
                return constants.EMAIL_LEAVER_INVITE;
            case ContactLogType.EmailMeterCollection:
                return constants.EMAIL_METER_COLLECTION;
            case ContactLogType.EmailMeterCollectionReminder:
                return constants.EMAIL_METER_COLLECTION_REMINDER;
            case ContactLogType.EmailMeterCollectionFinalReminder:
                return constants.EMAIL_METER_COLLECTION_FINAL_REMINDER;
            case ContactLogType.SmsMeterCollection:
                return constants.SMS_METER_COLLECTION;
            case ContactLogType.EmailEnergyStopLeaver:
                return constants.EMAIL_ENERGY_STOP_LEAVER;
            case ContactLogType.SmsEnergyStopLeaverExpectations:
                return constants.SMS_ENERGY_STOP_LEAVER_EXPECTATIONS;
            case ContactLogType.EmailEnergyStopLeaverExpectations:
                return constants.EMAIL_ENERGY_STOP_LEAVER_EXPECTATIONS;
            case ContactLogType.EmailSettingTheScene:
                return constants.EMAIL_TRANSFEREE_SETTING_THE_SCENE;
            case ContactLogType.EmailProvisionalEnergyProposal:
                return constants.EMAIL_TRANSFEREE_PROVISIONAL_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposal:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposalWithContext:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_WITH_CONTEXT;
            case ContactLogType.SmsEnergyProposal:
                return constants.SMS_TRANSFEREE_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposalVacancy:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_VACANCY;
            case ContactLogType.EmailEnergyProposalLandAgent:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_LAND_AGENT;
            case ContactLogType.EmailEnergyProposalReminder:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_REMINDER;
            case ContactLogType.EmailEnergyOrdered:
                return constants.EMAIL_TRANSFEREE_ENERGY_ORDERED;
            case ContactLogType.EmailEnergyNotInterested:
                return constants.EMAIL_TRANSFEREE_ENERGY_NOT_INTERESTED;
            case ContactLogType.EmailMeterReadingsEnergy:
                return constants.EMAIL_TRANSFEREE_METER_READINGS_ENERGY;
            case ContactLogType.EmailMeterReadingsWater:
                return constants.EMAIL_TRANSFEREE_METER_READINGS_WATER;
            case ContactLogType.EmailEnergyInstalled:
                return constants.EMAIL_TRANSFEREE_ENERGY_INSTALLED;
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter15DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_15_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter40DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_40_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter15DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_15_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter40DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_40_DAYS_LATE;
            case ContactLogType.EmailEnergyStopNotInterested:
                return constants.EMAIL_LEAVER_ENERGY_STOP_NOT_INTERESTED;
            case ContactLogType.EmailEnergyStopSupplierComplete:
                return constants.EMAIL_ENERGY_STOP_SUPPLIER_COMPLETE;
            case ContactLogType.EmailLeaverMeterReadingsEnergy:
                return constants.EMAIL_LEAVER_METER_READINGS_ENERGY;
            case ContactLogType.EmailLeaverMeterReadingsWater:
                return constants.EMAIL_LEAVER_METER_READINGS_WATER;
            case ContactLogType.EmailTransfereeNewWaterContract:
                return constants.EMAIL_TRANSFEREE_NEW_WATER_CONTRACT;
            case ContactLogType.EmailLeaverClosingWaterContract:
                return constants.EMAIL_LEAVER_CLOSING_WATER_CONTRACT;
            case ContactLogType.EmailLeaverFollowUpClosingWaterContract:
                return constants.EMAIL_LEAVER_FOLLOW_UP_CLOSING_WATER_CONTRACT;
            case ContactLogType.EmailTransfereeWaterTransferDocumentNotUploadedReminder:
                return constants.EMAIL_TRANSFEREE_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER;
            case ContactLogType.EmailLeaverWaterTransferDocumentNotUploadedReminder:
                return constants.EMAIL_LEAVER_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER;
            case ContactLogType.EmailBoilerMaintenanceOrdered:
                return constants.EMAIL_BOILER_MAINTENANCE_ORDERED;
            default:
                return constants.EMPTY;
        }
    }

    public getContactLogWithCreatedByCheckedTranslationLabel(contactLog: ContactLog): string {
        if (!contactLog?.value) return null;
        switch (contactLog.value) {
            case ContactLogType.Call:
                return constants.CALL.BY;
            case ContactLogType.Voicemail:
                return constants.VOICEMAIL.ON;
            case ContactLogType.EmailEnergyUrgency:
                return constants.EMAIL_ENERGY_URGENCY.CHECKED;
            case ContactLogType.EmailPitch:
                return constants.EMAIL_PITCH.CHECKED;
            case ContactLogType.EmailMover:
                return constants.EMAIL_MOVER.CHECKED;
            case ContactLogType.EmailRealEstateAgent:
                return constants.EMAIL_REALESTATEAGENT.CHECKED;
            case ContactLogType.EmailConfirm:
                return constants.EMAIL_CONFIRM;
            case ContactLogType.EmailMeterTransferForLeaver:
                return constants.EMAIL_METER_TRANSFER_FOR_LEAVER;
            case ContactLogType.EmailCustomMover:
                return constants.EMAIL_CUSTOM_MOVER.CHECKED;
            case ContactLogType.EmailCustomRealEstateAgent:
                return constants.EMAIL_CUSTOM_REALESTATEAGENT.CHECKED;
            case ContactLogType.EmailCustomLinkedMove:
                return constants.EMAIL_CUSTOM_LINKEDMOVE.CHECKED;
            case ContactLogType.EmailCustomSupplier:
                return constants.EMAIL_CUSTOM_SUPPLIER.CHECKED;
            case ContactLogType.EmailCustomOther:
                return constants.EMAIL_CUSTOM_OTHER.CHECKED;
            case ContactLogType.EmailSurveyRequest:
                return constants.EMAIL_SURVEY_REQUEST.CHECKED;
            case ContactLogType.EmailLeaverInvite:
                return constants.EMAIL_LEAVER_INVITE;
            case ContactLogType.EmailMeterCollection:
                return constants.EMAIL_METER_COLLECTION;
            case ContactLogType.EmailMeterCollectionReminder:
                return constants.EMAIL_METER_COLLECTION_REMINDER;
            case ContactLogType.EmailMeterCollectionFinalReminder:
                return constants.EMAIL_METER_COLLECTION_FINAL_REMINDER;
            case ContactLogType.SmsCustomMover:
                return constants.SMS_CUSTOM_MOVER.CHECKED;
            case ContactLogType.SmsLeaver:
                return constants.SMS_LEAVER.CHECKED;
            case ContactLogType.SmsEotsRequestWithoutMover:
            case ContactLogType.SmsEotsRequestWithMover:
                return constants.SMS_EOTS_COMPLETE;
            case ContactLogType.SmsMeterCollection:
                return constants.SMS_METER_COLLECTION;
            case ContactLogType.EmailEnergyStopLeaver:
                return constants.EMAIL_ENERGY_STOP_LEAVER;
            case ContactLogType.SmsEnergyStopLeaverExpectations:
                return constants.SMS_ENERGY_STOP_LEAVER_EXPECTATIONS;
            case ContactLogType.EmailEnergyStopLeaverExpectations:
                return constants.EMAIL_ENERGY_STOP_LEAVER_EXPECTATIONS;
            case ContactLogType.EmailSettingTheScene:
                return constants.EMAIL_TRANSFEREE_SETTING_THE_SCENE;
            case ContactLogType.EmailProvisionalEnergyProposal:
                return constants.EMAIL_TRANSFEREE_PROVISIONAL_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposal:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposalWithContext:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_WITH_CONTEXT;
            case ContactLogType.SmsEnergyProposal:
                return constants.SMS_TRANSFEREE_ENERGY_PROPOSAL;
            case ContactLogType.EmailEnergyProposalVacancy:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_VACANCY;
            case ContactLogType.EmailEnergyProposalLandAgent:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_LAND_AGENT;
            case ContactLogType.EmailEnergyProposalReminder:
                return constants.EMAIL_TRANSFEREE_ENERGY_PROPOSAL_REMINDER;
            case ContactLogType.EmailEnergyOrdered:
                return constants.EMAIL_TRANSFEREE_ENERGY_ORDERED;
            case ContactLogType.EmailEnergyNotInterested:
                return constants.EMAIL_TRANSFEREE_ENERGY_NOT_INTERESTED;
            case ContactLogType.EmailMeterReadingsEnergy:
                return constants.EMAIL_TRANSFEREE_METER_READINGS_ENERGY;
            case ContactLogType.EmailMeterReadingsWater:
                return constants.EMAIL_TRANSFEREE_METER_READINGS_WATER;
            case ContactLogType.EmailEnergyInstalled:
                return constants.EMAIL_TRANSFEREE_ENERGY_INSTALLED;
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter15DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_15_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter40DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_40_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter15DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_15_DAYS_LATE;
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter40DaysLate:
                return constants.EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_40_DAYS_LATE;
            case ContactLogType.EmailEnergyStopNotInterested:
                return constants.EMAIL_LEAVER_ENERGY_STOP_NOT_INTERESTED;
            case ContactLogType.EmailEnergyStopSupplierComplete:
                return constants.EMAIL_ENERGY_STOP_SUPPLIER_COMPLETE;
            case ContactLogType.EmailLeaverMeterReadingsEnergy:
                return constants.EMAIL_LEAVER_METER_READINGS_ENERGY;
            case ContactLogType.EmailLeaverMeterReadingsWater:
                return constants.EMAIL_LEAVER_METER_READINGS_WATER;
            case ContactLogType.EmailTransfereeNewWaterContract:
                return constants.EMAIL_TRANSFEREE_NEW_WATER_CONTRACT;
            case ContactLogType.EmailLeaverClosingWaterContract:
                return constants.EMAIL_LEAVER_CLOSING_WATER_CONTRACT;
            case ContactLogType.EmailLeaverFollowUpClosingWaterContract:
                return constants.EMAIL_LEAVER_FOLLOW_UP_CLOSING_WATER_CONTRACT;
            case ContactLogType.EmailTransfereeWaterTransferDocumentNotUploadedReminder:
                return constants.EMAIL_TRANSFEREE_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER;
            case ContactLogType.EmailLeaverWaterTransferDocumentNotUploadedReminder:
                return constants.EMAIL_LEAVER_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER;
            case ContactLogType.EmailBoilerMaintenanceOrdered:
                return constants.EMAIL_BOILER_MAINTENANCE_ORDERED;
            default:
                return constants.EMPTY;
        }
    }

    public getAsset(file: Asset): Observable<SafeResourceUrl> {
        const reader = new FileReader();
        return this.moveService.getAsset(file.location).pipe(
            mergeMap((response) => {
                return new Observable<SafeResourceUrl>((emit) => {
                    reader.onloadend = () => emit.next(this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string));
                    reader.readAsDataURL(response.body);
                });
            })
        );
    }

    /**
     * This method will get asset from server and open it to user
     */
    public openAsset(file: Asset): void {
        this.moveService.getAsset(file.location).subscribe((res) => {
            const anchor = document.createElement('a');
            anchor.href = URL.createObjectURL(res.body);
            anchor.download = file.name || res.headers.get('Filename');
            anchor.target = '_blank';
            anchor.click();
        });
    }

    /**
     * this method will provide energy status based on current status
     * @param move
     */
    public getEnergyStatus(move: Move): ServiceStatus {
        const meterComplete = !!move?.moveStates?.meterComplete;
        switch (true) {
            // transferee is not interested but leaver was notified of meterReadings
            case meterComplete && !!move.energyMeterTransferDocumentsMovingAddressWithoutContract:
                return ServiceStatus.LeaverNotified;
            // admin processed energy meters
            case meterComplete && move.moveStates.metersProcessedByAdmin:
                return ServiceStatus.Processed;
            case meterComplete:
                return ServiceStatus.Processing;
            default:
                return ServiceStatus.NoInfo;
        }
    }

    /**
     * this method will provide water status based on current status
     * @param move
     */
    public getWaterStatus(move: Move): ServiceStatus {
        const { waterDocumentsMovingAddressByRealEstateAgent, waterDocumentsMovingAddressByAdmin } = move?.moveStates || ({} as any);

        switch (true) {
            case !!waterDocumentsMovingAddressByRealEstateAgent && !!waterDocumentsMovingAddressByAdmin:
                return ServiceStatus.Processed;
            case !!waterDocumentsMovingAddressByRealEstateAgent:
                return ServiceStatus.Processing;
            default:
                return ServiceStatus.NoInfo;
        }
    }

    private handleCreateOrUpdateMoveForTransfereeSuccess(move: Move): [
        OperatorFunction<
            | Move
            | {
                  id: string;
              },
            Move
        >,
        OperatorFunction<Move, string>,
        MonoTypeOperatorFunction<string>,
    ] {
        return [
            tap((updatedMove: Move) =>
                this.handleCreateMoveForTransfereeSuccess((updatedMove as { id: string }).id || updatedMove._id, move)
            ),
            map((updatedMove: Move) => (updatedMove as { id: string }).id || updatedMove._id),
            finalize(() => this.uiSandbox.moveLoading(false)),
        ];
    }

    /**
     * @deprecated: use this.reviewsSandbox.updateReview
     */
    public editReviewSuggestion(moveId: string, suggestion: string): Observable<Move> {
        return this.moveService.editReviewSuggestion(moveId, suggestion);
    }

    public getAllLogs(moveId: string): Observable<Log<ContactLogType | MilestoneLogType>[]> {
        return this.moveService.getAllLogs(moveId);
    }

    public impersonate(moveId: string, dialogRef?: MatDialogRef<any>): Observable<Move> {
        return this.moveOnce$.pipe(
            switchMap((moveState) => (!(moveState && DbUtils.getStringId(moveState) === moveId) ? this.get(moveId) : of(moveState))),
            map((moveState) => (moveState.isDraft ? moveState : this.moveStateWithRealEstateAgentFactory(moveState))),
            map((move) => {
                dialogRef?.close({
                    navigatedAway: true,
                });
                this.setLatestMoveState(move);
                this.setMoveState(move);
                return move;
            })
        );
    }

    public setMailToLink(subjectLabel?: string) {
        return combineLatest([this.move$, this.translationSandbox.selected$]).pipe(
            map(([move, language]) => {
                const { transferee } = MoveUtils.getMovers(move);
                if (!transferee) return null;
                const mailTo = environment.mailTo[language] as string;
                const subject = encodeURI(
                    `[${DbUtils.getStringId(transferee)}] - ${this.translateService.instant(subjectLabel ?? 'ENERGY.ENERGY_METER_INFO.PROCESSED.SUBJECT')}`
                );

                return `mailto:${mailTo}?subject=Re:%20${subject}`;
            })
        );
    }

    private moveStateWithRealEstateAgentFactory(moveState: Move): Pick<Move, 'realEstateAgent' | '_id'> {
        const payload: Pick<Move, 'realEstateAgent' | '_id'> = {};
        if (moveState.realEstateAgent) {
            payload.realEstateAgent = DbUtils.getStringId(moveState.realEstateAgent);
        }
        return payload;
    }
}
