import { ServiceCardConfig, UpsellServiceType } from '@app/services/types/service-type';
import { ServiceCardItem } from '@ui/cards';
import { Navigation } from '@app/navigation/enums/navigation.enum';

export const routingDataPreviousStep = 'previousStep';
export const routingDataNextStep = 'nextStep';
export const routingDataServiceType = 'serviceType';
export const routingDataUpsellServices = 'upsellServices';

export const serviceCardTelecom: ServiceCardConfig = {
    titleLabel: 'DASHBOARD.TELECOM.TITLE',
    descriptionLabel: 'DASHBOARD.TELECOM.TEXT',
    serviceCardItem: ServiceCardItem.Telecom,
    upsellServiceType: UpsellServiceType.Telecom,
    serviceCardId: 'telecom-card',
    imageSrc: 'https://assets.smooved.be/images/service_telecom.png',
    imageWidth: 94,
    imageHeight: 93,
    buttonText: 'DASHBOARD.TELECOM.OFFER',
    routeLink: `/${Navigation.Telecom}`,
};
export const serviceCardBoilerMaintenance: ServiceCardConfig = {
    titleLabel: 'DASHBOARD.BOILER_MAINTENANCE.TITLE',
    descriptionLabel: 'DASHBOARD.BOILER_MAINTENANCE.TEXT',
    serviceCardItem: ServiceCardItem.BoilerMaintenance,
    upsellServiceType: UpsellServiceType.BoilerMaintenance,
    serviceCardId: 'boiler-maintenance-card',
    imageSrc: 'https://assets.smooved.be/images/service_energy.png',
    imageWidth: 72,
    imageHeight: 121,
    buttonText: 'DASHBOARD.BOILER_MAINTENANCE.OFFER',
    routeLink: `/${Navigation.Services}/${Navigation.BoilerMaintenance}`,
};
