import { createReducer, on } from '@ngrx/store';
import { ServicesState } from '@app/services/state/services.state';
import {
    clearServicesState,
    selectOffer,
    setOrderedBoilerMaintenanceContract,
    updateFulfillment,
    updateOrderInfo,
} from '@app/services/state/services.actions';

const initialState: ServicesState = {
    orderInfo: {},
    selectedOffer: null,
    fulfillment: {},
    orderedContracts: {},
};

export const serviceReducer = createReducer(
    initialState,
    on(clearServicesState, () => ({
        ...initialState,
    })),
    on(updateOrderInfo, (state, { orderInfo }) => ({
        ...state,
        orderInfo: {
            ...state.orderInfo,
            ...orderInfo,
        },
    })),
    on(selectOffer, (state, { offer }) => ({
        ...state,
        selectedOffer: offer,
    })),
    on(updateFulfillment, (state, { fulfillment }) => ({
        ...state,
        fulfillment: {
            ...state.fulfillment,
            ...fulfillment,
        },
    })),
    on(setOrderedBoilerMaintenanceContract, (state, { boilerMaintenanceContract }) => ({
        ...state,
        orderedContracts: {
            ...state.orderedContracts,
            boilerMaintenanceContract,
        },
    }))
);
