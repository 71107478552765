<app-loading-container [loading]="loading">
    <app-todo-list [label]="'FOLLOW_UP' | translate" class="u-margin-top">
        <app-todo
            class="u-display-block u-margin-top"
            [state]="TodoState.Disabled"
            [checked]="boilerMaintenanceContract?.confirmed"
            [label]="'SERVICES.BOILER_MAINTENANCE.FOLLOW_UP.CONFIRMED' | translate"
        ></app-todo>
        <app-todo
            class="u-display-block"
            [state]="TodoState.Disabled"
            [checked]="boilerMaintenanceContract?.checkedOff"
            [label]="'SERVICES.BOILER_MAINTENANCE.FOLLOW_UP.CHECKED_OFF' | translate"
        ></app-todo>
    </app-todo-list>
</app-loading-container>
