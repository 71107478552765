// todo - needs to be moved to constants files in separated sub modules (https://gitlab.com/smooved-app/smooved-app/-/issues/338) (navigation.constants.ts)

export enum Navigation {
    Dash = '/',
    Admin = 'admin',
    Start = 'start',
    StartRegisterMove = 'start/register-move',
    App = 'app',
    Thanks = 'app/thanks',
    OrderConfirmation = 'order-confirmation',
    OrderConfirmationSingleGo = 'order-confirmation/single-go',
    MoveSaved = 'app/move-saved',
    Dashboard = 'dashboard',
    Login = 'login',
    Moves = 'moves',
    Movers = 'movers',
    Authentication = 'authentication',
    RealEstateAgentDashboard = 'dashboard',
    RealEstateAgentRegister = 'authentication/real-estate-agent/register/contact-details',
    RealEstateAgent = 'real-estate-agent',
    RealEstateAgentStart = 'start',
    Management = 'management',
    Analytics = 'analytics',
    Details = 'details',
    Overview = 'overview',
    Inputs = 'inputs',
    Reviews = 'reviews',
    RealEstateAgentRegisterMove = 'register-move',
    RealEstateAgentNps = 'nps',
    Services = 'services',
    Energy = 'energy',
    EnergyTransfer = 'energy-transfer',
    EnergyContract = 'energy-contract',
    EnergySuccess = 'energy-success',
    BoilerMaintenance = 'boiler-maintenance',
    Whise = 'whise',
    EnergyOptimization = 'energy-optimization',
    Contract = 'contract',
    Telecom = 'telecom',
    TelecomTransfer = 'telecom-transfer',
    TelecomInterested = 'telecom-interested',
    TelecomQualification = 'telecom-qualification',
    Insurances = 'insurances',
    Logistics = 'logistics',
    Complete = 'complete',
    Post = 'post',
    Water = 'water',
    Surveys = 'surveys',
    Interviews = 'interviews',
    InterviewStart = 'start',
    InterviewSuccess = 'success',
    Nps = 'nps',
    Insights = 'insights',
    Exports = 'exports',
    Client = 'client',
    Office = 'office',
    FinancialReport = 'financial-report',
    QuarterReport = 'quarter-report',
    Invoices = 'invoices',
    Confirmations = 'confirmations',
    RentalInspection = 'rental-inspection',
    Mortgage = 'mortgage',
    OTS = 'ots',
    EOTS = 'eots',
    MeterReadings = 'meter-readings',
    Settings = 'settings',
    Configure = 'configure',
    Gifts = 'gifts',
    Tots = 'tots',
    Success = 'success',
    ConfirmEnergy = 'confirm-energy',
    LeaverDetails = 'leaver-details',
    ContactDetailsLeaver = 'contact-details-leaver',
    SuppliersLeaver = 'suppliers-leaver',
    RegularizationBill = 'regularization-bill',
    Leavers = 'leavers',
    Data = 'data',
    ZipCode = 'zip-code',
    Metrics = 'metrics',
    Modules = 'modules',
    Team = 'team',
}

export enum NavigationOutlet {
    Secondary = 'secondaryNavigation',
    Tertiary = 'tertiaryNavigation',
}
