import { AppI18nKeyType, i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { I18nKeyUtils } from '@smooved/core';
import { CheckInput, DropdownInput } from '@smooved/ui';

export const EMPTY = ' ';
export const CREATED = i18nContactLog('CREATED_ON');
export const ENERGY_ORDERED = i18nContactLog('ENERGY_ORDERED');
export const CALL = {
    ON: i18nContactLog('CALL_ON'),
    BY: i18nContactLog('CALL_BY'),
};
export const VOICEMAIL = {
    ON: i18nContactLog('VOICEMAIL_ON'),
    BY: i18nContactLog('VOICEMAIL_BY'),
};
export const EMAIL_ENERGY_URGENCY = {
    ON: i18nContactLog('EMAIL_ENERGY_URGENCY_ON.CHECKED'),
    CHECKED: i18nContactLog('EMAIL_ENERGY_URGENCY.CHECKED'),
};
export const EMAIL_PITCH = {
    ON: i18nContactLog('EMAIL_PITCH_ON.CHECKED'),
    CHECKED: i18nContactLog('EMAIL_PITCH.CHECKED'),
};
export const EMAIL_MOVER = {
    ON: i18nContactLog('EMAIL_MOVER_ON.CHECKED'),
    CHECKED: i18nContactLog('EMAIL_MOVER.CHECKED'),
};
export const EMAIL_REALESTATEAGENT = {
    ON: i18nContactLog('EMAIL_REAL_ESTATE_AGENT_ON.CHECKED'),
    CHECKED: i18nContactLog('EMAIL_REAL_ESTATE_AGENT.CHECKED'),
};
export const EMAIL_CONFIRM = i18nContactLog('EMAIL_CONFIRM.CHECKED');
export const EMAIL_CUSTOM_MOVER = {
    SHORT: i18nContactLog('CUSTOM_MOVER.SHORT.CHECKED'),
    CHECKED: i18nContactLog('CUSTOM_MOVER.CHECKED'),
};
export const EMAIL_CUSTOM_REALESTATEAGENT = {
    SHORT: i18nContactLog('CUSTOM_REAL_ESTATE_AGENT.SHORT.CHECKED'),
    CHECKED: i18nContactLog('CUSTOM_REAL_ESTATE_AGENT.CHECKED'),
};
export const EMAIL_CUSTOM_LINKEDMOVE = {
    SHORT: i18nContactLog('CUSTOM_LINKED_MOVE.SHORT.CHECKED'),
    CHECKED: i18nContactLog('CUSTOM_LINKED_MOVE.CHECKED'),
};
export const EMAIL_CUSTOM_SUPPLIER = {
    SHORT: i18nContactLog('CUSTOM_SUPPLIER.SHORT.CHECKED'),
    CHECKED: i18nContactLog('CUSTOM_SUPPLIER.CHECKED'),
};
export const EMAIL_CUSTOM_OTHER = {
    SHORT: i18nContactLog('CUSTOM_OTHER.SHORT.CHECKED'),
    CHECKED: i18nContactLog('CUSTOM_OTHER.CHECKED'),
};
export const EMAIL_SURVEY_REQUEST = {
    SHORT: i18nContactLog('EMAIL_SURVEY_REQUEST.SHORT.CHECKED'),
    CHECKED: i18nContactLog('EMAIL_SURVEY_REQUEST.CHECKED'),
};
export const SMS_CUSTOM_MOVER = {
    SHORT: i18nContactLog('SMS_CUSTOM_MOVER.SHORT.CHECKED'),
    CHECKED: i18nContactLog('SMS_CUSTOM_MOVER.CHECKED'),
};
export const EMAIL_METER_TRANSFER_FOR_LEAVER = i18nContactLog('EMAIL_METER_TRANSFER_LEAVER');
export const SMS_LEAVER = {
    SHORT: i18nContactLog('SMS_LEAVER.SHORT.CHECKED'),
    CHECKED: i18nContactLog('SMS_LEAVER.CHECKED'),
};
export const SMS_EOTS_COMPLETE = i18nContactLog('SMS_EOTS_COMPLETE.CHECKED');

export const EMAIL_METER_COLLECTION = i18nContactLog('EMAIL_METER_COLLECTION');
export const EMAIL_METER_COLLECTION_REMINDER = i18nContactLog('EMAIL_METER_COLLECTION_REMINDER');
export const EMAIL_METER_COLLECTION_FINAL_REMINDER = i18nContactLog('EMAIL_METER_COLLECTION_FINAL_REMINDER');
export const SMS_METER_COLLECTION = i18nContactLog('SMS_METER_COLLECTION');
export const EMAIL_ENERGY_STOP_LEAVER = i18nContactLog('EMAIL_ENERGY_STOP_LEAVER');
export const EMAIL_LEAVER_INVITE = i18nContactLog('ON.EMAIL_LEAVER_INVITE');
export const SMS_ENERGY_STOP_LEAVER_EXPECTATIONS = i18nContactLog('SMS_ENERGY_STOP_LEAVER_EXPECTATIONS');
export const EMAIL_ENERGY_STOP_LEAVER_EXPECTATIONS = i18nContactLog('EMAIL_ENERGY_STOP_LEAVER_EXPECTATIONS');
export const EMAIL_TRANSFEREE_SETTING_THE_SCENE = i18nContactLog('EMAIL_TRANSFEREE_SETTING_THE_SCENE');
export const EMAIL_TRANSFEREE_PROVISIONAL_ENERGY_PROPOSAL = i18nContactLog('EMAIL_TRANSFEREE_PROVISIONAL_ENERGY_PROPOSAL');
export const EMAIL_TRANSFEREE_ENERGY_PROPOSAL_REMINDER = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_PROPOSAL_REMINDER');
export const EMAIL_TRANSFEREE_ENERGY_PROPOSAL = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_PROPOSAL');
export const EMAIL_TRANSFEREE_ENERGY_PROPOSAL_WITH_CONTEXT = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_PROPOSAL_WITH_CONTEXT');
export const EMAIL_TRANSFEREE_ENERGY_PROPOSAL_LAND_AGENT = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_PROPOSAL_LAND_AGENT');
export const EMAIL_TRANSFEREE_ENERGY_PROPOSAL_VACANCY = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_PROPOSAL_VACANCY');
export const EMAIL_TRANSFEREE_ENERGY_ORDERED = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_ORDERED');
export const EMAIL_TRANSFEREE_ENERGY_NOT_INTERESTED = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_NOT_INTERESTED');
export const EMAIL_TRANSFEREE_METER_READINGS_ENERGY = i18nContactLog('EMAIL_TRANSFEREE_METER_READINGS_ENERGY');
export const EMAIL_TRANSFEREE_METER_READINGS_WATER = i18nContactLog('EMAIL_TRANSFEREE_METER_READINGS_WATER');
export const EMAIL_TRANSFEREE_ENERGY_INSTALLED = i18nContactLog('EMAIL_TRANSFEREE_ENERGY_INSTALLED');
export const SMS_TRANSFEREE_ENERGY_PROPOSAL = i18nContactLog('SMS_TRANSFEREE_ENERGY_PROPOSAL');
export const EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_15_DAYS_LATE = i18nContactLog(
    'EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_15_DAYS_LATE'
);
export const EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_40_DAYS_LATE = i18nContactLog(
    'EMAIL_REAL_ESTATE_AGENT_ENTERED_LEAVER_AFTER_40_DAYS_LATE'
);
export const EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_15_DAYS_LATE = i18nContactLog(
    'EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_15_DAYS_LATE'
);
export const EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_40_DAYS_LATE = i18nContactLog(
    'EMAIL_REAL_ESTATE_AGENT_ENTERED_TRANSFEREE_AFTER_40_DAYS_LATE'
);
export const EMAIL_LEAVER_ENERGY_STOP_NOT_INTERESTED = i18nContactLog('EMAIL_LEAVER_ENERGY_STOP_NOT_INTERESTED');
export const EMAIL_ENERGY_STOP_SUPPLIER_COMPLETE = i18nContactLog('EMAIL_ENERGY_STOP_SUPPLIER_COMPLETE');
export const EMAIL_LEAVER_METER_READINGS_ENERGY = i18nContactLog('EMAIL_LEAVER_METER_READINGS_ENERGY');
export const EMAIL_LEAVER_METER_READINGS_WATER = i18nContactLog('EMAIL_LEAVER_METER_READINGS_WATER');
export const EMAIL_TRANSFEREE_NEW_WATER_CONTRACT = i18nContactLog('EMAIL_TRANSFEREE_NEW_WATER_CONTRACT');
export const EMAIL_LEAVER_CLOSING_WATER_CONTRACT = i18nContactLog('EMAIL_LEAVER_CLOSING_WATER_CONTRACT');
export const EMAIL_LEAVER_FOLLOW_UP_CLOSING_WATER_CONTRACT = i18nContactLog('EMAIL_LEAVER_FOLLOW_UP_CLOSING_WATER_CONTRACT');
export const EMAIL_TRANSFEREE_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER = i18nContactLog(
    'EMAIL_TRANSFEREE_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER'
);
export const EMAIL_LEAVER_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER = i18nContactLog(
    'EMAIL_LEAVER_WATER_TRANSFER_DOCUMENT_NOT_UPLOADED_REMINDER'
);

export const EMAIL_BOILER_MAINTENANCE_ORDERED = i18nContactLog('EMAIL_BOILER_MAINTENANCE_ORDERED')

export function i18nContactLog(key: string): string {
    return I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.ContactLog, key);
}

export function i18nEscalation(key: string): string {
    return I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.Escalation, key);
}

export const createContactLogTitleResource = i18nContactLog('CREATE');

export const MAX_PAST_DAYS_BEFORE_MOVING_DATE = 14;

// valid ean always starts with 54 and is 18 characters long
export const eanStartWith = '54';
export const eanLength = 18;

export const professionalOptions: DropdownInput<boolean>[] = [
    {
        id: 'professional-false',
        labelResource: 'MOVE.CONTRACT_TYPE.NONPROFESSIONAL',
        value: false,
        name: 'professional',
    },
    {
        id: 'professional-true',
        labelResource: 'MOVE.CONTRACT_TYPE.PROFESSIONAL',
        value: true,
        name: 'professional',
    },
];

export const vacancyOption: CheckInput<boolean> = {
    id: 'vacancy',
    labelResource: 'MOVE.IS_VACANCY',
    value: true,
    name: 'is-vacancy',
};

export const customMessageModalTitle = I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.AdminCustomMessage, 'TITLE');
