import { Injectable } from '@angular/core';
import { PostHogEventsEnum } from '@smooved/core';
import { ServiceType, UpsellServiceType } from '@app/services/types/service-type';
import { PosthogService } from '@app/tracking/posthog.service';

@Injectable({ providedIn: 'root' })
export class PosthogServicesService {
    constructor(private readonly posthogService: PosthogService) {}

    public clickService(currentService: ServiceType): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickService, { currentService });
    }

    public viewLineup(currentService: ServiceType): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ViewLineup, { currentService });
    }

    public clickNextOrderInfo(currentService: ServiceType, extraProperties?: object): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickNextOrderInfo, {
            currentService,
            ...extraProperties,
        });
    }

    public clickNextFulfillment(currentService: ServiceType, extraProperties?: object): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickNextFulfillment, {
            currentService,
            ...extraProperties,
        });
    }

    public clickChangeFulfillment(currentService: ServiceType, extraProperties?: object): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickChangeFulfillment, {
            currentService,
            ...extraProperties,
        });
    }

    public clickNextTransactionDetails(currentService: ServiceType, extraProperties?: object): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickNextTransactionDetails, {
            currentService,
            ...extraProperties,
        });
    }

    public clickNextContactDetails(currentService: ServiceType, extraProperties?: object): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickNextContactDetails, {
            currentService,
            ...extraProperties,
        });
    }

    public clickChooseOffer(currentService: ServiceType, offerChosen: string): void {
        if (!currentService || !offerChosen) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickChooseOffer, {
            currentService,
            offerChosen,
        });
    }

    public clickChangeOffer(currentService: ServiceType): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickChangeOffer, {
            currentService,
        });
    }

    public confirmOffer(currentService: ServiceType): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ConfirmOffer, {
            currentService,
        });
    }

    public clickDone(currentService: ServiceType): void {
        if (!currentService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickDone, {
            currentService,
        });
    }

    public clickUpsellService(currentService: ServiceType, upsellService: UpsellServiceType): void {
        if (!currentService || !upsellService) return;
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ClickUpsellService, {
            currentService,
            upsellService,
        });
    }
}
