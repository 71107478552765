<smvd-ui-card [border]="false" [hasPadding]="false" class="__service-card">
    <div class="u-flex-responsive">
        <div class="__image-holder">
            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-h100p __background-{{ service }}">
                <ng-content select="[image-content]"></ng-content>
            </div>
        </div>
        <div class="__content-holder u-flex-column u-flex-justify-content-space-between">
            <div class="u-margin-bottom-double-up-to-and-including-phone-landscape">
                <h4 class="u-font-size-18 u-line-height-20p u-margin-bottom-xs">{{ title }}</h4>
                <p class="u-color-muted u-line-height-normal">{{ description }}</p>
            </div>
            <div class="u-flex-responsive">
                <ng-content select="[actions-content]"></ng-content>
            </div>
        </div>
    </div>
</smvd-ui-card>
