import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuccessfulResponse, HttpUtils } from '@smooved/core';
import { BoilerMaintenanceContract, BoilerMaintenanceOffer } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { environment } from '@environments/environment';
import { CreateBoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/create-boiler-maintenance-contract';
import { PropertyAge } from '@app/services-components/order-info/property-age/property-age.constants';

@Injectable()
export class BoilerMaintenanceService {
    constructor(private readonly http: HttpClient) {}

    public getLineup(propertyAge: PropertyAge): Observable<SuccessfulResponse<BoilerMaintenanceOffer[]>> {
        return this.http.get<SuccessfulResponse<BoilerMaintenanceOffer[]>>(`${environment.apiUri}/services/boiler-maintenance/lineup`, {
            params: HttpUtils.buildQueryParameters({
                propertyAge: propertyAge,
            }),
        });
    }

    public createContract(payload: CreateBoilerMaintenanceContract): Observable<SuccessfulResponse<any>> {
        return this.http.post<SuccessfulResponse<any>>(`${environment.apiUri}/services/boiler-maintenance/contract`, payload);
    }

    public getContract(transactionId: string): Observable<BoilerMaintenanceContract> {
        return this.http.get<BoilerMaintenanceContract>(`${environment.apiUri}/services/boiler-maintenance/contract/${transactionId}`);
    }

    public setConfirmed(transactionId: string, confirmed: boolean): Observable<BoilerMaintenanceContract> {
        return this.http.patch<BoilerMaintenanceContract>(
            `${environment.apiUri}/services/boiler-maintenance/contract/${transactionId}/confirmed`,
            { confirmed }
        );
    }

    public setCheckedOff(transactionId: string, checkedOff: boolean): Observable<BoilerMaintenanceContract> {
        return this.http.patch<BoilerMaintenanceContract>(
            `${environment.apiUri}/services/boiler-maintenance/contract/${transactionId}/checked-off`,
            { checkedOff }
        );
    }
}
