export enum SvgIllustration {
    RecommendedByFriends = '/assets/images/smooved-recommended.svg',
    EnergyBulb = '/assets/images/smooved-energy-bulb.svg',
    Logo = '/assets/images/smooved-original.svg',
    LogoMonochrome = '/assets/images/smooved-monochrome.svg',
    GoogleScore = '/assets/images/google-score.svg',
    FacebookScore = '/assets/images/facebook-score.svg',
    HouseMatchLogo = '/assets/images/house-match.svg',
    HouseMatchIcon = '/assets/images/house-match-icon.svg',
    HouseMatchIconMonochrome = '/assets/images/house-match-icon-monochrome.svg',
    HouseMatchIconDefault = '/assets/images/house-match-icon-default.svg',
    HouseMatchHands = '/assets/images/house-match-hands.svg',
    HouseMatchIllustrationBox = '/assets/images/house-match-illustration-box.svg',
    HouseMatchIllustrationVase = '/assets/images/house-match-illustration-vase.svg',
    HouseMatchIllustrationSigns = '/assets/images/housematch-signs.svg',

    HouseMatchStar = '/assets/images/housematch-star.svg',
    HouseMatchHalfStar = '/assets/images/housematch-half-star.svg',
    HouseMatchEmptyStar = '/assets/images/housematch-empty-star.svg',

    Tag1 = '/assets/images/tag1.svg',
    Tag2 = '/assets/images/tag2.svg',
    Tag3 = '/assets/images/tag3.svg',

    //icons
    PropertyTypeApartment = '/assets/icons/apartment.svg',
    PropertyTypeHouse = '/assets/icons/house.svg',
    LivingArea = '/assets/icons/area.svg',
    IncreaseGrowthArrow = '/assets/icons/increase-growth-arrow.svg',
    DecreaseGrowthArrow = '/assets/icons/decrease-growth-arrow.svg',
    Added = '/assets/icons/added.svg',
    CalendarSmall = '/assets/icons/calendar-small.svg',
    Calendar = '/assets/icons/calendar.svg',
    CheckedFilled = '/assets/icons/check-filled.svg',
    CheckOutline = '/assets/icons/check-outline.svg',
    ChevronDown = '/assets/icons/chevron-down.svg',
    ChevronLeft = '/assets/icons/chevron-left.svg',
    ChevronRight = '/assets/icons/chevron-right.svg',
    ChevronUp = '/assets/icons/chevron-up.svg',
    CloseMenu = '/assets/icons/close-menu.svg',
    Close = '/assets/icons/close.svg',
    Compare = '/assets/icons/compare.svg',
    Dashboard = '/assets/icons/dashboard.svg',
    Edit = '/assets/icons/edit.svg',
    ElectricitySmall = '/assets/icons/electricity-small.svg',
    Email = '/assets/icons/email.svg',
    Euro = '/assets/icons/euro.svg',
    House = '/assets/icons/house.svg',
    Info = '/assets/icons/info.svg',
    InsertEmotion = '/assets/icons/insert-emoticon.svg',
    Insights = '/assets/icons/insights.svg',
    Leaderboard = '/assets/icons/leaderboard.svg',
    Menu = '/assets/icons/menu.svg',
    Office = '/assets/icons/office.svg',
    OpenMenu = '/assets/icons/open-menu.svg',
    QuarterReport = '/assets/icons/quarter-report.svg',
    Review = '/assets/icons/review.svg',
    Settings = '/assets/icons/settings.svg',
    TelecomSmall = '/assets/icons/telecom-small.svg',
    Todo = '/assets/icons/todo.svg',
    Toggle = '/assets/icons/toggle.svg',
    Transactions = '/assets/icons/transactions.svg',
    TrendDown = '/assets/icons/trend-down.svg',
    TrendUp = '/assets/icons/trend-up.svg',
    TrendingDownBig = '/assets/icons/trending-down-big.svg',
    TrendingUpBig = '/assets/icons/trending-up-big.svg',
    Unchecked = '/assets/icons/unchecked.svg',
    Users = '/assets/icons/users.svg',
    Search = 'https://assets.smooved.be/icons/search.svg',
    ThumbsUp = 'https://assets.smooved.be/icons/thumbs-up.svg',
    ThumbsDown = 'https://assets.smooved.be/icons/thumbs-down.svg',
    Download = 'https://assets.smooved.be/icons/download.svg',
    Upload = 'https://assets.smooved.be/icons/upload.svg',
    UploadComplete = 'https://assets.smooved.be/icons/upload-complete.svg',
    xLogo = 'https://assets.smooved.be/icons/x-logo.svg',
    Archived = 'https://assets.smooved.be/icons/archived.svg',
    Mail = 'https://assets.smooved.be/icons/contact-us-mail.svg',
    Phone = 'https://assets.smooved.be/icons/contact-us-phone.svg',
    Whatsapp = 'https://assets.smooved.be/icons/contact-us-whatsapp.svg',
    Messenger = 'https://assets.smooved.be/icons/contact-us-fb-messenger.svg',
    LiveChat = 'https://assets.smooved.be/icons/contact-us-chat.svg',
    Faq = 'https://assets.smooved.be/icons/contact-us-faq.svg',
    Date = 'https://assets.smooved.be/icons/date.svg',
    User = 'https://assets.smooved.be/icons/user.svg',
    Property = 'https://assets.smooved.be/icons/property.svg',
    RealEstateAgent = 'https://assets.smooved.be/icons/real-estate-agent.svg',
    Web = 'https://assets.smooved.be/icons/web.svg',
    Google = 'https://assets.smooved.be/icons/google.svg',
    Facebook = 'https://assets.smooved.be/icons/facebook.svg',
    ReviewVerified = 'https://assets.smooved.be/icons/verified.svg',
    ReviewUnverified = 'https://assets.smooved.be/icons/unverified.svg',
    File = 'https://assets.smooved.be/icons/file.svg',
    CloseButton = 'https://assets.smooved.be/icons/close.svg',
    PaperClip = 'https://assets.smooved.be/icons/paperclip.svg',
    Document = 'https://assets.smooved.be/icons/document.svg',
    PdfFile = 'https://assets.smooved.be/icons/pdf-file.svg',
    ImageFile = 'https://assets.smooved.be/icons/image-file.svg',
    MarketPlaceIcon = 'https://assets.smooved.be/icons/smooved-deals.svg',

    // Meter state icons
    MeterStateProcessed = 'https://assets.smooved.be/icons/meter-state-processed.svg',
    MeterStateConfirmed = 'https://assets.smooved.be/icons/meter-state-confirmed.svg',
    MeterStateCompleted = 'https://assets.smooved.be/icons/meter-state-completed.svg',
    MeterStateIncomplete = 'https://assets.smooved.be/icons/meter-state-incomplete.svg',
    MeterStateNoTransfer = 'https://assets.smooved.be/icons/meter-state-no-transfer.svg',

    // Electricity Meters
    MetersElectricity = 'https://assets.smooved.be/icons/bolt.svg',
    MetersGas = 'https://assets.smooved.be/icons/flame.svg',
    MetersWater = 'https://assets.smooved.be/icons/drop.svg',

    // housematch
    Navigation = '/assets/icons/navigation.svg',
}
