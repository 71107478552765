import { Component, OnChanges, OnInit } from '@angular/core';
import { EnergyMetersState } from '@smooved/core';
import { MeterStatePillBaseComponent } from '../meter-state-pill-base/meter-state-pill-base.component';
import { TransactionUtils } from '@ui/transaction/utils/transaction.utils';
import { MeterStateType } from '@ui/transaction/constants/meter-state.constants';
import { MeterStateContext } from '@core/enums/meters-state.enum';

@Component({
    selector: 'smvd-ui-energy-meter-state-pill',
    templateUrl: '../meter-state-pill-base/meter-state-pill-base.component.html',
    styleUrls: ['energy-meter-state-pill.component.scss'],
})
export class EnergyMeterStatePillComponent extends MeterStatePillBaseComponent implements OnInit, OnChanges {
    public stateClass: string = `__meter-state--${EnergyMetersState.Incomplete}`;

    private readonly defaultMetersStateKey = EnergyMetersState[EnergyMetersState.Incomplete];

    override setAppearanceAndState(): void {
        this.stateKey = EnergyMetersState[this.meterState] ? EnergyMetersState[this.meterState] : this.defaultMetersStateKey;
        this.stateClass =
            this.todo && (this.meterState === EnergyMetersState.Incomplete || this.meterState === EnergyMetersState.Completed)
                ? '__meter-state-todo'
                : `__meter-state--${this.stateKey.toLowerCase()}`;
        this.setIconUrl();
    }

    protected setIconUrl() {
        this.iconUrl = TransactionUtils.getMeterStateIcon(
            MeterStateType.Energy,
            TransactionUtils.getMeterStateStatusEnergy(this.meterState as EnergyMetersState),
            this.todo ? MeterStateContext.Error : MeterStateContext.Default
        );
    }
}
