import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { updateTransaction } from '@app/services/state/services.actions';
import { selectTransactionInfo } from '@app/services/state/services.selectors';
import { ServicesState } from '@app/services/state/services.state';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Address } from '@smooved/core';
import { EmailInputComponent, PhoneNumberValidators, UiSandbox } from '@smooved/ui';
import { AddressInputComponent } from '@ui/form/address-input/address-input.component';
import { PhoneInputComponent } from '@ui/form/phone-input/phone-input.component';
import { TextInputComponent } from '@ui/form/text-input/text-input.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-contact-details-container',
    template: `
        <app-base-step-container (next)="onNext()">
            <h3 class="u-margin-bottom-md">{{ 'SERVICES.FULFILLMENT.CONTACT_DETAILS.TITLE' | translate }}</h3>
            <div class="u-service-card u-margin-bottom-md">
                <form [formGroup]="form">
                    <div class="u-flex-responsive u-flex-gap u-margin-bottom">
                        <app-email-input
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                            [label]="'COMMON.EMAIL' | translate"
                            formControlName="email"
                        ></app-email-input>
                        <smvd-ui-phone-input
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                            [label]="'COMMON.PHONE' | translate"
                            formControlName="phoneNumber"
                        ></smvd-ui-phone-input>
                    </div>
                    <div class="u-flex-responsive u-flex-gap u-margin-bottom">
                        <smvd-ui-text-input
                            [label]="'COMMON.FIRSTNAME' | translate"
                            formControlName="firstName"
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                        ></smvd-ui-text-input>
                        <smvd-ui-text-input
                            [label]="'COMMON.LASTNAME' | translate"
                            formControlName="lastName"
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                        ></smvd-ui-text-input>
                    </div>
                    <smvd-ui-address-input
                        maxWidth="612px"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        [label]="'CONTACT_ADDRESS' | translate"
                        formControlName="contactAddress"
                    ></smvd-ui-address-input>
                </form>
            </div>
        </app-base-step-container>
    `,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        BaseStepContainer,
        AddressInputComponent,
        EmailInputComponent,
        TextInputComponent,
        PhoneInputComponent,
    ],
})
export class ContactDetailsContainer extends BaseStepContainer implements OnInit {
    public form = this.formBuilder.group({
        email: this.formBuilder.control<string | null>(null, [Validators.required, Validators.email]),
        phoneNumber: this.formBuilder.control<string | null>(null, [Validators.required, PhoneNumberValidators.isValidPhoneNumber()]),
        firstName: this.formBuilder.control<string | null>(null, [Validators.required]),
        lastName: this.formBuilder.control<string | null>(null, [Validators.required]),
        contactAddress: this.formBuilder.control<Address | null>(null, [Validators.required]),
    });

    constructor(
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        private readonly store: Store<ServicesState>,
        private readonly formBuilder: FormBuilder,
        public readonly uiSandbox: UiSandbox,
        private readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    public ngOnInit(): void {
        this.store
            .select(selectTransactionInfo)
            .pipe(take(1))
            .subscribe((state) => {
                this.form.patchValue({
                    email: state.user?.email,
                    phoneNumber: state.user?.phoneNumber,
                    firstName: state.user?.firstName,
                    lastName: state.user?.lastName,
                    contactAddress: state.user?.contactAddress || state.user?.movingAddress,
                });
            });
    }

    public onNext(): void {
        this.markAsTouched();
        if (this.form.invalid) return;
        this.posthogServicesService.clickNextContactDetails(this.serviceType);
        this.store.dispatch(
            updateTransaction({
                user: {
                    email: this.form.value.email,
                    phoneNumber: this.form.value.phoneNumber,
                    firstName: this.form.value.firstName,
                    lastName: this.form.value.lastName,
                    contactAddress: this.form.value.contactAddress,
                },
            })
        );
        super.onNext();
    }

    private markAsTouched(): void {
        this.form.get('email').markAsTouched();
        this.form.get('phoneNumber').markAsTouched();
        this.form.get('firstName').markAsTouched();
        this.form.get('lastName').markAsTouched();
        this.form.markAllAsTouched();
    }
}
