import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServicesState } from '@app/services/state/services.state';
import { Move } from '@app/move/interfaces/move';

export const featureName = 'service';

export const selectFeature = (state: any) => state[featureName] as ServicesState;

export const selectOrderInfo = createSelector(selectFeature, (state) => state.orderInfo);
export const selectSelectedOffer = createSelector(selectFeature, (state) => state.selectedOffer);
export const selectFulfillmentInfo = createSelector(selectFeature, (state) => state.fulfillment);
export const selectOrderedBoilerMaintenanceContract = createSelector(
    selectFeature,
    (state) => state.orderedContracts?.boilerMaintenanceContract
);

// this will just fetch info from the move state right now
const transactionFeature = createFeatureSelector<Partial<Move>>('move');
export const selectTransactionInfo = createSelector(transactionFeature, (state) => state);
