import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelContainerModule } from '@ui/form/label-container';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from '@ui/icon';
import { SvgModule } from '@ui/svg';
import { ErrorStateModule } from '@ui/form/error-state';
import { MatInputModule } from '@angular/material/input';
import { BadgeModule } from '@ui/badge';
import { SelectInputComponent } from '@ui/form/select-input/select-input.component';

@NgModule({
    declarations: [SelectInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LabelContainerModule,
        FormsModule,
        TranslateModule,
        MatRadioModule,
        BadgeModule,
        IconModule,
        SvgModule,
        ErrorStateModule,
        MatInputModule,
    ],
    exports: [SelectInputComponent],
})
export class SelectInputModule {}
