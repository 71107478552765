import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LineupComponent } from '@app/services-components/lineup/components/lineup/lineup.component';
import { TranslateModule } from '@ngx-translate/core';
import { LineupOfferComponent } from '@app/services-components/lineup/components/lineup-offer/lineup-offer.component';
import { ButtonModule } from '@ui/button';
import { LineupOfferButtonComponent } from './components/lineup-offer-button/lineup-offer-button.component';
import { ServiceOfferIsSelectedPipe } from './pipes/service-offer-is-selected.pipe';
import { RouterLink } from '@angular/router';
import { PipeModule } from '@ui/pipes';

@NgModule({
    declarations: [LineupComponent, LineupOfferComponent, LineupOfferButtonComponent, ServiceOfferIsSelectedPipe],
    imports: [CommonModule, TranslateModule, NgOptimizedImage, ButtonModule, RouterLink, PipeModule],
    exports: [LineupComponent, LineupOfferComponent, LineupOfferButtonComponent, ServiceOfferIsSelectedPipe],
})
export class LineupModule {}
