import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { findKey } from 'lodash';
import { Subject } from 'rxjs';
import { MyErrorStateMatcher } from './error-state/error-state-matcher';
import { ErrorState } from './error-state/error-state.interface';
import { baseInputWidth } from './form.constants';
import { FormUtils } from './form.utils';

@Directive()
export class BaseInput implements OnInit, AfterViewInit, OnDestroy {
    public errorStateMatcher;
    public required: boolean;
    public innerDisabled: boolean;
    public autoFocus = false;

    @ViewChild('input', { static: true })
    public input: ElementRef;
    @Input() public id: string;
    @Input() public formControlName: string;
    @Input() public formControl: FormControl;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public errorMessages: { [key: string]: string };
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public showRequiredAsterisk: boolean; // for now make it configurable
    @Input()
    @HostBinding('style.width')
    public width = baseInputWidth;
    @Input()
    @HostBinding('style.maxWidth')
    public maxWidth = '100%';
    @Input() public readonly: boolean;
    protected destroy$ = new Subject<boolean>();

    constructor(public controlContainer: ControlContainer | FormGroupDirective) {}

    public ngOnInit(): void {
        this.errorStateMatcher = this.formControlName && new MyErrorStateMatcher(this.formControlName);
        const control: AbstractControl = this.getAbstractControl();
        if (!this.formControl && control) this.formControl = control as FormControl;
        if (control?.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                this.required = true;
            }
        }
    }

    public ngAfterViewInit(): void {
        if (this.autoFocus) {
            setTimeout(() => {
                this.input?.nativeElement?.focus();
            }, 500);
        }
    }

    public getAbstractControl(): AbstractControl {
        if (this.formControl) return this.formControl;
        const control = this.controlContainer?.control;
        if (!control) {
            return null;
        }

        // if formArray, get control by index
        if (control['controls']?.length) {
            return control['controls'][this.formControlName];
        }
        return control?.get(this.formControlName);
    }

    /**
     * only show error when form is submitted or field touched
     */
    public isErrorState(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && (this.controlContainer['submitted'] || this.getAbstractControl().touched);
    }

    public getFirstErrorState(): ErrorState {
        return FormUtils.getFirstError(this.getAbstractControl());
    }

    /**
     * @deprecated: use getFirstErrorState in combination with the ErrorStatePipe
     * Returns translation key
     */
    public getFirstError(): string {
        if (!this.getAbstractControl()) {
            return null;
        }
        const firstError = findKey(this.getAbstractControl().errors, (error) => !!error);
        if (!firstError) {
            return null;
        }
        return firstError;
    }

    public onBlur(event): void {
        this.propagateTouched();
    }

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.propagateTouched = fn;
    }

    public onTouched() {}

    public propagateChange = (_: any) => {};

    public propagateTouched = () => {};

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
