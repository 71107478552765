import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MoveData } from '@app/move/classes/move-data.class';
import { Service } from '@app/move/enums/service.enum';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveActionTypes } from '@app/move/state/move.actions';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MoveTodo } from '@app/wizard/move/interfaces/move-todo';
import { environment } from '@environments/environment';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { DbUtils, FeatureScope } from '@smooved/core';
import { TriPanelModalComponent, UriSchemaType } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { availableServices, defaultActive, MoveDetailTab } from './move-detail.constants';

@Component({
    selector: 'app-move-detail-modal',
    templateUrl: './move-detail.modal.html',
    styleUrls: ['./move-detail.modal.scss'],
})
export class MoveDetailModalComponent extends MoveData implements OnInit {
    @ViewChild(TriPanelModalComponent, { static: false }) private triPanelModal: TriPanelModalComponent;

    public readonly availableServices = availableServices;
    public readonly tabs = MoveDetailTab;
    public readonly mailTo = UriSchemaType.MailTo;
    public readonly featureScope = FeatureScope;
    public readonly environment = environment;
    public activeTab = defaultActive;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly router: Router,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private readonly actions$: ActionsSubject
    ) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit(): void {
        this.reload();
        this.actions$.pipe(ofType(MoveActionTypes.PatchPropertySuccess), takeUntil(this.destroy$)).subscribe(() => {
            // make sure we always have the latest data in the move detail modal. This is an interim solution. We should use the ngrx store instead of the data class and all the @Input/@Output listeners.
            this.reload();
        });
        if (this.data.options?.activeTab) {
            this.changeTab(MoveDetailTab[this.data.options?.activeTab]);
        }
    }

    public changeTab(tab: MoveDetailTab): void {
        this.activeTab = tab;
    }

    public showLeaver(leaverId: string): void {
        this.triPanelModal.close({ openMove: leaverId });
    }

    public addLeaver(): void {
        void this.router.navigate([Navigation.OTS, Navigation.LeaverDetails]);
    }

    public hasServiceTodo(todoList: MoveTodo[], serviceTodos: TodoType[]): boolean {
        return todoList?.some((moveTodo): boolean => serviceTodos.includes(moveTodo.todo));
    }

    public reload(): void {
        this.fetch(this.data.id, undefined, undefined, true);
        this.setActiveTab(this.data.options?.activeTab as MoveDetailTab);
    }

    public trackByService(
        _,
        item: {
            service: Service;
            label: string;
            isVisible: () => boolean;
            todoList: TodoType[];
        }
    ): Service {
        return item.service;
    }

    private setActiveTab(tab: MoveDetailTab): void {
        if (Object.values(this.tabs).includes(tab)) this.activeTab = tab;
    }

    protected readonly DbUtils = DbUtils;
}
