import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-water-info-detail',
    templateUrl: 'water-info-detail.component.html',
    styleUrls: ['./water-info-detail.component.scss'],
})
export class WaterInfoDetailComponent {
    @Input() public move: Move;

    public readonly environment = environment;
}
