export enum MeterStateType {
    Energy = 'energy',
    Water = 'water',
}

export enum MeterStateStatus {
    Add = 'add',
    Confirm = 'confirm',
    Ok = 'ok',
    Nok = 'nok',
    Disabled = 'disabled',
}

export const meterStateBaseIconUrl = 'https://assets.smooved.be/icons/status-';
