import { SelectInput } from '@ui/form';

export enum MaintenanceSchedule {
    Yearly = 'yearly',
    Biyearly = 'biyearly',
}

export const options: SelectInput<MaintenanceSchedule>[] = [
    {
        name: 'maintenance-schedule',
        value: MaintenanceSchedule.Yearly,
        id: MaintenanceSchedule.Yearly,
        labelResource: 'SERVICES.FULFILLMENT.MAINTENANCE_SCHEDULE.YEARLY',
    },
    {
        name: 'maintenance-schedule',
        value: MaintenanceSchedule.Biyearly,
        id: MaintenanceSchedule.Biyearly,
        labelResource: 'SERVICES.FULFILLMENT.MAINTENANCE_SCHEDULE.BIYEARLY',
    },
];
