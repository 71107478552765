export enum Service {
    energy = 'energy',
    energyStop = 'energyStop',
    telecom = 'telecom',
    insurances = 'insurances',
    water = 'water',
    logistics = 'logistics',
    post = 'post',
    boilerMaintenance = 'boilerMaintenance',
}
