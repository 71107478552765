import { ServiceCardItem } from '@ui/cards';

export enum ServiceType {
    EnergyNewContract = 'Energy New Contract',
    EnergyOptimization = 'Energy Optimization',
    BoilerMaintenance = 'Boiler Maintenance',
    TelecomNewContract = 'Telecom New Contract',
    TelecomExistingContract = 'Telecom Existing Contract',
    SmoovedDeals = 'Smooved Deals',
    Water = 'Water',
    Logistics = 'Logistics',
    Post = 'Post',
}

export enum UpsellServiceType {
    Telecom = 'Telecom',
    BoilerMaintenance = 'BoilerMaintenance',
}

export type ServiceCardConfig = {
    titleLabel: string;
    descriptionLabel: string;
    serviceCardItem: ServiceCardItem;
    upsellServiceType: UpsellServiceType;
    serviceCardId: string;
    imageSrc: string;
    imageWidth: number;
    imageHeight: number;
    buttonText: string;
    routeLink: string;
};
