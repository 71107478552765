import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UiContext } from '@smooved/ui';

@Component({
    selector: 'app-lineup-offer-button',
    template: `
        <button class="u-display-block u-w100p u-padding-none" smvd-ui-button [context]="buttonContext">
            <div class="__container u-padding-left-half u-padding-right-half u-h100p">
                <img [ngSrc]="iconSrc" width="24" height="24" />
                <span>{{ 'SERVICES.OFFER_LINEUP.OFFER.CHOOSE' | translate }}</span>
                <div></div>
            </div>
        </button>
    `,
    styleUrls: ['./lineup-offer-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class LineupOfferButtonComponent implements OnInit {
    @Input() public selected: boolean;

    public iconSrc: string;
    public buttonContext: UiContext;

    public ngOnInit(): void {
        this.iconSrc = `https://assets.smooved.be/icons/${this.selected ? 'check-filled-secondary' : 'check-unfilled-tertiary'}.svg`;
        this.buttonContext = this.selected ? UiContext.Secondary : UiContext.Tertiary;
    }
}
