import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoilerMaintenanceRoutingModule } from '@app/services/boiler-maintenance/boiler-maintenance-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@ui/button';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { LineupModule } from '@app/services-components/lineup/lineup.module';
import { LineupContainer } from '@app/services/boiler-maintenance/containers/lineup/lineup.container';
import { BoilerMaintenanceService } from '@app/services/boiler-maintenance/services/boiler-maintenance.service';
import { SelectInputModule } from '@ui/form/select-input/select-input.module';
import { serviceReducer } from '@app/services/state/services.reducer';
import { featureName } from '@app/services/state/services.selectors';
import { PreviousStepComponent } from '@app/services-components/lineup/components/previous-step/previous-step.component';

@NgModule({
    declarations: [LineupContainer],
    providers: [BoilerMaintenanceService],
    imports: [
        CommonModule,
        BoilerMaintenanceRoutingModule,
        TranslateModule,
        ButtonModule,
        ReactiveFormsModule,
        LineupModule,
        StoreModule.forFeature(featureName, serviceReducer),
        SelectInputModule,
        PreviousStepComponent,
    ],
})
export class BoilerMaintenanceModule {}
