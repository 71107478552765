import { Component, OnInit } from '@angular/core';
import { BoilerMaintenanceService } from '@app/services/boiler-maintenance/services/boiler-maintenance.service';
import { BoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { DbUtils } from '@core/utils';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { TodoState } from '@smooved/ui';
import { WaterMeterState } from '@core/enums';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-move-detail-boiler-maintenance-section',
    templateUrl: 'move-detail-boiler-maintenance-section.html',
    styleUrls: ['move-detail-boiler-maintenance-section.scss'],
})
export class MoveDetailBoilerMaintenanceSection implements OnInit {
    public loading = false;
    public boilerMaintenanceContract: BoilerMaintenanceContract;

    protected readonly DbUtils = DbUtils;
    protected readonly i18nKeys = appI18nKeyTypes;
    protected readonly TodoState = TodoState;
    protected readonly waterMeterState = WaterMeterState;

    constructor(
        private readonly boilerMaintenanceService: BoilerMaintenanceService,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.idOnce$.subscribe((transactionId) => {
            this.loading = true;
            this.boilerMaintenanceService.getContract(transactionId).subscribe((boilerMaintenanceContract) => {
                this.boilerMaintenanceContract = boilerMaintenanceContract;
                this.loading = false;
            });
        });
    }
}
