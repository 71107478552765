import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule, UiContext, UiSandbox } from '@smooved/ui';
import { routingDataNextStep, routingDataPreviousStep, routingDataServiceType } from '@app/services/constants/services.constants';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PreviousStepComponent } from '@app/services-components/lineup/components/previous-step/previous-step.component';
import { ServiceType } from '@app/services/types/service-type';

@Component({
    selector: 'app-base-step-container',
    template: `
        <ng-container *ngIf="{ isMobile: uiSandbox.isMobile } as vm">
            <div
                [ngClass]="{ 'u-padding-top-lg u-container u-margin-0-auto u-padding-x-axis': !vm.isMobile, 'u-padding': vm.isMobile }"
                class="u-margin-O-auto"
            >
                <app-previous-step *ngIf="previousStep" [route]="previousStep"></app-previous-step>
                <ng-content></ng-content>
                <div class="u-flex-row u-flex-justify-content-end" *ngIf="nextStep">
                    <button smvd-ui-button type="submit" [context]="uiContext.Secondary" (click)="next.emit()">
                        {{ 'UI.NEXT' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    `,
    standalone: true,
    imports: [CommonModule, TranslateModule, ButtonModule, PreviousStepComponent],
})
export class BaseStepContainer {
    @Output() public next: EventEmitter<void> = new EventEmitter();

    public readonly uiContext = UiContext;
    public readonly previousStep: string = this.route.snapshot.data[routingDataPreviousStep];
    public readonly nextStep: string = this.route.snapshot.data[routingDataNextStep];
    public readonly serviceType: ServiceType = this.route.snapshot.data[routingDataServiceType];

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        public readonly uiSandbox: UiSandbox
    ) {}

    protected onNext(): void {
        if (!this.nextStep) return;
        void this.router.navigateByUrl(this.nextStep);
    }
}
