import { EnergyMetersState, EnumUtils, WaterMeterState } from '@smooved/core';
import { meterStateBaseIconUrl, MeterStateStatus, MeterStateType } from '@ui/transaction/constants/meter-state.constants';
import { MeterStateContext } from '@core/enums/meters-state.enum';

export class TransactionUtils {
    public static getMeterStateStatusEnergy(state: EnergyMetersState): MeterStateStatus {
        switch (state) {
            case EnergyMetersState.Incomplete:
                return MeterStateStatus.Add;
            case EnergyMetersState.Completed:
                return MeterStateStatus.Confirm;
            case EnergyMetersState.Confirmed:
            case EnergyMetersState.Processed:
                return MeterStateStatus.Ok;
            default:
                return null;
        }
    }

    public static getMeterStateStatusWater(state: WaterMeterState): MeterStateStatus {
        switch (state) {
            case WaterMeterState.NoTransfer:
                return MeterStateStatus.Disabled;
            case WaterMeterState.Incomplete:
                return MeterStateStatus.Add;
            case WaterMeterState.Completed:
                return MeterStateStatus.Confirm;
            case WaterMeterState.Confirmed:
            case WaterMeterState.Processed:
                return MeterStateStatus.Ok;
            default:
                return null;
        }
    }

    public static getMeterStateIcon(type: MeterStateType, status: MeterStateStatus, context = MeterStateContext.Default): string {
        if (!EnumUtils.values(MeterStateType).includes(type)) return null;
        const baseUrl = `${meterStateBaseIconUrl}${type}`;
        switch (status) {
            case MeterStateStatus.Add:
                return context === MeterStateContext.Error ? `${baseUrl}-add-error.svg` : `${baseUrl}-add.svg`;
            case MeterStateStatus.Confirm:
                return context === MeterStateContext.Error ? `${baseUrl}-confirm-error.svg` : `${baseUrl}-confirm.svg`;
            case MeterStateStatus.Ok:
                return `${baseUrl}-ok.svg`;
            case MeterStateStatus.Nok:
                return `${baseUrl}-nok.svg`;
            case MeterStateStatus.Disabled:
                return `${baseUrl}-disabled.svg`;
            default:
                return null;
        }
    }
}
