import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServiceCardItem } from './service-card.constants';

@Component({
    selector: 'smvd-ui-service-card',
    templateUrl: 'service-card.component.html',
    styleUrls: ['service-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCardComponent {
    @Input() public title: string;
    @Input() public description: string;
    @Input() public service: ServiceCardItem;
}
