import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
    leaverServices,
    orderEnergySupplierStatusTableDef,
    transfereeServices,
} from '@app/admin/modals/move-detail/move-detail/move-detail.constants';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { CommunicationSandbox } from '@app/communication/sandboxes/communication.sandbox';
import { TemplateTag } from '@app/document-center/enums/template-tag';
import { EanInfoModal } from '@app/energy/modals/ean-info/ean-info.modal';
import { EnergyService } from '@app/energy/services/energy.service';
import { EscalationModalService } from '@app/escalation/services/escalation-modal.service';
import { MoveState } from '@app/move/enums/move-state.enum';
import { Service } from '@app/move/enums/service.enum';
import { Move } from '@app/move/interfaces/move';
import { DetailModal } from '@app/move/modals/detail/detail.modal';
import { MoveModalSandbox } from '@app/move/sandboxes/move-modal.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { OrderService } from '@app/order/services/order.service';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Services } from '@app/wizard/move/interfaces/services';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, FeatureToggle, ObjectUtils, RxjsComponent } from '@smooved/core';
import {
    ButtonAppearance,
    ButtonSize,
    mediumModalMaxWidth,
    ModalSandbox,
    NotificationSandbox,
    UiContext,
    UiIconSize,
    updateConfirmation,
} from '@smooved/ui';
import { isBoolean } from 'lodash';
import { Observable } from 'rxjs';
import { concatMap, delay, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { DeleteMoveModal } from '../../delete-move/delete-move.modal';
import { MoveDetailModalService } from './move-detail.service';

@Component({
    selector: 'app-move-detail',
    templateUrl: './move-detail.modal.html',
    styleUrls: ['./move-detail.modal.scss'],
    providers: [MoveDetailModalService],
})
export class MoveDetailModalComponent extends RxjsComponent implements OnInit {
    public get move(): Move {
        return this.innerMove;
    }

    public set move(value: Move) {
        this.innerMove = value;
        this.moveSandbox.setMoveState(value);
    }

    @ViewChild(DetailModal, { static: true }) public detail: DetailModal;

    public services$: Observable<Service[]>;
    public showPhoneTransfer$: Observable<boolean>;
    public showTelecomInstallation$: Observable<boolean>;
    public showContactAddress$: Observable<boolean>;

    public readonly moveState = MoveState;
    public readonly uiContext = UiContext;
    public readonly buttonSize = ButtonSize;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public readonly moverRole = MoverRole;
    public readonly appI18nKeyTypes = appI18nKeyTypes;
    public readonly orderEnergySupplierStatusTableDef = orderEnergySupplierStatusTableDef;
    public readonly templateTag = TemplateTag;
    public readonly DbUtils = DbUtils;
    public readonly featureToggle = FeatureToggle;

    private innerMove: Move;

    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly orderService: OrderService,
        private readonly orderSandbox: OrderSandbox,
        private readonly moveDetailModalService: MoveDetailModalService,
        public readonly authenticationSandbox: AuthenticationSandbox,
        private readonly energyService: EnergyService,
        private readonly uiSandbox: AppUiSandbox,
        private readonly communicationSandbox: CommunicationSandbox,
        private readonly dialogRef: MatDialogRef<any>,
        private readonly escalationModalService: EscalationModalService,
        private readonly moveModalSandbox: MoveModalSandbox
    ) {
        super();
    }

    public createEscalation(): void {
        this.escalationModalService.create();
    }

    public createContactLog(): void {
        this.moveModalSandbox.createContactLog(this.move);
    }

    public ngOnInit(): void {
        this.detail.move$.pipe(takeUntil(this.destroy$)).subscribe((move) => {
            this.move = move;
        });

        this.services$ = this.detail.move$.pipe(
            map((move) =>
                (MoveUtils.isLeaver(move) ? leaverServices : transfereeServices).filter((service) => {
                    return !move?.interested || !move?.interested[service];
                })
            ),
            delay(0)
        );

        this.showPhoneTransfer$ = this.detail.move$.pipe(
            map((move) => MoveUtils.hasOnlyMobile(move) || MoveUtils.hasOnlyFixedLine(move) || MoveUtils.hasMobileAndFixedLine(move))
        );

        this.showTelecomInstallation$ = this.detail.move$.pipe(map((move) => MoveUtils.hasTelecomInstallation(move)));

        this.showContactAddress$ = this.detail.move$.pipe(
            map((move) => {
                return !!move && !!Object.keys(ObjectUtils.difference(move?.user?.movingAddress, move?.user?.contactAddress) || {})?.length;
            }),
            delay(0)
        );

        this.dialogRef.beforeClosed().subscribe((result) => {
            if (!result?.navigatedAway) {
                this.moveSandbox.clearMoveState();
            }
            this.communicationSandbox.clear();
        });
    }

    public activateMove(move: Move): void {
        this.moveDetailModalService.activateMove(move).subscribe((updatedMove) => {
            if (!updatedMove) return;
            this.detail.updateMove(updatedMove, true);
        });
    }

    public showDeleteModal(move: Move): void {
        this.modalSandbox.openModal(null, null, null, DeleteMoveModal, { data: move }, (deleted) => {
            if (!deleted) return;
            this.detail.data.patchedSubject.next(true);
            this.detail.closableModalComponent.close();
        });
    }

    public setInterest(service: Service): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            if (!move) return;
            if (isBoolean(move.realEstateGroup?.services[service]) && !move.realEstateGroup.services[service]) {
                this.modalSandbox.openConfirmModal(
                    {
                        data: this.translateService.instant(`ADMIN.REAL_ESTATE_GROUPS.SERVICES.CONFIRMATION`, {
                            service: this.translateService.instant(`ADMIN.REAL_ESTATE_GROUPS.SERVICES.${service.toUpperCase()}.LABEL`),
                        }),
                    },
                    (confirm) => {
                        if (confirm) {
                            this.patchMove(move, service);
                        }
                    },
                    {
                        data: this.translateService.instant(`ADMIN.REAL_ESTATE_GROUPS.SERVICES.CONFIRMATION`, {
                            service: this.translateService.instant(`ADMIN.REAL_ESTATE_GROUPS.SERVICES.${service.toUpperCase()}.LABEL`),
                        }),
                    },
                    (confirm) => {
                        if (confirm) {
                            this.patchMove(move, service);
                        }
                    }
                );
            } else {
                this.patchMove(move, service);
            }
        });
    }

    public toggleWaterTransfer(value: boolean): void {
        this.moveSandbox.patchProperty('waterCollectiveMeter', !value, true, (updatedMove) => {
            if (!updatedMove) return;
            this.moveSandbox.setLatestMoveState(updatedMove);
            this.detail.updateMove(updatedMove, true);
        });
    }

    public onNotInterested(service: keyof Services): void {
        const data = this.translateService.instant(updateConfirmation) as string;
        this.modalSandbox.openConfirmModal({ data }, this.updateNotInterested(service));
    }

    private patchMove(move: Move, service: Service): void {
        this.moveSandbox.patchMove(
            move?._id,
            {
                interested: { [service]: true },
            },
            false,
            null,
            this.updateMoveAfterPatch
        );
    }

    private updateNotInterested = (service: string): ((result: boolean) => void) => {
        return (result: boolean): void => {
            if (result) {
                this.moveSandbox.moveOnce$.subscribe((move) => {
                    this.moveSandbox.patchMove(
                        DbUtils.getStringId(move),
                        { interested: { [service]: false } },
                        false,
                        null,
                        this.updateMoveAfterPatch
                    );
                });
            }
        };
    };

    public showLinkedMove(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.detail.data.changeMoveSubject.next((move?.linkedMove as Move)?._id);
            this.detail.closableModalComponent.close();
        });
    }

    public updatedAppointment(orderId: string, completed: boolean): void {
        this.orderSandbox.patchMortgage(orderId, { appointmentCompleted: { value: completed } }).subscribe(() => {
            this.detail.fetch(DbUtils.getStringId(this.move), null, null, true);
        });
    }

    public getEnergyOrderStatus(): void {
        this.uiSandbox.moveLoading(true);
        this.moveSandbox.idOnce$
            .pipe(
                switchMap((id) => this.orderService.getOrderEnergySupplierStatus(id)),
                finalize(() => this.uiSandbox.moveLoading(false))
            )
            .subscribe((result) => {
                this.modalSandbox.showTableModal(this.orderEnergySupplierStatusTableDef, result);
            });
    }

    public getEanByAddress(): void {
        this.uiSandbox.moveLoading(true);
        this.moveSandbox.moveOnce$
            .pipe(
                concatMap((move) =>
                    this.energyService.getEanByAddress({
                        language: move.user?.language,
                        address: move.user?.movingAddress,
                    })
                ),
                finalize(() => this.uiSandbox.moveLoading(false))
            )
            .subscribe((results) => {
                const config: MatDialogConfig = {
                    data: results,
                    maxWidth: mediumModalMaxWidth,
                };
                this.modalSandbox.openModal(
                    EanInfoModal,
                    config,
                    this.updateMoveAfterPatch,
                    EanInfoModal,
                    config,
                    this.updateMoveAfterPatch
                );
            });
    }

    public getEanByMeterNumbers(): void {
        this.uiSandbox.moveLoading(true);
        this.moveSandbox.moveOnce$
            .pipe(
                concatMap((move) => {
                    return this.energyService.getEanByMeterNumbers({
                        address: move.user?.movingAddress,
                    });
                }),
                finalize(() => this.uiSandbox.moveLoading(false))
            )
            .subscribe(
                (data) => {},
                (error) => {
                    this.notificationSandbox.error(error);
                }
            );
    }

    public updateMoveAfterPatch = (move) => {
        if (move) {
            this.detail.updateMove(move, true);
        }
    };
    protected readonly ObjectUtils = ObjectUtils;
    protected readonly MoveUtils = MoveUtils;
}
