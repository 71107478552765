import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BoilerMaintenanceOffer } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { TranslateService } from '@ngx-translate/core';
import { UiContext, UiSandbox } from '@smooved/ui';

@Component({
    selector: 'app-lineup-offer',
    template: ` <ng-container *ngIf="offer">
        <div class="u-w100p" *ngIf="{ isMobile: uiSandbox.isMobile } as vm">
            <header data-testid="lineup-offer-mobile-header" class="__offer-header" *ngIf="vm.isMobile; else headerDesktop">
                <img [src]="offer.cover" class="__offer-cover" />
                <div
                    class="__offer-header-container u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-padding-x-axis"
                >
                    <p class="__offer-title">{{ offer.offerName }} {{ offer.supplierName }}</p>
                    <img *ngIf="offer.supplierLogo" class="__supplier-logo" [src]="offer.supplierLogo" />
                </div>
            </header>
            <ng-template #headerDesktop>
                <header data-testid="lineup-offer-desktop-header" class="__offer-header">
                    <img [src]="offer.cover" class="__offer-cover" />
                    <p class="__offer-title u-font-size-sm-md">{{ offer.offerName }} {{ offer.supplierName }}</p>
                    <div *ngIf="offer.supplierLogo" class="__supplier-logo-cover u-padding-x-axis-half">
                        <img class="__supplier-logo" [src]="offer.supplierLogo" />
                    </div>
                </header>
            </ng-template>
            <main class="__offer-main u-padding u-flex-column u-flex-justify-content-space-between">
                <div>
                    <div class="u-margin-bottom">
                        <div *ngIf="offer.priceWithDiscount; else withoutDiscount" class="u-flex-row u-flex-align-items-center">
                            <span
                                data-testid="lineup-offer-price-with-discount"
                                class="u-color-content-brand-vibrant u-font-size-lg u-font-weight-bold"
                            >
                                {{ offer.priceWithDiscount | price }}
                            </span>
                            <div class="u-flex-column u-font-size-sm">
                                <span data-testid="lineup-offer-price" class="u-color-purple-500 u-text-line-through">{{
                                    offer.price | price
                                }}</span>
                                <span data-testid="lineup-offer-price-interval" class="u-color-content-secondary-default"
                                    >/{{ offer.priceInterval[translateService.currentLang] }}</span
                                >
                            </div>
                        </div>
                        <ng-template #withoutDiscount>
                            <span
                                data-testid="lineup-offer-price"
                                class="u-font-size-lg u-font-weight-bold u-color-content-primary-default"
                                >{{ offer.price | price }}</span
                            ><span data-testid="lineup-offer-price-interval" class="u-font-size-sm u-color-content-secondary-default"
                                >/{{ offer.priceInterval[translateService.currentLang] }}</span
                            >
                        </ng-template>
                    </div>
                    <div
                        *ngFor="let offerUsp of offer.offerUsps; let last = last"
                        class="u-flex-row u-flex-align-items-center"
                        [class.u-margin-bottom-half]="!last"
                    >
                        <img [ngSrc]="offerUsp.icon" width="18" height="18" class="u-margin-right-sm-md" />
                        <span class="u-color-content-secondary-default u-font-size-14 u-line-height-20p">{{ offerUsp.label[translateService.currentLang] }}</span>
                    </div>
                </div>
                <app-lineup-offer-button
                    *ngIf="selectable"
                    [class.u-margin-top-md]="vm.isMobile"
                    [class.u-margin-top-double]="!vm.isMobile"
                    [selected]="selected"
                    (click)="selectOffer.emit(offer)"
                ></app-lineup-offer-button>
                <ng-content></ng-content>
            </main>
        </div>
    </ng-container>`,
    styleUrls: ['./lineup-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineupOfferComponent {
    @Input() public offer: BoilerMaintenanceOffer;
    @Input() @HostBinding('class.is-selected') public selected: boolean;
    @Input() public selectable = true;

    @Output() public selectOffer: EventEmitter<BoilerMaintenanceOffer> = new EventEmitter();

    public readonly uiContext = UiContext;

    constructor(
        public readonly translateService: TranslateService,
        public readonly uiSandbox: UiSandbox
    ) {}
}
