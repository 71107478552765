<smvd-ui-card>
    <div class="u-flex-row u-flex-justify-content-space-between">
        <div>
            <h5>
                {{ $any(escalation.category)?.labels | translateLabel }}
                <app-icon
                    appTooltip
                    icon="info"
                    [template]="tooltipContent"
                    [hidePointer]="true"
                    [placement]="uiPlacement.RightEnd"
                    class="u-margin-left-half"
                ></app-icon>
            </h5>
            <p class="u-color-muted u-font-size-small">
                <ng-container *ngIf="(escalation.state | auditValue) !== escalationState.Closed; else closed">
                    {{ 'COMMON.CREATED_ON' | translate: {createdOn: escalation.createdOn | formatDate} }}
                    <ng-container *ngIf="escalation.createdBy | name as name">{{
                            'COMMON.BY_USER' | translate: {user: name}
                        }}
                    </ng-container>
                </ng-container>
                <ng-template #closed>
                    {{
                        'CLOSED_ON'
                            | i18nKeyTranslate
                            : i18nKeys.Escalation
                                : {
                                createdOn: (escalation.state.lastModifiedOn || escalation.state.createdOn) | formatDate
                            }
                    }}
                    <ng-container *ngIf="(escalation.state.lastModifiedBy || escalation.state.createdBy) | name as name"
                    >{{ 'COMMON.BY_USER' | translate: {user: name} }}
                    </ng-container>
                </ng-template>
            </p>
        </div>
        <div>
            <button smvd-ui-button [appearance]="buttonAppearance.Stroked" [context]="uiContext.Secondary"
                    (click)="openEditModal()">
                {{ 'COMMON.EDIT' | translate }}
            </button>
            <button
                class="u-padding-none"
                smvd-ui-button
                [appearance]="buttonAppearance.Link"
                [context]="uiContext.Secondary"
                (click)="toggleDetails()"
            >
                {{ (collapsed ? 'COMMON.SHOW_MORE' : 'COMMON.SHOW_LESS') | translate }}
            </button>
        </div>
    </div>
    <ng-container *ngIf="!collapsed">
        <div class="u-flex-row u-flex-justify-content-flex-start u-margin-top">
            <app-svg-illustration [svg]="tagSvgMap.get(escalation.tag)"></app-svg-illustration>
            <p class="u-margin-left">
                {{ 'STATE.' + (escalation.state | auditValue) | uppercase | i18nKeyTranslate: i18nKeys.Escalation }}
            </p>
        </div>
        <p class="u-margin-top u-white-space-break-spaces">{{ escalation.description }}</p>

        <h6 *collapsible="moreInfo"
            class="u-color-muted u-margin-bottom-half u-margin-top">{{ 'COMMON.MORE_INFO' | translate }}</h6>
        <ng-container *ngIf="logActions?.length">
            <h6 *collapsible="actions" class="u-color-muted u-margin-bottom-half u-margin-top">
                {{ 'ACTIONS' | i18nKeyTranslate: i18nKeys.Escalation }}
            </h6>
        </ng-container>

        <div class="u-margin-top u-flex-row">
            <button
                *ngIf="$any(escalation.category)?.relevantActions | includes: contactLogType.EmailCustomMover"
                app-button
                [context]="uiContext.Secondary"
                class="u-margin-right-half"
                (click)="openCustomEmail()"
                class="u-margin-right-half"
            >
                {{ 'SEND_EMAIL' | i18nKeyTranslate: i18nKeys.Escalation }}
            </button>
            <button
                *ngIf="$any(escalation.category)?.relevantActions | includes: contactLogType.Call:contactLogType.Voicemail"
                app-button
                [context]="uiContext.Secondary"
                (click)="openLogAction()"
            >
                {{ 'LOG_ACTION' | i18nKeyTranslate: i18nKeys.Escalation }}
            </button>
        </div>
    </ng-container>
</smvd-ui-card>

<ng-template #moreInfo>
    <div class="__more-info-grid u-grid u-grid-col-2-repeat">
        <div class="__service">
            <label class="u-font-size-small u-color-muted">{{ 'SERVICES.LABEL_SINGLE' | translate }}</label>
            <p>
                {{ escalation.service | i18nKeyTranslate: i18nKeys.Service }}
                <ng-container *ngIf="escalation.supplierGroup"> - {{ escalation.supplierGroup.name }}</ng-container>
            </p>
        </div>
        <div class="__ops">
            <label class="u-font-size-small u-color-muted">{{ 'OPS_ADMIN' | translate }}</label>
            <p>{{ escalation.servicedBy | name: '-' }}</p>
        </div>
        <div class="__account-manager">
            <label class="u-font-size-small u-color-muted">{{ 'ACCOUNT_MANAGER' | translate }}</label>
            <p>{{ escalation.accountManager | name: '-' }}</p>
        </div>
        <div class="__external-urls">
            <label
                class="u-font-size-small u-color-muted">{{ 'EXTERNAL_URLS.LABEL' | i18nKeyTranslate: i18nKeys.Escalation }}</label>
            <a
                smvd-ui-button
                [appearance]="buttonAppearance.Link"
                [context]="uiContext.Secondary"
                *ngFor="let url of escalation.externalUrls"
                [href]="url"
                target="_blank"
                class="u-display-block u-padding-none"
            >{{ url }}</a
            >
            <p *ngIf="!escalation.externalUrls?.length">-</p>
        </div>
    </div>
</ng-template>

<ng-template #actions>
    <div>
        <div class="u-padding-y-axis __action-item" *ngFor="let logAction of logActions">
            <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center">
                <label class="u-font-size-small u-color-muted u-display-inline-flex u-flex-align-items-center"
                >{{ (logAction.lastModifiedOn || logAction.createdOn) | formatDate: dateTimeFormatDefault }}
                    <strong class="u-margin-left-half">
                        <ng-container
                            *ngIf="logAction.value">{{ logAction.value | i18nKeyTranslate: i18nKeys.ContactLog }}
                        </ng-container>
                        <ng-container *ngIf="logAction.type">{{
                                'ACTION.TYPE.' + logAction.type | i18nKeyTranslate: i18nKeys.Escalation
                            }}
                        </ng-container>
                    </strong>
                </label>
                <label
                    class="u-font-size-small u-color-muted">{{ (logAction.lastModifiedBy || logAction.createdBy) | name }}</label>
            </div>
            <div class="u-display-inline-flex u-flex-align-items-center" *ngIf="logAction.subject || logAction.content">
                {{ logAction.subject || (logAction.content | limit: 0:maxContentChars) }}
                <button
                    class="u-margin-left-none"
                    *ngIf="logAction.content"
                    app-button
                    [appearance]="buttonAppearance.Icon"
                    [context]="uiContext.Secondary"
                    icon="info"
                    (click)="showContactLogInfo(logAction)"
                ></button>
            </div>
            <smvd-app-mention-input
                width="100%"
                class="u-margin-top-half"
                *ngIf="logAction.remark"
                [ngModel]="logAction.remark"
                [disabled]="true"
                [hasMargin]="false"
            ></smvd-app-mention-input>
        </div>
    </div>
</ng-template>

<ng-template #tooltipContent>
    <ol class="__instructions u-font-size-small u-margin-half u-text-align-left">
        <li *ngFor="let instruction of $any(escalation.category)?.instructions" class="u-margin-bottom-half">
            {{ instruction | translateLabel }}
        </li>
    </ol>
</ng-template>
