import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { CheckInput, MobileModalComponent } from '@smooved/ui';

@Component({
    selector: 'app-mobile-move-water-modal',
    template: `
        <app-mobile-modal
            *ngIf="{ isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async), move: (move$ | async) } as vm"
        >
            <div mobile-modal-header>
                <h6>{{ 'WATER.LABEL' | translate }}</h6>
                <span class="u-color-muted u-font-size-small">{{ vm.move?.user?.formattedMovingAddressWithoutCountry }}</span>
            </div>
            <div mobile-modal-main>
                <form [formGroup]="form" (ngSubmit)="submit()" class="u-flex-column u-flex-justify-content-space-between u-h100p">
                    <div class="u-background-white">
                        <div class="u-padding">
                            <app-check-input
                                [custom]="false"
                                [option]="waterCollectiveMeterOption"
                                formControlName="waterCollectiveMeter"
                            ></app-check-input>

                            <div *ngIf="data.move?.rentalInspectionAsset" class="u-margin-top">
                                <h6 class="u-margin-bottom-half u-color-muted">{{ 'OTHER' | translate }}</h6>
                                <app-rental-inspection-asset class="u-display-block" [move]="data.move"></app-rental-inspection-asset>
                            </div>
                        </div>
                    </div>

                    <div class="u-border-radius-top-extra-large u-flex-no-shrink u-background-white u-padding">
                        <app-button type="submit" *ngIf="!data.readOnly || vm.isRealEstateAgentOrAdmin" class="u-display-block u-w100p">{{
                            'SAVE' | translate
                        }}</app-button>
                    </div>
                </form>
            </div>
        </app-mobile-modal>
    `,
    styleUrls: ['./mobile-move-water.component.scss'],
})
export class MobileMoveWaterComponent extends MoveData implements OnInit {
    @ViewChild(MobileModalComponent) public detail: MobileModalComponent;

    public form: UntypedFormGroup;

    public waterCollectiveMeterOption: CheckInput<boolean> = {
        id: 'water-collective-meter',
        name: 'water-collective-meter',
        value: true,
        label: this.translateService.instant('WATER.DOCUMENT.MODAL.COLLECTIVE_METER'),
    };

    constructor(
        protected moveSandbox: MoveSandbox,
        private uiSandbox: AppUiSandbox,
        private fb: FormBuilder,
        private translateService: TranslateService,
        public authenticationSandbox: AuthenticationSandbox,
        @Inject(MAT_DIALOG_DATA) public data: ModalDataMove
    ) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit() {
        this.updateMove(this.data.move);
        this.form = this.fb.group({
            waterCollectiveMeter: [{ value: this.data.move?.waterCollectiveMeter, disabled: !!this.data.readOnly }],
        });
    }

    public submit() {
        if (this.form.invalid) return false;

        this.uiSandbox.showLoadingOverlay();
        this.moveOnce$.subscribe((move) => {
            this.moveSandbox.patchProperty(
                'waterCollectiveMeter',
                this.form.get('waterCollectiveMeter').value,
                true,
                (move) => {
                    this.uiSandbox.hideLoadingOverlay();
                    this.updateMove(move, true);
                    this.detail.dialogRef.close(true);
                },
                false,
                move,
                true
            );
        });
    }
}
