import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { PosthogTransferDateSource } from '@app/tracking/enums/posthog.enum';
import { PosthogService } from '@app/tracking/posthog.service';
import { EnergyMetersState, PostHogEventsEnum, WaterMeterState } from '@core/enums';
import { UrlUtils } from '@core/utils';
import { NotificationSandbox } from '@ui/notification';
import { UiContext } from '@ui/ui.enums';

@Component({
    selector: 'smvd-app-move-details',
    templateUrl: 'move-details.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailsContainer implements OnInit {
    public readonly uiContext = UiContext;
    public processed = false;
    public loading = false;

    public readonly form: FormGroup = this.fb.group({
        movingDate: null,
    });

    public contactUsLink$ = this.moveSandbox.setMailToLink('MOVE.DETAILS.PROCESSED.SUBJECT');

    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly router: Router,
        private readonly fb: FormBuilder,
        private readonly posthogService: PosthogService
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.form.patchValue({
                movingDate: move.movingDate,
            });

            if (
                move.track?.energyMeterReadings?.energyMetersState === EnergyMetersState.Processed ||
                move.track?.waterMeterReadings.waterMetersState === WaterMeterState.Processed
            ) {
                this.processed = true;
                this.form.disable();
            }

            const utmMedium = UrlUtils.getQueryParam('utm_medium');
            this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ViewChangeDateModal, {
                from: utmMedium ?? 'app',
            });
        });
    }

    public submit(): void {
        if (this.form.invalid) return;
        this.loading = true;
        this.moveSandbox.idOnce$.subscribe((moveId) => {
            this.moveSandbox.patch({ moveId, payload: this.form.value, callback: this.onSave });
        });
    }

    private onSave = () => {
        this.loading = false;
        this.notificationSandbox.updatedSuccess();
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.UpdateTransferDate, {
            dateFilledOut: this.form.get('movingDate').dirty,
            from: PosthogTransferDateSource.MoverModal,
        });
        this.router.navigate([Navigation.Dashboard]);
    };
}
