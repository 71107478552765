<app-loading-container [loading]="loading">
    <div [formGroup]="form" class="u-flex-column u-flex-align-items-end u-margin-half">
        <div class="u-grid u-grid-col-2-repeat">
            <smvd-ui-text-input
                [formControlName]="waterDetailFields.NationalRegistrationNumber"
                [placeholder]="placeholders[waterDetailFields.NationalRegistrationNumber]"
                [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate"
                ngDefaultControl
            ></smvd-ui-text-input>
            <smvd-ui-text-input
                [formControlName]="waterDetailFields.ClientNumber"
                [placeholder]="placeholders[waterDetailFields.ClientNumber]"
                [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.CLIENT_NUMBER' | translate"
                ngDefaultControl
            ></smvd-ui-text-input>

            <div>
                <ui-searchable-dropdown-input
                    [formControlName]="waterDetailFields.WaterSupplier"
                    [options]="waterSupplierOptions$ | async"
                    [placeholder]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.PLACEHOLDER' | translate"
                    [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate"
                    ngDefaultControl
                ></ui-searchable-dropdown-input>
                <span class="u-color-gray-dark u-font-size-14">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.NOTE' | translate }}</span>
                <a class="u-font-size-14 u-margin-bottom-half" [href]="lookupToolUrl" target="_blank"
                    >{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LINK' | translate }}</a
                >
            </div>
            <div></div>

            <smvd-ui-text-input
                [formControlName]="waterDetailFields.MeterNumber"
                [placeholder]="placeholders[waterDetailFields.MeterNumber]"
                [label]="('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate)"
                ngDefaultControl
            ></smvd-ui-text-input>
            <smvd-ui-text-input
                [formControlName]="waterDetailFields.MeterReading"
                [placeholder]="placeholders[waterDetailFields.MeterReading]"
                [label]="('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate)"
                ngDefaultControl
            ></smvd-ui-text-input>

            <div class="u-min-w-0">
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.BROKER_ATTACHMENTS' | translate }}
                <div
                    class="u-flex-row u-padding-half u-padding-right-double u-border-radius u-bordered-gray-lightest u-margin-bottom-half u-flex-align-items-center __attachment-row"
                    *ngFor="let waterTransferAsset of move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.Attachment, undefined]"
                >
                    <ng-container
                        [ngTemplateOutlet]="waterTransferAssetTemplate"
                        [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                    ></ng-container>
                </div>

                <button smvd-ui-button [context]="context.Alt" class="u-margin-top" (click)="fileInput.click()">
                    {{ 'Add attachments' | translate }}
                </button>
            </div>
            <div class="u-min-w-0">
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TRANSFER_DOCUMENT' | translate }}
                <div
                    class="u-flex-row u-padding-half u-padding-right-double u-border-radius u-bordered-gray-lightest u-margin-bottom-half u-flex-align-items-center __attachment-row"
                    *ngFor="let waterTransferAsset of move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.TransferDocument]"
                >
                    <ng-container
                        [ngTemplateOutlet]="waterTransferAssetTemplate"
                        [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                    ></ng-container>
                </div>
                <button
                    smvd-ui-button
                    class="u-display-block u-margin-y-axis"
                    [context]="context.Alt"
                    (click)="openProcessWaterAttachmentsModal()"
                >
                    {{ 'Process attachments' | translate }}
                </button>

                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.EXTRA_EMAIL_ATTACHMENTS' | translate }}
                <div
                    class="u-flex-row u-padding-half u-padding-right-double u-border-radius u-bordered-gray-lightest u-margin-bottom-half u-flex-align-items-center __attachment-row"
                    *ngFor="let waterTransferAsset of move.water?.waterTransferAssets | waterTransferAssetFilter: [WaterTransferAssetType.EmailAttachment]"
                >
                    <ng-container
                        [ngTemplateOutlet]="waterTransferAssetTemplate"
                        [ngTemplateOutletContext]="{ waterTransferAsset: waterTransferAsset }"
                    ></ng-container>
                </div>
                <button
                    smvd-ui-button
                    class="u-display-block u-margin-y-axis"
                    [context]="context.Alt"
                    (click)="openProcessWaterAttachmentsModal()"
                >
                    {{ 'Process attachments' | translate }}
                </button>
            </div>
        </div>

        <div class="u-flex-row u-flex-justify-content-flex-end u-margin-top-double">
            <ng-container *ngIf="canConfirmMeters$ | async; else canSave">
                <button
                    smvd-ui-button
                    [disabled]="form.pristine"
                    [appearance]="buttonAppearance.Stroked"
                    [context]="uiContext.PrimaryDark"
                    class="__button-save"
                    type="submit"
                    (click)="saveWaterDetails()"
                >
                    {{ 'SAVE_ONLY' | translate }}
                </button>
                <button
                    id="confirm-button"
                    smvd-ui-button
                    [disabled]="form.pristine && move.water.waterTransferConfirmedByRealEstateAgent"
                    [context]="uiContext.PrimaryDark"
                    class="__button-save u-margin-left-half"
                    (click)="saveAndConfirmWaterDetails()"
                >
                    {{ 'SAVE_AND_CONFIRM' | translate }}
                </button>
            </ng-container>

            <ng-template #canSave>
                <button
                    id="submit-button"
                    smvd-ui-button
                    [disabled]="form.pristine"
                    [context]="uiContext.PrimaryDark"
                    class="__button-save"
                    (click)="saveWaterDetails()"
                >
                    {{ 'SAVE' | translate }}
                </button>
            </ng-template>
        </div>
    </div>
</app-loading-container>

<ng-template #waterTransferAssetTemplate let-waterTransferAsset="waterTransferAsset">
    <smvd-ui-svg-illustration
        class="u-margin-right u-flex-no-shrink __mime-icon"
        [svg]="waterTransferAsset?.mime === Mimetypes.Pdf ? SvgIllustration.PdfFile: SvgIllustration.ImageFile"
    ></smvd-ui-svg-illustration>
    <div class="u-flex-column u-flex-grow-1 u-cursor-pointer __file-info" (click)="onEnergyMeterReadingDetail(waterTransferAsset)">
        <div class="u-color-gray-ultradark __file-name">{{ waterTransferAsset?.name }}</div>
        <div class="u-font-size-12 u-color-gray-dark">{{ waterTransferAsset?.createdOn | formatDate }}</div>
    </div>
    <a
        (click)="removeAsset(waterTransferAsset)"
        app-svg-illustration
        class="u-color-gray-dark u-cursor-pointer u-flex-no-shrink u-margin-left-half"
        [svg]="svgIllustration.CloseButton"
    ></a>
</ng-template>

<input
    #fileInput
    hidden
    [accept]="ALLOWED_MIME_TYPES"
    type="file"
    ng2FileSelect
    (onFileSelected)="onFileSelected($event)"
    [uploader]="uploader"
    multiple
/>
