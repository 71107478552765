import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UiSize } from '../../ui.enums';
import { BaseInput } from '../base-input';
import { FormFieldAppearanceEnum } from '../enums/mat-form-field-appearance.enum';
import { defaultInputDelay } from './text-input.constants';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { LabelContainerModule } from '@ui/form/label-container';
import { ErrorStateModule } from '@ui/form/error-state';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: 'app-text-input, smvd-ui-text-input',
    template: `
        <app-label-container
            [required]="required"
            [id]="id"
            [label]="label"
            [labelTemplateRef]="labelTemplateRef"
            [ngSwitch]="!!matAutocomplete"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [showRequiredAsterisk]="showRequiredAsterisk"
        >
            <mat-form-field class="u-input-size-{{ size }}" [appearance]="appearance">
                <div matPrefix class="__prefix u-color-muted">
                    <ng-content select="[prefix]"></ng-content>
                </div>
                <input
                    *ngSwitchCase="false"
                    [id]="id"
                    type="text"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    autocomplete="smooved"
                    [mask]="mask"
                    [dropSpecialCharacters]="!keepMask"
                />
                <input
                    *ngSwitchCase="true"
                    [id]="id"
                    type="text"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    [matAutocomplete]="matAutocomplete"
                    [mask]="mask"
                    [dropSpecialCharacters]="!keepMask"
                />
                <div class="__suffix" matSuffix>
                    <button *ngIf="!!innerModel && !!matAutocomplete" theme="no-style">
                        <mat-icon>close</mat-icon>
                    </button>
                    <ng-content select="[suffix]"></ng-content>
                </div>
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./text-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true,
        },
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        LabelContainerModule,
        ErrorStateModule,
        FormsModule,
        NgxMaskDirective,
        MatAutocompleteModule,
    ],
    standalone: true,
})
export class TextInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public labelTemplateRef: TemplateRef<any>;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public size = UiSize.Medium;
    @Input() public matAutocomplete: MatAutocomplete;
    @Input() public appearance: MatFormFieldAppearance = FormFieldAppearanceEnum.Outline;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('uiMask') public mask: string;
    @Input() public keepMask: boolean;
    @Input() public inputDelay = defaultInputDelay;

    public innerModel: string;

    private subject = new Subject<string>();

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.subject.pipe(debounceTime(this.inputDelay), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((result) => {
            this.propagateChange(result.trim());
        });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.subject.next(this.innerModel);
    }
}
