import { Pipe, PipeTransform } from '@angular/core';
import { ServiceOffer } from '@app/services/types/service-offer';

@Pipe({
    name: 'serviceOfferIsSelected',
    pure: true,
})
export class ServiceOfferIsSelectedPipe implements PipeTransform {
    transform(offer: ServiceOffer, selectedOffer: ServiceOffer): boolean {
        if (!selectedOffer || !offer) return false;
        return offer.offerName === selectedOffer.offerName && offer.supplierName === selectedOffer.supplierName;
    }
}
