import { Pipe, PipeTransform } from '@angular/core';
import { NameUtils } from '../utils';

@Pipe({
    name: 'name',
    standalone: true,
})
export class NamePipe implements PipeTransform {
    transform(value: { firstName?: string; lastName?: string }, emptyAs?: string): string {
        return NameUtils.getName(value, emptyAs);
    }
}
