import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Attachment } from '@app/email/interfaces/attachment';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@app/move/state/move.utils';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { WaterTransferAsset } from '@app/water/interfaces/water';
import { Asset } from '@smooved/core';

export class DocumentCenterUtils {
    public static getMoveAssetsByAttachmentId(attachmentId: AttachmentId, move: Move): Attachment[] {
        switch (attachmentId) {
            case AttachmentId.EnergyMeterReadingAsset:
                return this.mapAssetsToAttachments(move.energyMeterReadingAssets, AttachmentId.EnergyMeterReadingAsset);
            case AttachmentId.EnergyDocumentAsset:
                return this.mapAssetsToAttachments(move.energyDocumentAssets, AttachmentId.EnergyDocumentAsset);
            case AttachmentId.RentalAgreementAsset:
                return this.mapAssetsToAttachments(
                    MoveUtils.getRentalInspection(move)?.agreementDocumentAssets,
                    AttachmentId.RentalAgreementAsset
                );
            case AttachmentId.RentalInspectionAsset:
                return this.mapAssetsToAttachments([move?.rentalInspectionAsset], AttachmentId.RentalInspectionAsset);
            case AttachmentId.WaterTransferAsset:
                return this.mapWaterTransferAssetsToAttachments(move.water?.waterTransferAssets);
            default:
                return [];
        }
    }

    private static mapWaterTransferAssetsToAttachments(waterTransferAssets: WaterTransferAsset[]): Attachment[] {
        return DocumentCenterUtils.mapAssetsToAttachments(
            waterTransferAssets?.filter((waterTransferAsset) =>
                [WaterTransferAssetType.EmailAttachment, WaterTransferAssetType.TransferDocument].includes(
                    waterTransferAsset.waterTransferAssetType
                )
            ),
            AttachmentId.WaterTransferAsset
        );
    }

    private static mapAssetsToAttachments(assets: Asset[], attachmentId: AttachmentId): Attachment[] {
        return assets?.map((asset): Attachment => this.assetToAttachment(asset, attachmentId)) ?? [];
    }

    private static assetToAttachment({ key, name, location }: Asset, id: AttachmentId): Attachment {
        return { id, key, name, location };
    }
}
