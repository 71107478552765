import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { BoilerMaintenanceService } from '@app/services/boiler-maintenance/services/boiler-maintenance.service';
import { BoilerMaintenanceOffer } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { selectOffer } from '@app/services/state/services.actions';
import { selectOrderInfo, selectSelectedOffer } from '@app/services/state/services.selectors';
import { ServicesState } from '@app/services/state/services.state';
import { ServiceOffer } from '@app/services/types/service-offer';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { Store } from '@ngrx/store';
import { UiSandbox } from '@smooved/ui';
import { switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-lineup',
    template: `
        <ng-container *ngIf="{ selectedOffer: selectedOffer$ | async, isMobile: uiSandbox.isMobile } as vm">
            <div
                [ngClass]="{ 'u-padding-top-lg u-container u-padding-x-axis': !vm.isMobile, 'u-padding': vm.isMobile }"
                class="u-margin-0-auto u-padding-bottom-lg"
            >
                <app-previous-step route="../order-info/property-construction"></app-previous-step>
                <h3 class="u-margin-bottom-md">{{ 'SERVICES.BOILER_MAINTENANCE.OFFER_LINEUP.TITLE' | translate }}</h3>
                <div [ngClass]="{ 'u-flex-column': vm.isMobile, 'u-flex-row u-flex-align-items-center': !vm.isMobile }">
                    <app-lineup-offer
                        [ngClass]="{ 'u-display-block u-w100p': vm.isMobile }"
                        *ngFor="let offer of offers; let last = last"
                        [class.u-margin-bottom]="vm.isMobile && !last"
                        [class.u-margin-right-md]="!vm.isMobile && !last"
                        [offer]="offer"
                        (selectOffer)="selectOffer($event)"
                        [selected]="offer | serviceOfferIsSelected: vm.selectedOffer"
                    ></app-lineup-offer>
                </div>
            </div>
        </ng-container>
    `,
})
export class LineupContainer extends BaseStepContainer implements OnInit {
    public offers: ServiceOffer[] = [];
    public selectedOffer$ = this.store.select(selectSelectedOffer);

    constructor(
        private readonly store: Store<ServicesState>,
        public readonly uiSandbox: UiSandbox,
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        private readonly boilerMaintenanceService: BoilerMaintenanceService,
        private readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    public ngOnInit(): void {
        this.posthogServicesService.viewLineup(this.serviceType);
        this.store
            .select(selectOrderInfo)
            .pipe(
                take(1),
                switchMap((orderInfo) => this.boilerMaintenanceService.getLineup(orderInfo.propertyAge))
            )
            .subscribe((response) => {
                this.offers = response.data;
            });
    }

    public selectOffer(offer: BoilerMaintenanceOffer): void {
        this.posthogServicesService.clickChooseOffer(this.serviceType, offer.supplierName);
        this.store.dispatch(selectOffer(offer));
        super.onNext();
    }
}
