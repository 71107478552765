import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as uri from '@app/admin/constants/uri.constants';
import { RealEstateGroupFilter } from '@app/admin/interfaces/real-estate-group-filter.interface';
import { EmailTemplate } from '@app/email/enums/email-template.enum';
import { EmailContent } from '@app/email/interfaces/email-content';
import { RealEstateGroupForAdmin } from '@app/real-estate-group/interfaces/real-estate-group-for-admin.interface';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { PaginationResponse, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { Admin } from '../interfaces/admin.interface';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly realEstateGroupService: RealEstateGroupService
    ) {}

    public exportMovesLight(beginDate?: Date, endDate?: Date): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportMovesLightUri, beginDate, endDate);
    }

    public exportMoves(beginDate?: Date, endDate?: Date): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportMovesUri, beginDate, endDate);
    }

    public export(uri: string, beginDate?: Date, endDate?: Date): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri, beginDate, endDate);
    }

    public exportMarketplace(): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(uri.exportMarketplaceUri, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    public exportRealEstateAgents(): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportRealEstateAgentsUri);
    }

    public exportExternalInfluencers(): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportExternalInfluencersUri);
    }

    public exportReviews(beginDate?: Date, endDate?: Date): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportReviews, beginDate, endDate);
    }

    public exportOffices(): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportOfficesUri);
    }

    public exportRealEstateGroups(): Observable<Blob> {
        return this.baseBlobRequestWithRange(uri.exportRealEstateGroupsUri);
    }

    public importEnergy(data: FormData): Observable<void> {
        return this.httpClient.post<void>(uri.importEnergyUri, data);
    }

    public importTelecom(data: FormData): Observable<void> {
        return this.httpClient.post<void>(uri.importTelecomUri, data);
    }

    public importPropertyRates(data: FormData): Observable<void> {
        return this.httpClient.post<void>(uri.importPropertyRatesUri, data);
    }

    public patch(adminId: string, admin: Admin): Observable<Admin> {
        return this.httpClient.patch<Admin>(`${uri.baseUri}/${adminId}`, admin);
    }

    public getRealEstateGroupsForAdmin(
        realEstateGroupFilter?: RealEstateGroupFilter
    ): Observable<PaginationResponse<RealEstateGroupForAdmin>> {
        return this.httpClient.get<PaginationResponse<RealEstateGroupForAdmin>>(uri.realEstateGroupsUri, {
            params: this.realEstateGroupService.buildRealEstateGroupFilter(realEstateGroupFilter),
        });
    }

    public renderMoveEmail(moveId: string, emailTemplate: EmailTemplate): Observable<EmailContent> {
        return this.httpClient.get<EmailContent>(
            StringUtils.parseUri(uri.emailMovesRenderUri, {
                moveId,
                emailTemplate,
            })
        );
    }

    private baseBlobRequestWithRange(url: string, beginDate?: Date, endDate?: Date): Observable<Blob> {
        let httpParams: HttpParams = new HttpParams();
        if (beginDate) {
            httpParams = httpParams.append('beginDate', beginDate.toString());
        }
        if (endDate) {
            httpParams = httpParams.append('endDate', endDate.toString());
        }
        return this.httpClient.get(url, {
            params: httpParams,
            responseType: 'blob',
        });
    }
}
